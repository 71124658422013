import { envConfig } from "../../config/env.config";
import {
    IBaseResponseModel
} from "../../models/common/models.type";
import { ICreateTimeOffTypeRequest, ITimeOffTypePagingRequest, ITimeOffType, IUpdateTimeOffTypeRequest, IUpdateMailCCRequest } from "../../models/timeofftype";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_TIMEOFFTYPE = ApiEndpoints.TIMEOFFTYPE;
const baseURL = envConfig.API_ENDPOINT;


const getPage = (
    params: ITimeOffTypePagingRequest
): Promise<IBaseResponseModel<ITimeOffType[]>> => {
    return api.get(baseURL, API_TIMEOFFTYPE.GET_PAGE.url, {
        params: params
    });
};

const getSingle = (
    id: string
): Promise<IBaseResponseModel<ITimeOffType[]>> => {
    return api.get(baseURL, `${API_TIMEOFFTYPE.GET_SINGLE.url}/${id}`);
};
const getTimeOffType = (
    Operation: number
): Promise<IBaseResponseModel<ITimeOffType[]>> => {
    return api.get(baseURL, API_TIMEOFFTYPE.GET_TIMEOFFTYPE.url, {
        params: { Operation: Operation }
    });
};
const createTimeOffType = (
    params: ICreateTimeOffTypeRequest
): Promise<IBaseResponseModel<ITimeOffType>> => {
    return api.post(baseURL, API_TIMEOFFTYPE.CREATE.url, params);
};
const updateTimeOffType = (
    params: IUpdateTimeOffTypeRequest
): Promise<IBaseResponseModel<ITimeOffType>> => {
    return api.put(baseURL, API_TIMEOFFTYPE.UPDATE.url, params);
};
const deleteTimeOffType = (
    id: string
): Promise<IBaseResponseModel<ITimeOffType>> => {
    return api.delete(baseURL, `${API_TIMEOFFTYPE.DELETE.url}/${id}`);
};
const getMailCCDefault = (): Promise<IBaseResponseModel<string>> => {
    return api.get(baseURL, API_TIMEOFFTYPE.GET_MAIL_CC.url);
};

const updateMailCCDefault = (
    params: IUpdateMailCCRequest
): Promise<IBaseResponseModel<string>> => {
    return api.put(baseURL, API_TIMEOFFTYPE.UPDATE_MAIL_CC.url, params.data);
};



const TimeOffTypeService = {
    getPage,
    getSingle,
    getTimeOffType,
    createTimeOffType,
    updateTimeOffType,
    deleteTimeOffType,
    getMailCCDefault,
    updateMailCCDefault

};

export default TimeOffTypeService;
