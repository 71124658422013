import { Box, Typography, Divider, Button } from "@mui/material";
import dayjs from "dayjs";
import {
  FC,
  useState,
  useMemo,
  ReactNode,
  useEffect,
  useCallback
} from "react";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import ErrorIcon from "@mui/icons-material/Error";
import UndoIcon from "@mui/icons-material/Undo";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BasicMenu from "../../../../../components/Menu/BasicMenu";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import useAxios from "../../../../../components/UseAxios/useAxios";
import { ETaskStatus } from "../../../../../models/common/models.enum";
import { IRoleBoard } from "../../../../../models/Stage";
import { ITaskCard } from "../../../../../models/Task";
import { IRoleWorkflow } from "../../../../../models/workflow";
import TaskService from "../../../../../services/api/task.service";
import AssignTaskModal from "../../../assignTaskModal/assign_task_modal";
import CreateTaskModal from "../../../CreateTaskModal/create_task_modal";
import { TaskBoxWrapper } from "../TaskBox/styles";
import { Avatar, Modal } from "../../../../../components";

interface IProps {
  dragItem: boolean;
  canReassignTask?: boolean;
  onClick?: () => void;
  onUpdateSuccess?: (newTask: ITaskCard) => void;
  onDeleteSuccess?: (newTask: ITaskCard) => void;
  onRollBackSuccess?: (newTask: ITaskCard) => void;
  task: ITaskCard;
  roleWorkflow?: IRoleBoard | null;
  roleStage?: IRoleWorkflow | null;
}

const TaskCard: FC<IProps> = ({
  task,
  dragItem,
  canReassignTask,
  onClick,
  onUpdateSuccess,
  onDeleteSuccess,
  onRollBackSuccess,
  roleWorkflow,
  roleStage
}) => {
  const deleteTask = useAxios<string>({ loading: "OnRequest" });
  const rollbackTask = useAxios<string>({ loading: "OnRequest" });
  const [openUpdateAssign, setOpenUpdateAssign] = useState(false);
  const [openUpdateTask, setOpenUpdateTask] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const canAssignTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask ||
      canReassignTask
    );
  }, [roleStage, roleWorkflow, canReassignTask]);

  const canManageTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask
    );
  }, [roleStage, roleWorkflow]);

  const MenuLabel = ({
    isGravity,
    onClick,
    name,
    icon
  }: {
    isGravity?: boolean;
    icon: ReactNode;
    name: string;
    onClick: () => void;
  }) => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: "11px 12px",
          display: "flex",
          color: "#585656c9"
        }}
        onClick={onClick}
      >
        {icon}
        <Typography
          variant="caption"
          fontWeight={500}
          fontSize={13}
          color={isGravity ? "#aa0000" : "585656c9"}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (rollbackTask.isSuccess) {
      onRollBackSuccess?.(task);
      toggleMessage({
        type: "success",
        message: rollbackTask.message ?? "Cập nhật thành công"
      });
    }
    if (rollbackTask.error) {
      toggleMessage({
        type: "error",
        message: rollbackTask.error.message ?? "Failed to rollback task"
      });
    }
  }, [
    rollbackTask.isSuccess,
    rollbackTask.error,
    rollbackTask.message,
    task,
    onRollBackSuccess
  ]);

  useEffect(() => {
    if (deleteTask.isSuccess) {
      toggleMessage({
        type: "success",
        message: deleteTask.message ?? ""
      });
      onDeleteSuccess?.(task);
      handleCloseDeleteDialog(); // Close the dialog after deletion
    }
    if (deleteTask.error) {
      toggleMessage({
        type: "error",
        message: deleteTask.error.message ?? ""
      });
    }
  }, [
    deleteTask.error,
    deleteTask.isSuccess,
    deleteTask.message,
    task,
    onDeleteSuccess
  ]);

  const handleNewTab = () => {
    window.open(ROUTE_PATH.TASK + "?id=" + task.id, "_blank");
  };

  const handleShowDetail = useCallback(() => {
    // navigateSearch(ROUTE_PATH.TASK, { id: task.id as string });
    onClick?.();
  }, []);
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true); // Open the delete confirmation dialog
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false); // Close the delete confirmation dialog
  };

  const handleConfirmDelete = () => {
    handleDelete(); // Call the delete function
  };

  const handleDelete = () => {
    deleteTask.request(TaskService.deleteTask(task.id));
  };

  const handleRollBack = () => {
    rollbackTask.request(TaskService.rollBack(task.id));
  };

  return (
    <>
      <TaskBoxWrapper
        className={` bg-white border border-gray-200 shadow-none p-3${dragItem ? " rotate-3" : ""}`}
        type={`${task.status}`}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignSelf={"stretch"}
          justifyContent={"space-between"}
        >
          <Typography
            className="flex-1 font-bold text-base my-1 Overflow2Line prevent-select"
            sx={{ textDecoration: "underline" }}
            onClick={() => handleShowDetail()}
          >
            {task.title}
          </Typography>
          <BasicMenu
            onChange={() => {}}
            label={<MoreHorizIcon />}
            padding={0}
            options={[
              {
                value: "blank",
                label: MenuLabel({
                  onClick: handleNewTab,
                  icon: (
                    <OpenInBrowserIcon
                      sx={{ width: "20px", height: "20px", marginRight: 1 }}
                    />
                  ),
                  name: "Xem ở trong tab mới"
                })
              },
              ...(task.status === ETaskStatus.Failed
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: handleRollBack,
                        icon: (
                          <UndoIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Quay lại giai đoạn trước đó"
                      })
                    }
                  ]
                : []),
              ...(canManageTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "edit",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateTask(true),
                        icon: (
                          <ModeEditOutlineOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Chỉnh sửa nhiệm vụ"
                      })
                    }
                  ]
                : []),

              ...(canAssignTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateAssign(true),
                        icon: (
                          <AddBoxRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Giao lại cho người khác"
                      })
                    }
                  ]
                : []),
              ...(canManageTask
                ? [
                    {
                      value: "divider",
                      label: (
                        <Divider
                          sx={{
                            height: "1px",
                            width: "90%",
                            marginInline: 1
                          }}
                        />
                      )
                    },
                    {
                      value: "delete",
                      label: MenuLabel({
                        isGravity: true,
                        onClick: handleOpenDeleteDialog,
                        icon: (
                          <DeleteOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Xoá Nhiệm vụ"
                      })
                    }
                  ]
                : [])
            ]}
          />
        </Box>
        <div
          className="TaskShortDesc Overflow2Line"
          dangerouslySetInnerHTML={{
            __html: task.description
          }}
          onClick={() => handleShowDetail()}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={0.5}
          marginTop={"auto"}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            webkitLineClamp: "1",
            webkitBoxOrient: "vertical"
          }}
        >
          {/* <Tag name="backend" /> */}
        </Box>
        {!task.personAssigned && canAssignTask && (
          <Box
            display={"flex"}
            sx={{ width: "100%" }}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box
              className="TaskStatus flex-1"
              display={"flex"}
              flexDirection={"row"}
              onClick={() => handleShowDetail()}
            >
              <ErrorIcon />
              <div> Chưa được giao</div>
            </Box>

            <Button
              sx={{
                minWidth: "32px",
                width: "52px",
                height: "28px",
                borderRadius: 1
              }}
              onClick={() => setOpenUpdateAssign(true)}
              variant="contained"
            >
              Giao
            </Button>
          </Box>
        )}
        {task.personAssigned && (
          <Box
            display={"flex"}
            sx={{ width: "100%", height: "28px" }}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => handleShowDetail()}
          >
            <Box display={"flex"} flexDirection={"row"} gap={0.5}>
              <Avatar
                src={task.personAssigned.avatar}
                alt="Avatar-Image"
                width={20}
                height={20}
              />
              <Typography
                variant="caption"
                fontSize={12}
                lineHeight={"unset"}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "1",
                  webkitBoxOrient: "vertical"
                }}
              >
                {task.personAssigned.fullName}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              fontSize={12}
              className="TaskDeadline"
              lineHeight={"unset"}
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "1",
                webkitBoxOrient: "vertical"
              }}
            >
              {task.deadLine
                ? dayjs(task.deadLine).format("DD/MM/YYYY")
                : "No deadline"}
            </Typography>
          </Box>
        )}
      </TaskBoxWrapper>
      <AssignTaskModal
        open={openUpdateAssign}
        task={task}
        onUpdateSuccess={onUpdateSuccess ?? (() => {})}
        handleClose={() => setOpenUpdateAssign(false)}
      />
      <CreateTaskModal
        open={openUpdateTask}
        taskId={task.id}
        isUpdate
        workflowId={task.boardId}
        onCreateSuccess={newTaskcard => onUpdateSuccess?.(newTaskcard!)}
        handleClose={() => setOpenUpdateTask(false)}
      />
      <Modal
        title={"Xác nhận xóa nhiệm vụ"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={openDeleteDialog}
        onSubmit={handleConfirmDelete}
        disabledSubmit={deleteTask.isLoading}
        onClose={handleCloseDeleteDialog}
      >
        {"Bạn có chắc chắn muốn xóa nhiệm vụ này?"}
      </Modal>
    </>
  );
};

export default TaskCard;
