import { IRules, IUpdateRulesReq } from "./../../models/office/index";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";

const API = ApiEndpoints.OFFICE;
const baseURL = envConfig.API_ENDPOINT;

const get = (): Promise<IBaseResponseModel<IRules>> => {
  return api.get(baseURL, API.GET.url);
};

const update = (data: IUpdateRulesReq): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE.url, data);
};

const OfficeService = {
  get,
  update
};

export default OfficeService;
