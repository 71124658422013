import {
  Box,
  Button,
  Grid,
  Pagination,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi.hook";
import {
  ICreateMeetingRoomReq,
  IMeetingRoom,
  IMeetingSchedule
} from "../../models/meeting";
import MeetingService from "../../services/api/meeting.service";
import { ApproveWrapper } from "./styles";

import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import moment from "moment";
import {
  EMeetingRoomScheduleTypeEnum,
  EMeetingRoomStatus
} from "../../models/common/models.enum";
import CommonHandle from "../../common/handles/common.handles";
import {
  DATE_FORMAT2,
  DATE_REQ_FORMAT,
  TIME_FORMAT
} from "../../config/constants";
import { Avatar, ControlForm, Modal } from "../../components";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import DoDisturbRoundedIcon from "@mui/icons-material/DoDisturbRounded";
import ScheduleCard from "./components/ScheduleCard";
import { toggleMessage } from "../../components/Toast/Toast";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputForm from "../../components/controls/InputForm";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import _ from "lodash";

type Props = {};

const Approve = (props: Props) => {
  const [value, setValue] = React.useState(EMeetingRoomStatus.Pending);
  const [openCreate, setOpenCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [data, setData] = useState<{
  //   date: string;
  //   schedule: IMeetingSchedule[];
  // } | null>(null);

  const rooms = useApi<IMeetingRoom[]>({ isFetch: true });
  const getPage = useApi<IMeetingSchedule[]>({ isFetch: true, isPaging: true });
  const getAll = useApi<IMeetingSchedule[]>({ isFetch: true });
  const createRoom = useApi<IMeetingRoom>({});
  const deleteSchedule = useApi<IMeetingSchedule>({});
  const deleteRoom = useApi<IMeetingRoom>({});
  const adminUpdateStatus = useApi<IMeetingSchedule>({});

  const validationSchema = Yup.object().shape({
    RoomName: Yup.string().required(`${"Vui lòng nhập tên phòng"}`),
    Location: Yup.string().required(`${"Vui lòng nhập vị trí"}`),
    Description: Yup.string().required(`${"Vui lòng nhập mô tả"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateMeetingRoomReq>({
    resolver: yupResolver(validationSchema)
  });

  function fetchData() {
    getPage.request(
      MeetingService.getPageByStatus({
        Year: moment().year(),
        Month: moment().month() + 1,
        CurrentPage: currentPage,
        PageSize: 10,
        Status: value
      })
    );
  }

  function fetchRooms() {
    rooms.request(
      MeetingService.getRooms({
        CurrentPage: 1,
        PageSize: 10
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, [value, currentPage]);

  function handleAdminUpdateStatus(
    schedule: IMeetingSchedule,
    status: EMeetingRoomStatus
  ) {
    adminUpdateStatus.request(
      MeetingService.adminUpdateStatus({
        id: schedule?.id ?? "",
        status: status
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          fetchData();
        } else if (!res.success) {
          toggleMessage({
            type: "error",
            message: res?.message ?? "Đã xảy ra lỗi"
          });
        }
      })
    );
  }

  useEffect(() => {
    fetchRooms();
    getAll.request(
      MeetingService.getPage({
        Type: EMeetingRoomScheduleTypeEnum.Day,
        Year: moment().year(),
        Month: moment().month() + 1,
        DateTime: moment(moment().format(DATE_FORMAT2)).format(DATE_REQ_FORMAT)
      })
    );
  }, []);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: EMeetingRoomStatus
  ) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  const onSubmit = (request: ICreateMeetingRoomReq) => {
    createRoom.request(
      MeetingService.createRoom({ ...request, IsActive: true }).then(
        response => {
          if (response.success) {
            toggleMessage({
              type: "success",
              message: "Tạo phòng thành công"
            });
            fetchRooms();
            setOpenCreate(false);
            reset();
          } else {
            toggleMessage({
              type: "error",
              message: response.message ?? "Đã xảy ra lỗi"
            });
          }
        }
      )
    );
  };

  return (
    <ApproveWrapper>
      <Box width="22%">
        <Typography variant="subtitle2" margin="12px">
          DANH SÁCH PHÒNG HỌP
        </Typography>
        <Box className="content-box">
          {rooms.data &&
            rooms.data.map((room, i) => (
              <Box key={i}>
                <Tooltip title={"Nhấn giữ để xoá"}>
                  <Box
                    display="flex"
                    marginBottom="8px"
                    onMouseUp={() => {
                      toggleConfirmModal({
                        title: `Bạn muốn xoá phòng họp này?`,
                        content: `Bạn chắc chắn muốn xoá phòng họp này`,
                        onSubmit: async () => {
                          deleteRoom.request(
                            MeetingService.deleteRoom(room.id).then(
                              response => {
                                if (response.success) {
                                  toggleMessage({
                                    type: "success",
                                    message: "Phòng họp đã bị xoá"
                                  });
                                  fetchRooms();
                                } else {
                                  toggleMessage({
                                    type: "success",
                                    message: response?.error ?? "Đã xảy ra lỗi"
                                  });
                                }
                              }
                            )
                          );
                        },
                        submitText: "Xác nhận",
                        type: "warning-red"
                      });
                    }}
                  >
                    <MeetingRoomRoundedIcon />
                    <Box marginLeft="12px">
                      <Typography variant="subtitle2">
                        {room.roomName}
                      </Typography>
                      <Typography variant="caption">
                        {room.location} - {room.description}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Box>
            ))}
          <Button
            startIcon={<AddCircleRoundedIcon />}
            onClick={() => setOpenCreate(true)}
          >
            Thêm phòng họp
          </Button>

          <Modal
            title={"Tạo phòng họp"}
            open={openCreate}
            onClose={() => setOpenCreate(false)}
            onSubmit={handleSubmit(onSubmit)}
            textSubmit="Tạo mới"
            loadingState={createRoom.loading}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px"
              }}
            >
              <ControlForm
                title="Tên phòng họp"
                isRequired
                classname="brand-form"
              >
                <InputForm
                  placeholder="Tên phòng họp"
                  required
                  name="RoomName"
                  errors={errors}
                  control={control}
                  size="small"
                  disabled={createRoom.loading}
                />
              </ControlForm>
              <ControlForm title="Mô tả" isRequired classname="brand-form">
                <InputForm
                  placeholder="Mô tả"
                  name="Description"
                  errors={errors}
                  control={control}
                  size="small"
                  disabled={createRoom.loading}
                />
              </ControlForm>
              <ControlForm title="Vị trí" isRequired classname="brand-form">
                <InputForm
                  placeholder="Vị trí"
                  required
                  name="Location"
                  errors={errors}
                  control={control}
                  size="small"
                  disabled={createRoom.loading}
                />
              </ControlForm>
            </form>
          </Modal>
        </Box>
        <Typography variant="subtitle2" margin="12px">
          LỊCH HỌP HÔM NAY
        </Typography>
        {getAll.data
          ?.filter(
            data =>
              CommonHandle.formatDate(data.startDate) ===
              CommonHandle.formatDate(moment().toDate())
          )
          .map(today => (
            <Box key={today.id} marginLeft="12px">
              <ScheduleCard key={today.id} item={today} />
            </Box>
          ))}
      </Box>
      <Box className="content-box" flexGrow={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab value={EMeetingRoomStatus.Pending} label="Chờ phê duyệt" />
          <Tab value={EMeetingRoomStatus.WaitCancel} label="Chờ xác nhận huỷ" />
          <Tab value={EMeetingRoomStatus.Confirmed} label="Đã phê duyệt" />
          <Tab value={EMeetingRoomStatus.Completed} label="Đã Kết thúc" />
        </Tabs>
        <Box height="85vh" marginTop="16px">
          {getPage.data &&
            _(getPage.data)
              .groupBy(x => moment(x.startDate).format("MM/yyyy"))
              .map((value, key) => ({ date: key, schedule: value }))
              .value()
              .map((month, i) => (
                <Box key={month.date}>
                  <Typography variant="subtitle2">
                    Tháng {month.date}
                  </Typography>
                  {month.schedule.map(schedule => (
                    <Box
                      key={schedule.id}
                      sx={{
                        border: "1px solid #DEDEDE",
                        display: "flex",
                        borderRadius: "8px",
                        padding: "10px 24px",
                        margin: "14px 0"
                      }}
                    >
                      <Grid container spacing={2} columns={18}>
                        <Grid item xs={2}>
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "500" }}
                          >
                            {moment(schedule.startDate).format("dddd")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "18px" }}
                          >
                            {moment(schedule.startDate).format("DD")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ display: "flex" }}>
                            <AccessTimeFilledRoundedIcon
                              sx={{
                                fontSize: 16,
                                color: "#AAAAB2",
                                marginRight: "4px"
                              }}
                            />
                            <Typography variant="subtitle2">
                              {moment(schedule.startDate).format(TIME_FORMAT)}
                            </Typography>
                          </div>
                          <div>
                            <LocationOnRoundedIcon
                              sx={{
                                fontSize: 16,
                                color: "#AAAAB2",
                                marginRight: "4px"
                              }}
                            />
                            <Typography variant="caption">
                              {schedule.meetingRoom?.roomName ??
                                "Không có thông tin"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2">
                            {schedule?.title}
                          </Typography>
                          <Typography variant="caption">
                            {schedule?.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          {!schedule.meetingRegistrant ? (
                            <p>Không có dữ liệu</p>
                          ) : (
                            <Box display="flex">
                              <Avatar
                                src={schedule.meetingRegistrant?.avatar}
                                width="30px"
                                height="30px"
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginLeft="8px"
                              >
                                <Typography variant="subtitle2">
                                  {schedule.meetingRegistrant?.fullName}
                                </Typography>
                                <Typography variant="caption">
                                  {schedule.meetingRegistrant?.position}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            marginLeft="12px"
                            // alignItems="center"
                          >
                            {value === EMeetingRoomStatus.Pending && (
                              <Button
                                startIcon={<DoDisturbRoundedIcon />}
                                size="small"
                                color="error"
                                onClick={() => {
                                  toggleConfirmModal({
                                    title: `Bạn từ chối đăng ký này?`,
                                    content: (
                                      <Box>
                                        <p>Bạn muốn từ chối đăng ký này?</p>
                                        <p>
                                          Đăng ký này sẽ bị xoá sau khi xác nhận
                                        </p>
                                      </Box>
                                    ),
                                    onSubmit: async () => {
                                      deleteSchedule.request(
                                        MeetingService.deleteMeeting(
                                          schedule.id
                                        ).then(response => {
                                          if (response.success) {
                                            toggleMessage({
                                              type: "success",
                                              message: "Đăng ký đã bị xoá"
                                            });
                                            fetchData();
                                          }
                                        })
                                      );
                                    },
                                    submitText: "Xác nhận",
                                    type: "warning-red"
                                  });
                                }}
                              >
                                Từ chối
                              </Button>
                            )}
                            {value === EMeetingRoomStatus.Pending && (
                              <Button
                                size="small"
                                sx={{ marginTop: "4px" }}
                                startIcon={<TaskAltRoundedIcon />}
                                onClick={() => {
                                  toggleConfirmModal({
                                    title: `Bạn muốn duyệt đăng ký?`,
                                    content: `Bạn chắc chắn muốn duyệt đăng ký này?`,
                                    onSubmit: async () =>
                                      handleAdminUpdateStatus(
                                        schedule,
                                        EMeetingRoomStatus.Confirmed
                                      ),
                                    submitText: "Phê duyệt",
                                    type: "confirm"
                                  });
                                }}
                              >
                                Phê duyệt
                              </Button>
                            )}
                            {value === EMeetingRoomStatus.WaitCancel && (
                              <Button
                                size="small"
                                sx={{ marginTop: "4px" }}
                                startIcon={<TaskAltRoundedIcon />}
                                onClick={() => {
                                  toggleConfirmModal({
                                    title: `Bạn muốn duyệt yêu cầu huỷ?`,
                                    content: `Bạn chắc chắn muốn duyệt yêu cầu huỷ đăng ký này?`,
                                    onSubmit: async () =>
                                      handleAdminUpdateStatus(
                                        schedule,
                                        EMeetingRoomStatus.Cancel
                                      ),
                                    submitText: "Xác nhận",
                                    type: "infor"
                                  });
                                }}
                              >
                                Xác nhận
                              </Button>
                            )}
                            {value === EMeetingRoomStatus.Confirmed && (
                              <Button
                                size="small"
                                color="info"
                                sx={{ marginTop: "4px" }}
                                startIcon={<DownloadDoneRoundedIcon />}
                                onClick={() => {
                                  toggleConfirmModal({
                                    title: `Bạn muốn kết thúc cuộc họp này?`,
                                    content: `Bạn sẽ không thể tiếp tục sử dụng phòng họp sau khi xác nhận`,
                                    onSubmit: async () => {
                                      adminUpdateStatus.request(
                                        MeetingService.adminUpdateStatus({
                                          id: schedule?.id ?? "",
                                          status: EMeetingRoomStatus.Completed
                                        }).then(res => {
                                          if (res.success) {
                                            toggleMessage({
                                              type: "success",
                                              message: "Cập nhật thành công"
                                            });
                                            fetchData();
                                          } else if (!res.success) {
                                            toggleMessage({
                                              type: "error",
                                              message:
                                                res?.message ?? "Đã xảy ra lỗi"
                                            });
                                          }
                                        })
                                      );
                                    },
                                    submitText: "Xác nhận",
                                    type: "warning-red"
                                  });
                                }}
                              >
                                Kết thúc
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              ))}

          {getPage.pagging && getPage.pagging.totalPages > 1 && (
            <Box
              sx={{
                margin: "12px 18px",
                padding: "10px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center"
              }}
            >
              <Typography>
                {1 + 10 * (getPage.pagging.currentPage - 1)} -{" "}
                {getPage.pagging.currentPage * 10 < getPage.pagging.totalCount!
                  ? getPage.pagging.currentPage * 10
                  : getPage.pagging.totalCount!}{" "}
                / {getPage.pagging.totalCount}
              </Typography>
              <Pagination
                count={getPage.pagging.totalPages}
                showFirstButton={getPage.pagging.totalPages > 2}
                showLastButton={getPage.pagging.totalPages > 2}
                shape="rounded"
                page={getPage.pagging.currentPage ?? 0}
                onChange={(_, v) => setCurrentPage(v)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </ApproveWrapper>
  );
};

export default Approve;
