import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import CheckIcon from "../../../assets/images/common/select_square.svg";
import IconUnCheck from "../../../assets/images/common/check_square_5.svg";
import { useApi } from "../../../hooks/useApi.hook";
import { IDocumentType } from "../../../models/docs";
import DocsService from "../../../services/api/docs.service";
import { toggleMessage } from "../../../components/Toast/Toast";

type Props = {
  editItem?: IDocumentType;
  handleClose: Function;
};

const CreateDocumentTypePopup = ({ handleClose, editItem }: Props) => {
  const [inputValue, setInputValue] = useState(editItem?.name ?? "");
  const [isActive, setIsActive] = useState(editItem?.isActive ?? true);

  const createDocType = useApi<IDocumentType>({});
  const updateDocumentType = useApi<IDocumentType>({});

  function onSubmit() {
    if (editItem) {
      updateDocumentType.request(
        DocsService.updateDocType({
          ...editItem,
          name: inputValue,
          isActive: isActive
        }).then(res => {
          if (res.success) {
            toggleMessage({
              type: "success",
              message: res?.message ?? "Cập nhật thành công"
            });
            handleClose(true);
          } else {
            toggleMessage({
              type: "error",
              message: res?.error ?? "Đã có lỗi xảy ra"
            });
          }
        })
      );
    } else {
      createDocType.request(
        DocsService.createDocumentType({
          name: inputValue,
          isActive: isActive
        }).then(res => {
          if (res.success) {
            toggleMessage({
              type: "success",
              message: res?.message ?? "Cập nhật thành công"
            });
            handleClose(true);
          }
        })
      );
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "#f1f5f6",
        padding: "14px",
        position: "absolute",
        top: "50%",
        left: "50%",
        height: "auto",
        transform: "translate(-50%, -50%)",
        width: "40vw",
        borderRadius: "8px"
      }}
    >
      <Typography variant="h5">
        {editItem ? "Chỉnh sửa" : "Tạo loại văn bản"}
      </Typography>

      <Box
        sx={{
          padding: "12px",
          backgroundColor: "white",
          borderRadius: "8px",
          marginTop: "10px"
        }}
      >
        <Typography variant="subtitle2">
          Tên loại văn bản <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          fullWidth
          sx={{ margin: "8px 0" }}
          placeholder="Nhập tên loại văn bản"
          defaultValue={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <Box display="flex" alignItems="center">
          <Typography variant="body2" marginLeft="4px">
            Kích hoạt
          </Typography>
          <Checkbox
            checked={isActive}
            onChange={e => setIsActive(e.target.checked)}
            icon={<img src={IconUnCheck} width={"22px"} height={"22px"} />}
            checkedIcon={<img src={CheckIcon} width={"22px"} height={"22px"} />}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="end"
        marginTop="12px"
        alignItems="center"
      >
        {createDocType.loading && (
          <Box display="flex" alignItems="center">
            <CircularProgress size={28} />
            <Typography variant="caption" marginLeft="12px">
              Đang xử lý..
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          size="small"
          disabled={createDocType.loading}
          sx={{
            borderRadius: "8px",
            color: "Gray",
            border: "1px solid Gray",
            margin: "0 12px"
          }}
          onClick={() => handleClose()}
        >
          Huỷ bỏ
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: "8px" }}
          disabled={createDocType.loading || inputValue.length === 0}
          onClick={onSubmit}
        >
          {editItem ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateDocumentTypePopup;
