import { IBaseResponseModel } from "../../models/common/models.type";
import {
  ILoginResponse,
  ILoginRequest,
  IUpdateFcmTokenReq
} from "../../models/auth";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";

const API_AUTH = ApiEndpoints.AUTH;
const baseURL = envConfig.API_ENDPOINT;

const loginWithPassword = (
  login: ILoginRequest
): Promise<IBaseResponseModel<ILoginResponse>> => {
  return api.post(baseURL, API_AUTH.LOGIN_WITH_PASSWORD.url, login);
};

const adminLoginWithPassword = async (
  login: ILoginRequest
): Promise<IBaseResponseModel<ILoginResponse>> => {
  return api.post(baseURL, API_AUTH.ADMIN_LOGIN_WITH_PASSWORD.url, login);
};

const updateFcmToken = async (
  updateFcmToken: IUpdateFcmTokenReq
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_AUTH.UPDATE_FCM_TOKEN.url, updateFcmToken);
};

const logout = async (
  fireBaseToken: string
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_AUTH.LOGOUT.url, {
    fireBaseToken: fireBaseToken
  });
};

const AuthService = {
  loginWithPassword,
  adminLoginWithPassword,
  updateFcmToken,
  logout
};

export default AuthService;
