import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  MenuItem,
  Pagination,
  Select,
  styled,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "./styles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { ControlForm, Modal } from "../../components";
import { ICreateUserRequest, ITag, IUser } from "../../models/user";
import InputForm from "../../components/controls/InputForm";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UserInfoContainer from "./components/UserInfoContainer/UserInfoContainer";
import { useApi } from "../../hooks/useApi.hook";
import UserService from "../../services/api/user.service";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import UserTable from "./components/UserTable/UserTable";
import { toggleMessage } from "../../components/Toast/Toast";
import SearchNoResult from "./components/SearchNoResult";
import { IAttachmentFile } from "../../models/Task";
import UploadFileService from "../../services/api/uploadFile.service";
import EditAvatar from "./components/EditAvatar";
import SearchInput from "./components/SearchInput";
import { Helmet } from "react-helmet";
import { IBasePaging } from "../../models/common/models.type";
import RoleService from "../../services/api/role.service";
import { IFeaturePermission, IRoles } from "../../models/user/models.roles";
import { authTokens } from "../../services/services";
import {
  FeatureModel,
  HRPermissionModel
} from "../../models/common/models.enum";
import { IDepartment } from "../../models/department";
import { SearchDepartmentInput } from "./components/SearchDepartmentInput";

const drawerWidth = 320;

const Main = styled("main", { shouldForwardProp: prop => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  position: "relative"
}));

const Member = () => {
  const [data, setData] = useState<IUser[]>([]);
  const [userId, setUserId] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState("");
  const [searchState, setSearchState] = useState<"SUCCESS" | "NONE" | null>(
    null
  );
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [paging, setPaging] = useState<IBasePaging | null>();
  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const inputRef = useRef<any>(null);
  const getPage = useApi<IUser[]>({ isFetch: true, isPaging: true });
  const createUser = useApi<IUser>({});
  const searchUser = useApi<ITag[]>({});
  const uploadFile = useApi<IAttachmentFile[]>({});
  const roles = useApi<IRoles[]>({ isFetch: true });
  const getFeaturePersmission = useApi<IFeaturePermission[]>({ isFetch: true });

  const [file, setFile] = useState<File | null>(null);
  const [fileTemp, setFileTemp] = useState<IAttachmentFile | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  function fetchData(keySearch?: string, pageIndex?: number) {
    getPage.request(
      UserService.getUsers({
        CurrentPage: pageIndex ?? 1,
        PageSize: 10,
        KeySearch: keySearch
      })
    );
    setSelectedIndex(null);
    setOpen(false);
    setSelectedUser(null);
    if (!keySearch) {
      setInputValue("");
    }
    setSearchState(null);
  }

  useEffect(() => {
    if (userId)
      getFeaturePersmission.request(
        RoleService.getFeaturePemissionByUserId(userId)
      );
  }, [userId]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserId(user?.id);
    }
    getUserInfo();
    fetchData();
  }, []);

  useEffect(() => {
    if (getFeaturePersmission.data) {
      setPermissions(
        getFeaturePersmission.data.find(f => f.feature === FeatureModel.HR)
          ?.permissions ?? []
      );
    }
  }, [getFeaturePersmission.data]);

  useEffect(() => {
    if (getPage.data) {
      setData(getPage?.data ?? []);
      setPaging(getPage.pagging);
      if (getPage.data.length && inputValue.length) {
        setSearchState("SUCCESS");
      } else if (!getPage.data.length && inputValue.length) {
        setSearchState("NONE");
      }
    }
  }, [getPage.data]);

  useEffect(() => {
    if (createUser.success) {
      toggleMessage({
        type: "success",
        message: "Tạo tài khoản thành công"
      });
      setOpenCreate(false);
      reset();
      fetchData(undefined, getPage.pagging?.currentPage);
    }
    if (createUser.error) {
      toggleMessage({
        type: "error",
        message: createUser?.error ?? "Đã xảy ra lỗi"
      });
      if (fileTemp) {
        uploadFile.request(UploadFileService.deleteFile(fileTemp));
      }
    }
  }, [createUser.success, createUser.error]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(`${"Vui lòng nhập họ và tên đệm"}`),
    lastName: Yup.string().required(`${"Vui lòng nhập tên"}`),
    userName: Yup.string().required(`${"Vui lòng nhập username"}`),
    roles: Yup.string().notOneOf([""], "You must select an option!"),
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
        "Định dạng mail không hợp lệ"
      )
      .required(`${"Vui lòng nhập địa chỉ mail"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      firstName: null,
      lastName: null,
      userName: null,
      email: null,
      position: null,
      roles: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (request: any) => {
    const role: IRoles = JSON.parse(request.roles);
    const req: ICreateUserRequest = {
      firstName: request.firstName,
      lastName: request.lastName,
      userName: request.userName,
      email: request.email,
      position: request.position,
      roles: role.key,
      roleId: role.id,
      departmentIds:
        request?.departmentIds &&
        (request?.departmentIds as IDepartment[]).map(
          department => department.id
        )
    };
    if (file) {
      const formData = new FormData();
      formData.append("FolderName", "User");
      formData.append("Files", file);
      uploadFile.request(
        UploadFileService.uploadFile(formData).then(response => {
          if (response.success) {
            setFileTemp(response.data[0]);
            createUser.request(
              UserService.createUser({ ...req, avatar: response.data[0].path })
            );
          } else {
            toggleMessage({
              type: "error",
              message: uploadFile?.error ?? "Đã xảy ra lỗi"
            });
          }
        })
      );
    } else {
      createUser.request(UserService.createUser(req));
    }
  };

  const handleSelected = (index: number, item: IUser) => {
    if (index === selectedIndex) {
      setOpen(!open);
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
      setSelectedUser(item);
      if (open === false) {
        setOpen(true);
      }
    }
  };

  const handleChange = (value: number) => {
    fetchData(inputValue.length ? inputValue : undefined, value);
  };

  function handleSearch() {
    if (inputValue.length > 0) {
      fetchData(inputValue, 1);
    } else {
      fetchData();
      setSearchState(null);
    }
  }

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <LoadingOverlayWrapper
      active={getPage.loading || searchUser.loading}
      text={"Đang tải dữ liệu, vui lòng chờ.."}
    >
      <Wrapper>
        <Helmet>
          <title> Quản lý tài khoản | Thành viên</title>
        </Helmet>
        <AppBar position="static" color="default">
          <Toolbar>
            <Box flexGrow={1} display="flex" alignItems="center">
              <SearchInput
                input={inputValue}
                placeholder="Tìm kiếm thành viên.."
                isSuccess={searchState === "SUCCESS"}
                search={handleSearch}
                clear={() => fetchData()}
                value={inputValue}
                countResult={paging?.totalCount ?? 0}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValue(event.target.value);
                }}
              />
            </Box>
            {permissions &&
              permissions.includes(HRPermissionModel.RegisterUser) && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: "4px" }}
                  endIcon={<ExpandMoreRoundedIcon />}
                  onClick={_ => {
                    roles.request(RoleService.getRoles());
                    setOpenCreate(true);
                  }}
                >
                  Thêm tài khoản
                </Button>
              )}
          </Toolbar>
        </AppBar>

        <Modal
          title="Tạo tài khoản mới"
          textSubmit="Tạo mới"
          textClose="Huỷ bỏ"
          open={openCreate}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => {
            setOpenCreate(false);
            reset();
          }}
          loadingState={createUser.loading}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <ControlForm title="" classname="brand-form">
              <EditAvatar
                file={file}
                onButtonClick={onButtonClick}
                handleFileChange={handleFileChange}
                inputRef={inputRef}
              />
            </ControlForm>
            <ControlForm
              title="Họ và tên đệm"
              isRequired
              classname="brand-form"
            >
              <InputForm
                placeholder="Họ và tên đệm"
                required
                name="lastName"
                errors={errors}
                control={control}
                size="small"
                disabled={createUser.loading}
              />
            </ControlForm>
            <ControlForm title="Tên" isRequired classname="brand-form">
              <InputForm
                placeholder="Tên"
                required
                name="firstName"
                errors={errors}
                control={control}
                size="small"
                disabled={createUser.loading}
              />
            </ControlForm>
            <ControlForm title="Username" isRequired classname="brand-form">
              <InputForm
                placeholder="@Username"
                required
                name="userName"
                errors={errors}
                control={control}
                size="small"
                disabled={createUser.loading}
              />
            </ControlForm>
            <ControlForm title="Email" isRequired classname="brand-form">
              <InputForm
                placeholder="Email"
                name="email"
                errors={errors}
                control={control}
                size="small"
                disabled={createUser.loading}
                type="email"
              />
            </ControlForm>

            <ControlForm title="Chức danh" classname="brand-form">
              <InputForm
                placeholder="Chức danh"
                name="position"
                errors={errors}
                control={control}
                size="small"
                disabled={createUser.loading}
              />
            </ControlForm>

            <ControlForm
              title="Loại tài khoản"
              isRequired
              classname="brand-form"
            >
              <Controller
                name="roles"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={createUser.loading}
                    fullWidth
                    size="small"
                    placeholder="Vui lòng chọn"
                    sx={{ fontSize: "small" }}
                    error={!!errors.roles}
                  >
                    {roles.data &&
                      roles.data?.map(role => (
                        <MenuItem
                          key={role.id}
                          value={JSON.stringify(role)}
                          sx={{ fontSize: "small" }}
                        >
                          {role.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors.roles && (
                <Typography variant="caption" marginLeft="16px" color="error">
                  Vui lòng chọn loại tài khoản
                </Typography>
              )}
              <Container sx={{ height: "14px" }} />
              <ControlForm title="Phòng ban" classname="brand-form">
                <SearchDepartmentInput
                  name={"departmentIds"}
                  control={control}
                  errors={errors}
                  isError={!!errors.leaderId}
                  placeholder={"Gõ để tìm"}
                />
              </ControlForm>
            </ControlForm>
          </form>
        </Modal>

        {searchState === "NONE" && (
          <SearchNoResult
            keySearch={inputValue}
            title={"nhân viên"}
            clearSearch={() => {
              fetchData();
            }}
          />
        )}
        <Box
          sx={{
            display: searchState === "NONE" ? "none" : "flex",
            marginTop: "12px",
            marginLeft: "8px",
            marginRight: "8px"
          }}
        >
          <Main open={open}>
            <Box>
              {!!data && (
                <UserTable
                  data={data}
                  selected={handleSelected}
                  selectedIndex={selectedIndex}
                  showTotalTimeOff
                />
              )}
            </Box>
            {paging && paging.totalPages > 1 && (
              <Box
                sx={{
                  margin: "12px 18px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center"
                }}
              >
                <Typography variant="subtitle2" marginRight="16px">
                  {1 + 10 * (paging.currentPage - 1)} -{" "}
                  {paging.currentPage * 10 < paging.totalCount!
                    ? paging.currentPage * 10
                    : paging.totalCount!}{" "}
                  / {paging.totalCount}
                </Typography>
                <Pagination
                  count={paging.totalPages}
                  showFirstButton={paging.totalPages > 2}
                  showLastButton={paging.totalPages > 2}
                  variant="outlined"
                  shape="rounded"
                  page={paging.currentPage ?? 0}
                  onChange={(_, v) => handleChange(v)}
                />
              </Box>
            )}
          </Main>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth
              }
            }}
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: "transparent",
                border: "none",
                marginTop: "65px"
              }
            }}
          >
            {selectedUser && (
              <UserInfoContainer
                item={selectedUser}
                reFetch={() =>
                  fetchData(undefined, getPage.pagging?.currentPage)
                }
                permisisons={permissions}
              />
            )}
          </Drawer>
        </Box>
      </Wrapper>
    </LoadingOverlayWrapper>
  );
};

export default Member;
