import { Modal } from "../../../../components";
import * as Yup from "yup";
import useAxios from "../../../../components/UseAxios/useAxios";
import { ITimeOffOperation } from "../../../../models/timeoff";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toggleMessage } from "../../../../components/Toast/Toast";
import TimeOffTypeService from "../../../../services/api/timeofftype.service";
import { ITimeOffType } from "../../../../models/timeofftype";
import { Box, Typography } from "@mui/material";

interface IProps {
  open: boolean;
  type: ITimeOffType;
  onDeleteSuccess: () => void;
  handleClose: () => void;
}

const DeleteTimeOffTypeModal = ({
  open,
  type,
  onDeleteSuccess,
  handleClose
}: IProps) => {
  const deleteTimeOffTypeAxios = useAxios<ITimeOffOperation>({
    loading: "OnRequest"
  });

  useEffect(() => {
    if (deleteTimeOffTypeAxios.error)
      toggleMessage({
        type: "error",
        message: deleteTimeOffTypeAxios.error?.message ?? ""
      });
    if (deleteTimeOffTypeAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: deleteTimeOffTypeAxios.message ?? "Cập nhật thành công"
      });
      onDeleteSuccess();
      handleClose();
    }
  }, [deleteTimeOffTypeAxios.error, deleteTimeOffTypeAxios.isSuccess]);

  const onSubmit = async (event: any) => {
    deleteTimeOffTypeAxios.request(
      TimeOffTypeService.deleteTimeOffType(type.id)
    );
  };

  const validationSchema = Yup.object().shape({}) as any;
  const {
    control,
    handleSubmit,

    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(validationSchema)
  });
  return (
    <Modal
      title={"Xác nhận xoá loại nghỉ phép"}
      textSubmit={"Xác nhận"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={deleteTimeOffTypeAxios.isLoading}
      disabledSubmit={deleteTimeOffTypeAxios.isLoading}
      onClose={
        !deleteTimeOffTypeAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="body1" color="black" fontWeight={400}>
          Xác nhận xoá{" "}
          <Box component={"span"} fontWeight={600} fontSize={16}>
            {" "}
            {`${type.name}`}
          </Box>
        </Typography>
      </form>
    </Modal>
  );
};
export default DeleteTimeOffTypeModal;
