import { ControlForm, Modal } from "../../../../components";
import * as Yup from "yup";
import useAxios from "../../../../components/UseAxios/useAxios";
import { ITimeOffOperation } from "../../../../models/timeoff";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toggleMessage } from "../../../../components/Toast/Toast";
import TimeOffTypeService from "../../../../services/api/timeofftype.service";
import {
  ICreateTimeOffTypeRequest,
  ITimeOffType,
  IUpdateTimeOffTypeRequest
} from "../../../../models/timeofftype";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ETimeOffOperation } from "../../../../models/common/models.enum";
import InputForm from "../../../../components/controls/InputForm";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

interface IProps {
  open: boolean;
  type?: ITimeOffType;
  onCreateSuccess: () => void;
  handleClose: () => void;
}

interface DataForm {
  name: string;
  operation: ETimeOffOperation;
}

const TimeOffTypeOperation = [
  {
    title: "Có",
    value: ETimeOffOperation.Minus
  },
  {
    title: "Không",
    value: ETimeOffOperation.NotOperation
  }
];

const CreateTimeOffTypeModal = ({
  open,
  type,
  onCreateSuccess,
  handleClose
}: IProps) => {
  const createTimeOffTypeAxios = useAxios<ITimeOffOperation>({
    loading: "OnRequest"
  });

  useEffect(() => {
    reset({
      name: type?.name,
      operation: type?.operation ?? ETimeOffOperation.Minus
    });
    createTimeOffTypeAxios.reset();
  }, [open]);

  useEffect(() => {
    if (createTimeOffTypeAxios.error)
      toggleMessage({
        type: "error",
        message: createTimeOffTypeAxios.error?.message ?? ""
      });
    if (createTimeOffTypeAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: createTimeOffTypeAxios.message ?? ""
      });
      onCreateSuccess();
      handleClose();
    }
  }, [createTimeOffTypeAxios.error, createTimeOffTypeAxios.isSuccess]);

  const onSubmit = async (event: DataForm) => {
    if (type) {
      const request: IUpdateTimeOffTypeRequest = {
        id: type.id,
        name: event.name,
        operation: event.operation
      };

      createTimeOffTypeAxios.request(
        TimeOffTypeService.updateTimeOffType(request)
      );
    } else {
      const request: ICreateTimeOffTypeRequest = {
        Name: event.name,
        Operation: event.operation
      };

      createTimeOffTypeAxios.request(
        TimeOffTypeService.createTimeOffType(request)
      );
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(`Vui lòng nhập tên loại nghỉ phép`),
    operation: Yup.number().required(`Vui lòng chọn loại`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });
  return (
    <Modal
      title={type ? "Chỉnh sửa loại nghỉ phép" : "Thêm mới loại nghỉ phép"}
      textSubmit={"Xác nhận"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={createTimeOffTypeAxios.isLoading}
      disabledSubmit={createTimeOffTypeAxios.isLoading}
      onClose={
        !createTimeOffTypeAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm title="Tên loại nghỉ phép" classname="brand-form">
          <InputForm
            placeholder="Tên loại nghỉ phép"
            name="name"
            errors={errors}
            control={control}
            required
            size="small"
          />
        </ControlForm>
        <ControlForm title="Tính phép năm" classname="brand-form">
          <Controller
            control={control}
            name="operation"
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  displayEmpty
                  labelId="simple-select-label"
                  id="jobAssign-select"
                  IconComponent={ExpandLessIcon}
                  sx={{ fontSize: 13 }}
                >
                  {TimeOffTypeOperation.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      value={option.value}
                      key={index}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.operation && (
                  <FormHelperText sx={{ color: "#ED3E47" }}>
                    {errors.operation.message ?? ""}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};
export default CreateTimeOffTypeModal;
