export enum Roles {
  Member = "Member",
  Admin = "Admin",
  SuperAdmin = "SuperAdmin"
}

export enum FeatureModel {
  HR = "HR",
  Department = "Department",
  MeetingRoom = "MeetingRoom",
  MeetingRoomSchedule = "MeetingRoomSchedule",
  TimeOff = "TimeOff",
  TimeOffType = "TimeOffType",
  Rules = "Rules",
  Document = "Document",
  DocumentType = "DocumentType"
}

export enum TimeOffPermissionModel {
  View = "View",
  ManagerTimeOff = "ManagerTimeOff",
  AdminTimeOff = "AdminTimeOff",
  ManagerUpdateStatus = "ManagerUpdateStatus",
  AdminUpdateStatus = "AdminUpdateStatus",
  Create = "Create",
  Delete = "Delete",
  AdminDelete = "AdminDelete"
}

export enum TimeOffTypePermissionModel {
  View = "View",
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
  MailCC = "UpdateMailCC"
}

export enum DepartMentPermissionModel {
  View = "View",
  ViewAll = "ViewAll",
  Create = "Create",
  Update = "Update",
  Delete = "Delete"
}

export enum MeetingRoomPermissionModel {
  View = "View",
  Create = "Create",
  Update = "Update",
  Delete = "Delete"
}

export enum MeetingRoomSchedulePermissionModel {
  View = "View",
  Create = "Create",
  Update = "Update",
  UserUpdateStatus = "UserUpdateStatus",
  AdminUpdateStatus = "AdminUpdateStatus",
  Delete = "Delete"
}

export enum HRPermissionModel {
  ViewListUser = "ViewListUser",
  RegisterUser = "RegisterUser",
  EditUser = "EditUser",
  DeleteUser = "DeleteUser",
  ViewUserRegisterTimeOff = "ViewUserRegisterTimeOff",
  GetMaillCC = "GetMaillCC",
  UpdateMailCC = "UpdateMailCC",
  UpdateTotalTimeOffForUser = "UpdateTotalTimeOffForUser"
}

export enum RulesPermissionModel {
  View = "View",
  Update = "Update"
}

export enum DocumentPermissionModel {
  View = "View",
  AdminView = "AdminView",
  Create = "Create",
  Update = "Update",
  Delete = "Delete"
}

export enum DocumentTypePermissionModel {
  View = "View",
  Create = "Create",
  Update = "Update",
  Delete = "Delete"
}

export enum ObjectStatus {
  Active = "active",
  DeActive = "deactive"
}
export enum OrderDirection {
  DESC = "DESC",
  ASC = "ASC"
}
export enum EImageType {
  png = ".png",
  jpeg = ".jpeg",
  jpg = ".jpg"
}

export enum ECurrencyCode {
  VND = "VND",
  USD = "USD",
  EUR = "EUR"
}

export enum ELanguage {
  Vie = "vi-VN",
  Eng = "en-US"
}

export enum EFlow {
  Inflow = 0,
  Outflow = 1
}

export enum EModeModal {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  NOTHING = "NOTHING"
}

export enum ExchangeRateType {
  USDtoVND = "USD/VND",
  EURtoVND = "EUR/VND",
  EURtoUSD = "EUR/USD"
}

export enum TypeLink {
  internal = "internal",
  external = "external"
}

export enum UserAction {
  EditInformation = "Chỉnh sửa thông tin cá nhân",
  ChangePassword = "Thay đổi mật khẩu",
  ChangeManage = "Chỉnh sửa người quản lý trực tiếp",
  ChangeAdministration = "Phân quyền quản trị",
  UpdateTotalTimeOffForUser = "Cập nhật phép năm"
}

export enum TagType {
  User = 0,
  Department = 1
}

export enum EStageType {
  Step = 0,
  Cancel = 1,
  Complete = 2
}

export enum ETaskStatus {
  Inprogress = 0,
  Cancel = 1,
  Complete = 2,
  Failed = 3
}

export enum EWorkflowProcess {
  Continue = 0,
  First = 1,
  Other = 2,
  TaskAssigneeDecides = 3,
  SelectStep = 4,
  Randomly_Select_One_Person_To_Take_On_The_Task = 5
}

export enum EFireBaseType {
  AssignTask = 0,
  UpdateTask = 1,
  UpdateBoard = 2,
  RegisterTimeOff = 3,
  AprroveTimeOff = 4,
  NotAprroveTimeOff = 5,
  RegisterMeetingRoomSchedule = 6,
  AwaitCancelMeetingRoomSchedule = 7,
  AprroveMeetingRoomSchedule = 8,
  CancelMeetingRoomSchedule = 9,
  ReleasedDocument = 10
}

export enum ETimeOffStatus {
  Pending,
  ManagerApprove,
  CEOApprove,
  NotApprove
}
export enum EDayOffSession {
  Morning,
  Afternoon,
  AllDay
}

export enum ETimeOffOperation {
  NotOperation,
  Plus,
  Minus
}

export enum ETimeOffType {
  Leave_of_absence_with_permission_for_less_than_3days,
  Leave_of_absence_with_permission_for_more_than_3days,
  Leave_of_absence_without_permission_for_less_than_3days,
  Leave_of_absence_without_permission_for_more_than_3days
}

export const taskCardStatusColor = (
  status: ETaskStatus
): { color: string; bgcolor: string } => {
  switch (status) {
    case ETaskStatus.Inprogress:
      return { color: "#3091F3", bgcolor: "#EAF4FE" };
    case ETaskStatus.Cancel:
      return { color: "#F2A533", bgcolor: "#FEF6EB" };
    case ETaskStatus.Complete:
      return { color: "#54BD95", bgcolor: "#EEF8F4" };
    case ETaskStatus.Failed:
      return { color: "#ED3E47", bgcolor: "#FDECED" };
    default:
      return { color: "#000000", bgcolor: "000000" };
  }
};

export const taskStatusTitle = (status: ETaskStatus): string => {
  switch (status) {
    case ETaskStatus.Inprogress:
      return "Đang thực hiện";
    case ETaskStatus.Cancel:
      return "Huỷ";
    case ETaskStatus.Complete:
      return "Hoàn thành";
    case ETaskStatus.Failed:
      return "Lỗi";
    default:
      return "Không xác định";
  }
};

export enum EFileType {
  jpg = ".jpg",
  jpeg = ".jpeg",
  png = ".png",
  gif = ".gif",
  mp4 = ".mp4",
  mov = ".mov",
  avi = ".avi",
  flv = ".flv",
  docx = ".docx",
  doc = ".doc",
  xlxs = ".xlsx",
  cvs = ".cvs",
  pdf = ".pdf",
  pptx = ".pptx",
  ppt = ".ppt"
}

export enum EFireBaseTypeEnum {
  AssignTask = 0,
  UpdateTask = 1,
  UpdateBoard = 2
}

export enum EMeetingRoomStatus {
  Pending = 0,
  Confirmed = 1,
  Cancel = 2,
  Completed = 3,
  WaitCancel = 4
}

export enum EMeetingRoomScheduleTypeEnum {
  Month,
  Week,
  Day
}

export const meetingRoomStatusColor = (
  status?: EMeetingRoomStatus | null
): { color: string; bgcolor: string } => {
  switch (status) {
    case EMeetingRoomStatus.WaitCancel:
      return { color: "#E7B036", bgcolor: "#FFF8E8" };
    case EMeetingRoomStatus.Confirmed:
      return { color: "#54BD95", bgcolor: "#EEF8F4" };
    case EMeetingRoomStatus.Cancel:
      return { color: "#ED3E47", bgcolor: "#FFEFF0" };
    case EMeetingRoomStatus.Pending:
      return { color: "#3091F3", bgcolor: "#EAF4FE" };
    default:
      return { color: "#AAAAB2", bgcolor: "#F0F0F0" };
  }
};

export const meetingRoomStatusTitle = (status?: EMeetingRoomStatus | null) => {
  switch (status) {
    // case EMeetingRoomStatus.Pending:
    //   return { color: "#3091F3", bgcolor: "#EAF4FE" };
    case EMeetingRoomStatus.Pending:
      return "Chờ duyệt";
    case EMeetingRoomStatus.Confirmed:
      return "Đã duyệt";
    case EMeetingRoomStatus.Cancel:
      return "Đã huỷ";
    case EMeetingRoomStatus.WaitCancel:
      return "Chờ huỷ";
    case EMeetingRoomStatus.Completed:
      return "Đã kết thúc";
    default:
      return "Không xác định";
  }
};

export enum EDocumentStatus {
  PendingRelease = 0,
  Released = 1,
  Revocation = 2
}

export const documentStatusTitle = (status?: EDocumentStatus | null) => {
  switch (status) {
    case EDocumentStatus.PendingRelease:
      return "Chờ ban hành";
    case EDocumentStatus.Released:
      return "Đã ban hành";
    case EDocumentStatus.Revocation:
      return "Đã thu hồi";
    default:
      return "Không xác định";
  }
};

export const documentStatusColor = (
  status?: EDocumentStatus | null
): { color: string; bgcolor: string } => {
  switch (status) {
    case EDocumentStatus.PendingRelease:
      return { color: "#3091F3", bgcolor: "#EAF4FE" };
    case EDocumentStatus.Released:
      return { color: "#54BD95", bgcolor: "#EEF8F4" };
    case EDocumentStatus.Revocation:
      return { color: "#ED3E47", bgcolor: "#FFEFF0" };
    default:
      return { color: "#AAAAB2", bgcolor: "#F0F0F0" };
  }
};
