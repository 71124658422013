import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
  forwardRef
} from "react";
import { BoardProjectWrapper } from "./styles";
import { IWorkflow } from "../../../models/workflow";
import { IRoleBoard, IStage } from "../../../models/Stage";
import { ITaskCard } from "../../../models/Task";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  EStageType,
  EWorkflowProcess
} from "../../../models/common/models.enum";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ActionProject from "../ActionProject";
import AdvancedOptionsModal from "../AdvancedOptionsModal";
import CreateStageModal from "../CreateStageModal/create_stage_modal";
import ManageDragModal from "../ManageDragModal";
import UpdateTaskTransitionModal from "../UpdateTaskTransitionModal/UpdateTaskTransitionModal";
import { toggleMessage } from "../../../components/Toast/Toast";
import useAxios from "../../../components/UseAxios/useAxios";
import StageService from "../../../services/api/stage.service";
import Modal from "../../../components/Modal";
import { Box, Dialog, Slide, Typography } from "@mui/material";
import { authTokens } from "../../../services/services";
import { IUser } from "../../../models/user";
import { TransitionProps } from "@mui/material/transitions";
import variableStyles from "../../../theme/variable-styles";
import TaskDetail from "../Task/components/JobDetail/JobDetail";
import {
  Drag,
  DragDropZone,
  DragDropGuide,
  DragDropZones,
  DragDragItem
} from "./components/Drag/drag";
import StageColumn from "./components/StageColumn/stage_column";
import TaskCard from "./components/TaskCard/task_card";

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  stages: IStage[];
  setStages: (newStages: IStage[]) => void;
  onReload: () => void;
  onCloseWorkflow: (id: string) => void;
  onUpdateStagePosition: (currentPosition: IStage, newPosition: IStage) => void;
  onUpdateTaskPosition: (
    currentTask: ITaskCard,
    newPosition: IStage,
    index: number
  ) => void;
  filter: ReactNode;
}
interface PositionCreateState {
  stageSelected?: IStage;
  isUpdate?: boolean;
  side: number;
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BoardProject1: FC<Props> = ({
  workflow,
  stages,
  setStages,
  role,
  onReload,
  onCloseWorkflow,
  onUpdateStagePosition,
  onUpdateTaskPosition,
  filter
}) => {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [openCreateStage, setOpenCreateStage] = useState(false);
  const [openUpdateStage, setOpenUpdateStage] = useState(false);
  const deleteStage = useAxios<string>({ loading: "OnRequest" });
  const [positionSelectedStage, setPositionCreateStage] =
    useState<PositionCreateState>({ side: 0 });
  const [openManageDragStage, setOpenManageDragStage] = useState(false);
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);
  const [openTaskDetail, setopenTaskDetail] = useState<ITaskCard | null>(null);

  const [deleteStageDialog, setDeleteStageDialog] = useState<{
    open: boolean;
    stage: IStage | null;
  }>({ open: false, stage: null });

  const [updateTaskTransition, setUpdateTaskTransition] = useState<{
    open: boolean;
    task: ITaskCard;
    stage: IStage;
    newStages: IStage[];
    index: number;
  } | null>();

  const stageCached = useMemo(() => {
    if (!updateTaskTransition) return stages;
  }, [updateTaskTransition, stages]);

  const hasStepStage = useMemo(
    () => stages.find(e => e.type === EStageType.Step),
    [stages]
  );

  useEffect(() => {
    authTokens.getUser().then(user => setUserInfo(user));
  }, []);

  const handleUpdateTaskPosition = useCallback(
    async (
      newStages: IStage[],
      activeTask: ITaskCard,
      newStage: IStage,
      index: number
    ) => {
      if (
        newStage.id !== activeTask.workflowId &&
        newStage.reassignedWork === EWorkflowProcess.TaskAssigneeDecides
      ) {
        setUpdateTaskTransition({
          open: true,
          task: activeTask,
          stage: newStage,
          newStages: newStages,
          index: index
        });
        return;
      }
      setStages(newStages);
      onUpdateTaskPosition(activeTask, newStage, index);
    },
    [onUpdateTaskPosition, setStages]
  );

  const handleAdd = () => {
    setPositionCreateStage({ side: 1 });
    setOpenCreateStage(true);
  };

  const handleLeftAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 1 });
    setOpenCreateStage(true);
  };

  const handleRightAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 2 });
    setOpenCreateStage(true);
  };

  const handleAddSuccess = () => {
    onReload();
  };

  const handleUpdateWorkflowSuccess = () => {
    onReload();
  };

  const handleCopyWorkflowSuccess = () => {
    onReload();
  };

  const handleUpdateStage = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 0, isUpdate: true });
    setOpenUpdateStage(true);
  };

  const handleDeleteStage = async (stage: IStage) => {
    handleOpenDeleteStageDialog(stage);
  };

  const handleOpenDeleteStageDialog = (stage: IStage) => {
    setDeleteStageDialog({ open: true, stage: stage }); // Open the delete confirmation dialog
  };

  const handleCloseDeleteStageDialog = () => {
    setDeleteStageDialog({ open: false, stage: null }); // Close the delete confirmation dialog
  };

  const handleUpdateTaskcard = (newTask: ITaskCard) => {
    const stageIndex = stages.findIndex(e => e.id === newTask.workflowId);
    const taskIndex = stages[stageIndex].taskCards.findIndex(
      e => e.id === newTask.id
    );
    const newStages = structuredClone(stages);
    newStages[stageIndex].taskCards[taskIndex] = newTask;
    setStages(newStages);
  };

  const handleDeletedTaskcard = (newTask: ITaskCard) => {
    const stageIndex = stages.findIndex(e => e.id === newTask.workflowId);
    const taskIndex = stages[stageIndex].taskCards.findIndex(
      e => e.id === newTask.id
    );
    const newStages = structuredClone(stages);
    newStages[stageIndex].taskCards.splice(taskIndex, 1);
    setStages(newStages);
  };

  const handleRollbackTaskcard = () => {
    onReload();
  };

  const handleConfirmDelete = () => {
    deleteStage.request(
      StageService.deleteStage(deleteStageDialog.stage!.id as string)
    );
  };

  useEffect(() => {
    if (deleteStage.isSuccess) {
      onReload();
      handleCloseDeleteStageDialog(); // Close the dialog after deletion
    }
    if (deleteStage.error) {
      toggleMessage({
        type: "error",
        message: deleteStage.error.message ?? ""
      });
    }
  }, [deleteStage.isSuccess, deleteStage.error, onReload]);

  const handleDrop = useCallback(
    ({
      dragItem,
      dragType,
      drop
    }: {
      dragItem: UniqueIdentifier | null;
      dragType: string | null;
      drop: any;
    }) => {
      if (dragType === "card" && drop !== undefined && drop !== null) {
        let [newListPosition, newCardPosition] = drop!
          .toString()
          .split("|")
          .map((string: any) => parseInt(string as string));

        let newStages = structuredClone(stages);
        let oldCardPosition: number;
        let oldListPosition = stages.findIndex(stage => {
          oldCardPosition = stage.taskCards.findIndex(
            card => card.id === dragItem
          );
          return oldCardPosition >= 0;
        });

        let card = stages[oldListPosition].taskCards[oldCardPosition!];

        if (
          newListPosition === oldListPosition &&
          oldCardPosition! < newCardPosition
        ) {
          newCardPosition--;
        }

        newStages[oldListPosition].taskCards.splice(oldCardPosition!, 1);
        newStages[newListPosition].taskCards.splice(newCardPosition, 0, card);

        if (
          JSON.stringify(stages[newListPosition].taskCards) ===
          JSON.stringify(newStages[newListPosition].taskCards)
        )
          return;

        handleUpdateTaskPosition(
          newStages,
          newStages[newListPosition].taskCards[newCardPosition],
          newStages[newListPosition],
          newCardPosition
        );
      } else if (dragType === "list" && drop !== undefined && drop !== null) {
        let newListPosition = drop!;
        let oldListPosition = stages.findIndex(stage => stage.id === dragItem);

        let newStages = structuredClone(stages);
        let stage = stages[oldListPosition];
        if (oldListPosition < newListPosition) {
          newListPosition--;
        }
        newStages.splice(oldListPosition, 1);
        newStages.splice(newListPosition, 0, stage);

        if (JSON.stringify(stages) === JSON.stringify(newStages)) return;

        setStages(newStages);
        onUpdateStagePosition(
          newStages[newListPosition],
          newStages[oldListPosition]
        );
      }
    },
    [handleUpdateTaskPosition, setStages, stages, onUpdateStagePosition]
  );

  return (
    <>
      <BoardProjectWrapper className="flex flex-col overflow-hidden">
        {filter}

        <Drag handleDrop={handleDrop} stages={stages}>
          {({ activeItem, activeType, isDragging, forbiddenZones }) => (
            <DragDropZone className="flex overflow-auto h-auto w-full ">
              {stages.map((stage, stagePosition) => {
                return (
                  <React.Fragment key={stage.id}>
                    <DragDropZone
                      dropId={stagePosition}
                      dropType="list"
                      remember={true}
                    >
                      <DragDropGuide
                        dropId={stagePosition}
                        dropType="list"
                        className=" bg-gray-200 h-96 w-64 shrink-0 grow-0"
                      />
                    </DragDropZone>
                    <DragDropZones
                      className="flex flex-col h-auto"
                      prevId={stagePosition}
                      nextId={stagePosition + 1}
                      dropType="list"
                      split="x"
                      disable={forbiddenZones.includes(stage.id)}
                      remember={true}
                    >
                      <DragDragItem
                        dragId={stage.id}
                        className={`cursor-pointer ${activeItem === stage.id && activeType === "list" && isDragging ? "hidden" : "translate-x-0"}`}
                        dragType="list"
                        draggable={
                          stage.type === EStageType.Step &&
                          !(
                            role.isCreator === false &&
                            role.isProcessManagement === false
                          )
                        }
                      >
                        <StageColumn
                          name={stage.name}
                          dragItem={
                            activeItem === stage.id && activeType === "list"
                          }
                          stage={stage}
                          roleWorkflow={role}
                          isShowMenu={stage.type === EStageType.Step}
                          onLeftAdd={() => handleLeftAdd(stage)}
                          onRightAdd={() => handleRightAdd(stage)}
                          onDelete={() => handleDeleteStage(stage)}
                          onUpdate={() => handleUpdateStage(stage)}
                        >
                          {stages[stagePosition].taskCards.map(
                            (card, cardPosition) => {
                              return (
                                <DragDropZones
                                  key={card.id}
                                  prevId={`${stagePosition}|${cardPosition}`}
                                  nextId={`${stagePosition}|${cardPosition + 1}`}
                                  dropType="card"
                                  disable={forbiddenZones.includes(stage.id)}
                                  remember={true}
                                >
                                  <DragDropGuide
                                    dropId={`${stagePosition}|${cardPosition}`}
                                    className=" bg-gray-200 h-24 "
                                    dropType="card"
                                  />
                                  <DragDragItem
                                    dragId={card.id}
                                    className={`cursor-pointer ${activeItem === card.id && activeType === "card" && isDragging ? "hidden" : "translate-x-0"}`}
                                    dragType="card"
                                    draggable={
                                      !(
                                        !role.isCreator &&
                                        !role.isProcessManagement &&
                                        userInfo?.id !== card.personAssignedId
                                      )
                                    }
                                  >
                                    <TaskCard
                                      dragItem={
                                        activeItem === card.id &&
                                        activeType === "card"
                                      }
                                      roleStage={stage.roleWorkflowByUser}
                                      roleWorkflow={role}
                                      onClick={() => setopenTaskDetail(card)}
                                      onUpdateSuccess={newTaskcard =>
                                        handleUpdateTaskcard(newTaskcard)
                                      }
                                      onDeleteSuccess={newTaskcard =>
                                        handleDeletedTaskcard(newTaskcard)
                                      }
                                      onRollBackSuccess={newTaskcard =>
                                        handleRollbackTaskcard()
                                      }
                                      task={card}
                                      canReassignTask={
                                        stage.reassignedWork ===
                                          EWorkflowProcess.TaskAssigneeDecides &&
                                        userInfo?.id === card.personAssignedId
                                      }
                                    />
                                  </DragDragItem>
                                </DragDropZones>
                              );
                            }
                          )}
                          <DragDropZone
                            dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                            dropType="card"
                            disable={forbiddenZones.includes(stage.id)}
                            remember={true}
                          >
                            <DragDropGuide
                              dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                              className=" bg-gray-200 h-24"
                              dropType="card"
                            />
                          </DragDropZone>
                        </StageColumn>
                      </DragDragItem>
                      <DragDropZone
                        dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                        className="grow"
                        dropType="card"
                        disable={forbiddenZones.includes(stage.id)}
                        remember={true}
                      />
                    </DragDropZones>
                  </React.Fragment>
                );
              })}
              <DragDropZone
                dropId={stages.length}
                dropType="list"
                remember={true}
              >
                <DragDropGuide
                  dropId={stages.length}
                  dropType="list"
                  className=" bg-gray-200 h-96 w-64 shrink-0 grow-0"
                />
              </DragDropZone>
            </DragDropZone>
          )}
        </Drag>
        <CreateStageModal
          open={openCreateStage || openUpdateStage}
          workflow={workflow}
          isUpdate={openUpdateStage}
          {...positionSelectedStage}
          handleClose={() =>
            openCreateStage
              ? setOpenCreateStage(false)
              : setOpenUpdateStage(false)
          }
          handleSuccess={handleAddSuccess}
        />
        <ManageDragModal
          open={openManageDragStage}
          handleClose={() => setOpenManageDragStage(false)}
        />
        <AdvancedOptionsModal
          open={openAdvancedOptions}
          handleClose={() => setOpenAdvancedOptions(false)}
        />
        <Modal
          title={"Xác nhận xóa giai đoạn"}
          textSubmit={"Xác nhận"}
          textClose="Huỷ bỏ"
          open={deleteStageDialog.open}
          onSubmit={handleConfirmDelete}
          disabledSubmit={deleteStage.isLoading}
          onClose={handleCloseDeleteStageDialog}
        >
          {"Bạn có chắc chắn muốn xóa giai đoạn này?"}
        </Modal>
        {updateTaskTransition && (
          <UpdateTaskTransitionModal
            open={updateTaskTransition.open}
            workflowId={updateTaskTransition.stage.id}
            newStages={updateTaskTransition.newStages}
            task={updateTaskTransition.task}
            onUpdateSuccess={() => {
              setStages(updateTaskTransition.newStages);
              onUpdateTaskPosition(
                updateTaskTransition.task,
                updateTaskTransition.stage,
                updateTaskTransition.index
              );
              setTimeout(() => {
                setUpdateTaskTransition(null);
              }, 600);
            }}
            handleClose={() => {
              setStages(stageCached ?? stages);
              setUpdateTaskTransition(null);
            }}
          />
        )}
      </BoardProjectWrapper>
      <ActionProject
        workflow={workflow}
        role={role}
        hasStepStage={!!hasStepStage}
        onCreateTaskSuccess={() => onReload()}
        onCreateStage={() => handleAdd()}
        onCloseWorkflow={() => onCloseWorkflow(workflow.id!)}
        onUpdateWorkflowSuccess={() => handleUpdateWorkflowSuccess()}
        onCopyWorkflowSuccess={() => handleCopyWorkflowSuccess()}
      />
      {openTaskDetail && (
        <Dialog
          open={openTaskDetail !== null}
          keepMounted
          maxWidth="xl"
          fullWidth
          onClose={() => setopenTaskDetail(null)}
          TransitionComponent={Transition}
          aria-describedby="timeoff-dialog-export"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#267CDE",
              padding: "8px 16px"
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color={variableStyles.NaturalColor300}
            >
              {openTaskDetail?.title}
            </Typography>
            <CloseRoundedIcon
              onClick={() => setopenTaskDetail(null)}
              sx={{ color: "white", height: 24, width: 24 }}
            />
          </Box>

          <Box sx={{ overflowY: "auto" }}>
            <TaskDetail
              id={openTaskDetail!.id as string}
              onJobUpdated={() => onReload()}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default BoardProject1;
