import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  // margin: "24px",
  width: "70%",
  margin: "32px auto",
  "& .header": {
    display: "flex"
  },

  "& .account-identity": {
    display: "flex",
    marginTop: "6vh"
  },

  "& .account-info": {
    display: "flex",
    margin: "32px 12px",
    "& .info-row": {
      marginBottom: "24px",
      "& .row-infomation": {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        svg: {
          color: "#AAAAB2",
          fontSize: "20px"
        },
        p: {
          fontSize: "14px",
          marginLeft: "12px"
        }
      }
    }
  }
}));
