import { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import DocsUserView from "./components/DocsUserView/DocsUserView";
import DocsAdminView from "./components/DocsAdminView/DocsAdminView";
import { useApi } from "../../hooks/useApi.hook";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import {
  DocumentPermissionModel,
  DocumentTypePermissionModel,
  FeatureModel
} from "../../models/common/models.enum";
import { authTokens } from "../../services/services";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";

type Props = {};

const Docs = (props: Props) => {
  let navigate = useNavigate();
  const [userId, setUserId] = useState<string | undefined>();
  const [permisisons, setPermisisons] = useState<string[] | null>(null);
  const [typePermisisons, setTypePermisisons] = useState<string[] | null>(null);
  const getFeaturePersmission = useApi<IFeaturePermission[]>({ isFetch: true });
  const [contentView, setContentView] = useState(
    <p>Không có nội dung hiển thị</p>
  );

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserId(user?.id);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userId) {
      getFeaturePersmission.request(
        RoleService.getFeaturePemissionByUserId(userId).then(res => {
          if (res.success) {
            const permission = res.data.find(
              per => per.feature === FeatureModel.Document
            );
            const typepermission = res.data.find(
              per => per.feature === FeatureModel.DocumentType
            );
            if (typepermission) {
              setTypePermisisons(typepermission.permissions);
            }
            if (permission) {
              setPermisisons(permission.permissions);
            } else {
              navigate(ROUTE_PATH.FORBIDDEN);
            }
          }
        })
      );
    }
  }, [userId]);

  useEffect(() => {
    if (permisisons?.includes(DocumentPermissionModel.AdminView)) {
      setContentView(
        <DocsAdminView
          isCreate={permisisons.includes(DocumentPermissionModel.Create)}
          isUpdate={permisisons.includes(DocumentPermissionModel.Update)}
          isDelete={permisisons.includes(DocumentPermissionModel.Delete)}
          isCreateType={
            typePermisisons?.includes(DocumentTypePermissionModel.Create) ??
            false
          }
          isUpdateType={
            typePermisisons?.includes(DocumentTypePermissionModel.Update) ??
            false
          }
          isDeleteType={
            typePermisisons?.includes(DocumentTypePermissionModel.Delete) ??
            false
          }
          isViewType={
            typePermisisons?.includes(DocumentTypePermissionModel.View) ?? false
          }
        />
      );
    } else if (permisisons?.includes(DocumentPermissionModel.View)) {
      setContentView(<DocsUserView />);
    }
  }, [permisisons]);

  return <Wrapper>{contentView}</Wrapper>;
};

export default Docs;
