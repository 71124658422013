import { Box, Button, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import FORBIDDEN from "../assets/images/common/403.png";

type Props = {};

const Forbidden = (props: Props) => {
  return (
    <Box sx={{ padding: 4 }} title="403 Page No Access | Miko Admin">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <motion.div>
            <Typography variant="h3" paragraph>
              Không có quyền truy cập
            </Typography>
          </motion.div>
          <Typography sx={{ color: "text.blueSecondary" }}>
            Không thể truy cập vào trang này, vui lòng liên hệ admin để được cấp
            quyền
          </Typography>
          <motion.div>
            <Box
              component="img"
              src={FORBIDDEN}
              sx={{ height: 300, mx: "auto", my: { xs: 2, sm: 5 } }}
            />
          </motion.div>
          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Trở về trang chủ
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Forbidden;
