import { Box, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { TIME_FORMAT } from "../../../config/constants";
import { meetingRoomStatusColor } from "../../../models/common/models.enum";
import { IMeetingSchedule } from "../../../models/meeting";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";

type Props = {
  item: IMeetingSchedule;
};

const ScheduleCard = ({ item }: Props) => {
  const icons = [
    <AccessTimeFilledRoundedIcon sx={{ color: "#3091F3" }} />, //pendiing
    <CheckCircleRoundedIcon sx={{ color: "#54BD95" }} />, //confirm
    <DoDisturbOnRoundedIcon sx={{ color: "#ED3E47" }} />, //Cancel
    <DoneAllRoundedIcon />, //Completed
    <HourglassFullRoundedIcon sx={{ color: "#E7B036" }} /> //WaitCancel
  ];

  return (
    <Box
      margin="8px 0"
      borderRadius="4px"
      padding="12px"
      display="flex"
      sx={{ backgroundColor: "white" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Box display={"flex"}>
                <Box
                  sx={{
                    backgroundColor: meetingRoomStatusColor(item.status).color,
                    width: "3px",
                    height: "auto",
                    marginRight: "12px"
                  }}
                />
                <Typography variant="subtitle2" component="div">
                  {item.title}
                </Typography>
              </Box>
              <Box display="flex" marginTop="6px">
                <AccessTimeRoundedIcon
                  sx={{ fontSize: 16, marginRight: "4px", color: "GrayText" }}
                />
                <Typography variant="caption">
                  {moment(item.startDate).format(TIME_FORMAT)}
                  {item.endDate &&
                    `- ${moment(item.endDate).format(TIME_FORMAT)}`}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item>
              <Typography sx={{ cursor: "pointer" }} variant="body2">
                Remove
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
              height: "32px",
              width: "32px",
              borderRadius: "32px",
              alignContent: "center"
            }}
          >
            {icons[item.status]}
          </IconButton>
        </Grid>
      </Grid>

      {/* <Grid container spacing={2}>
        <Grid item xs={11}>
          <Box display="flex" sx={{ whiteSpace: "normal" }}>
            <Box
              sx={{
                width: "2px",
                heihgt: "8px",
                backgroundColor: meetingRoomStatusColor(item.status).color,
                marginRight: "8px"
              }}
            />
            <Typography
              variant="subtitle2"
              fontSize="12px"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: "12px"
              }}
            >
              {item.title}
            </Typography>
          </Box>
          <Box display="flex" marginTop="6px">
            <AccessTimeRoundedIcon
              sx={{ fontSize: 16, marginRight: "4px", color: "GrayText" }}
            />
            <Typography variant="caption">
              {moment(item.startDate).format(TIME_FORMAT)}
              {item.endDate && `- ${moment(item.endDate).format(TIME_FORMAT)}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
              height: "32px",
              width: "32px",
              borderRadius: "32px",
              alignContent: "center"
            }}
          >
            {icons[item.status]}
          </IconButton>
        </Grid>
      </Grid> */}
      {/* <Box flexGrow={1}>
        <Box display="flex" sx={{ whiteSpace: "normal" }}>
          <Box
            sx={{
              width: "2px",
              heihgt: "8px",
              backgroundColor: meetingRoomStatusColor(item.status).color,
              marginRight: "8px"
            }}
          />
          <Typography
            variant="subtitle2"
            fontSize="12px"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              marginRight: "12px"
            }}
          >
            {item.title}
          </Typography>
        </Box>
        <Box display="flex" marginTop="6px">
          <AccessTimeRoundedIcon
            sx={{ fontSize: 16, marginRight: "4px", color: "GrayText" }}
          />
          <Typography variant="caption">
            {moment(item.startDate).format(TIME_FORMAT)}
            {item.endDate && `- ${moment(item.endDate).format(TIME_FORMAT)}`}
          </Typography>
        </Box>
      </Box>
      <IconButton
        sx={{
          backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
          height: "32px",
          width: "32px",
          borderRadius: "32px",
          alignContent: "center"
        }}
      >
        {icons[item.status]}
      </IconButton> */}
    </Box>
  );
};

export default ScheduleCard;
