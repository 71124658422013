import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  maxHeight: "calc(100vh - 52px)",
  overflow: "hidden",
  width: "100%",

  "& .content-list": {
    width: "28%",
    "& .content-list-item": {
      maxHeight: "calc(100vh - 52px - 100px)",
      overflow: "auto",
      justifyItems: "center",
      "& .list-item": {
        width: "100%",
        backgroundColor: "white",
        borderBottom: "1px solid #C7C8C9"
      }
    }
  },

  "& .content-page": {
    // width: "72%",
    flexGrow: 1,
    backgroundColor: "white",
    minHeight: "calc(100vh - 52px)",
    height: "auto",
    padding: "62px 72px",
    overflow: "auto",
    borderLeft: "2px solid #C7C8C9",
    "& .empty-box": {
      justifyItems: "center",
      margin: "25vh"
    }
  }
}));
