import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { IStage } from "../../../models/Stage";

interface IProps {
  stages: IStage[];
  value: IStage | undefined;
  onChange: (value: IStage | undefined) => void;
}

const StageFilter = ({ value, stages, onChange }: IProps) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          <button
            {...bindTrigger(popupState)}
            type="button"
            className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-400 rounded-full focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {value ? value.name : "Board"}
          </button>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            sx={{
              width: "80%",
              marginTop: "4px"
            }}
            // className="w-100"
          >
            <div className="flex flex-wrap align-middle my-2 mx-2 gap-1 ">
              <div
                className={`select-none p-2 mx-2 my-2 shrink-0 grow-0 w-30 items-center px-3 py-1 text-sm font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-green-300 dark:bg-gray-500 dark:hover:bg-green-700 dark:focus:ring-gray-500 ${value === undefined ? "bg-green-400 dark:bg-green-700" : "bg-gray-400 dark:bg-gray-500"}`}
                onClick={() => {
                  onChange(undefined);
                  popupState.close();
                }}
              >
                Board
              </div>
              {stages.map(stage => (
                <div
                  key={stage.id}
                  className={`select-none p-2 mx-2 my-2  shrink-0 grow-0 items-center px-3 py-1 text-sm font-medium text-center text-white  rounded-lg focus:outline-none focus:ring-green-300  dark:hover:bg-green-600 dark:focus:ring-gray-500 ${value?.id === stage.id ? "bg-green-400 dark:bg-green-700" : "bg-gray-400 dark:bg-gray-500"}`}
                  onClick={() => {
                    onChange(stage);
                    popupState.close();
                  }}
                >
                  {stage.name}
                </div>
              ))}
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
export default StageFilter;
