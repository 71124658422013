import { Box, Divider, Typography } from "@mui/material";
import { Avatar } from "../../../components";
import { IUser } from "../../../models/user";
import IconAdmin from "@mui/icons-material/AdminPanelSettingsOutlined";
import IconManage from "@mui/icons-material/ManageAccountsOutlined";
import IconEmail from "@mui/icons-material/MailOutlineRounded";
import IconCake from "@mui/icons-material/CakeOutlined";
import IconPlace from "@mui/icons-material/PlaceOutlined";
import IconTag from "@mui/icons-material/BookmarkBorderRounded";
import IconPhone from "@mui/icons-material/PhoneOutlined";
import CommonHandle from "../../../common/handles/common.handles";

type Props = {
  item: IUser;
};

function InfoRow(props: { title: string; content: string; icon: any }) {
  return (
    <Box className="info-row">
      <Box className="info-title">
        {props.icon}
        <p>{props.title}</p>
      </Box>
      <Typography variant="caption" className="info-content">
        {props.content}
      </Typography>
    </Box>
  );
}

const UserInformationBox = ({ item }: Props) => {
  return (
    <>
      <Box>
        <Typography variant="body2" fontWeight="600">
          THÔNG TIN NHÂN VIÊN
        </Typography>
        <Divider sx={{ margin: "8px 0" }} />
      </Box>
      <Box className="information">
        <Avatar
          src={item?.avatar}
          alt="Avatar-Image"
          width={"48px"}
          height={"48px"}
        />
        <Box className="information-name">
          <Typography variant="subtitle1">{item.fullName}</Typography>
          <Typography variant="body2">{item.userName}</Typography>
        </Box>
      </Box>

      <InfoRow
        title={"Chức danh"}
        content={item?.position ?? ""}
        icon={<IconTag />}
      />
      <InfoRow title={"Email"} content={item.email} icon={<IconEmail />} />
      <InfoRow
        title={"Số điện thoại"}
        content={item?.phoneNumber ?? "Chưa có thông tin"}
        icon={<IconPhone />}
      />
      <InfoRow
        title={"Ngày sinh"}
        content={
          item?.birthDay
            ? CommonHandle.formatDate(item?.birthDay)
            : "Chưa có thông tin"
        }
        icon={<IconCake />}
      />
      <InfoRow
        title={"Địa chỉ"}
        content={item?.address ?? "Chưa có thông tin"}
        icon={<IconPlace />}
      />
      <InfoRow
        title={"Quản lý trực tiếp"}
        content={item?.manager?.fullName ?? "Chưa có người quản lý"}
        icon={<IconManage />}
      />
      <InfoRow
        title={"Quyền tài khoản"}
        content={item.roles}
        icon={<IconAdmin />}
      />
    </>
  );
};

export default UserInformationBox;
