import { UniqueIdentifier } from "@dnd-kit/core";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { EStageType } from "../../../../../models/common/models.enum";
import { IStage } from "../../../../../models/Stage";

export interface DragContextType {
  draggable: boolean;
  dragItem: UniqueIdentifier | null;
  forbiddenZones: UniqueIdentifier[];
  dragType: string | null;
  isDragging: boolean | null;
  dragStart: (
    e: React.DragEvent,
    dragItem: UniqueIdentifier | null,
    dragType: string
  ) => void;
  drag: (
    e: React.DragEvent,
    dragItem: UniqueIdentifier | null,
    dragType: string
  ) => void;
  dragEnd: (e: React.DragEvent) => void;
  drop: string | null;
  setDrop: (drop: string | null) => void;
  onDrop: (e: React.DragEvent) => void;
}

const DragContext = React.createContext<DragContextType | undefined>(undefined);

interface DragProps {
  draggable?: boolean;
  stages: IStage[];
  handleDrop: (data: {
    dragItem: UniqueIdentifier | null;
    dragType: string | null;
    drop: string | null;
  }) => void;
  children:
    | ReactNode
    | ((data: {
        activeItem: UniqueIdentifier | null;
        activeType: string | null;
        forbiddenZones: UniqueIdentifier[];
        isDragging: boolean | null;
      }) => ReactNode);
}

export const Drag: FC<DragProps> = ({
  draggable = true,
  handleDrop,
  children,
  stages
}) => {
  const [dragType, setDragType] = useState<string | null>(null);
  const [dragItem, setDragItem] = useState<UniqueIdentifier | null>(null);
  const [forbiddenZones, setForbiddenZones] = useState<UniqueIdentifier[]>([]);
  const [isDragging, setIsDragging] = useState<boolean | null>(null);
  const [drop, setDrop] = useState<string | null>(null);

  useEffect(() => {
    document.body.style.cursor = dragItem ? "grabbing" : "default";
  }, [dragItem]);

  const dragStart = (
    e: React.DragEvent,
    dragId: UniqueIdentifier | null,
    dragType: string
  ) => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = "move";
    setDragItem(dragId);
    setDragType(dragType);
    if (dragType === "list") {
      const Ids = [...stages].reduce(function (
        a: UniqueIdentifier[],
        stage,
        _
      ) {
        if (stage.type !== EStageType.Step) a.push(stage.id);
        return a;
      }, []);

      setForbiddenZones(Ids);
    }
    if (dragType === "card") {
      const stageIndex = stages.findIndex(
        stage => stage.taskCards.findIndex(task => task.id === dragId) >= 0
      );
      const Ids = [...stages]
        .filter((_, i, __) => i > stageIndex + 1 || i < stageIndex - 1)
        .map(e => e.id);

      setForbiddenZones(Ids);
    }
  };

  const drag = (e: React.DragEvent) => {
    e.stopPropagation();
    setIsDragging(true);
  };

  const dragEnd = (e: React.DragEvent) => {
    setDragItem(null);
    setDragType(null);
    setIsDragging(false);
    setDrop(null);
    setForbiddenZones([]);
  };

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    handleDrop({ dragItem, dragType, drop });
    setDragItem(null);
    setDragType(null);
    setIsDragging(false);
    setDrop(null);
    setForbiddenZones([]);
  };

  return (
    <DragContext.Provider
      value={{
        draggable,
        dragItem,
        dragType,
        forbiddenZones,
        isDragging,
        dragStart,
        drag,
        dragEnd,
        drop,
        setDrop,
        onDrop
      }}
    >
      {typeof children === "function"
        ? children({
            activeItem: dragItem,
            activeType: dragType,
            forbiddenZones: forbiddenZones,
            isDragging
          })
        : children}
    </DragContext.Provider>
  );
};

interface DragItemProps {
  as?: React.ElementType;
  dragId: UniqueIdentifier | null;
  dragType: string;
  draggable?: boolean;
  [key: string]: any;
}

export const DragDragItem = ({
  draggable = true,
  as,
  dragId,
  dragType,
  ...props
}: DragItemProps) => {
  const { dragStart, drag, dragEnd } = useContext(DragContext)!;

  let Component = as || "div";
  return (
    <Component
      onDragStart={(e: any) => dragStart(e, dragId, dragType)}
      onDrag={drag}
      draggable={draggable}
      onDragEnd={dragEnd}
      {...props}
    />
  );
};

interface DropZoneProps {
  as?: React.ElementType;
  dropId?: any;
  dropType?: string;
  remember?: boolean;
  children?: ReactNode;
  disable?: boolean;
  style?: React.CSSProperties;
  [key: string]: any;
}

export const DragDropZone = ({
  as,
  dropId,
  dropType,
  remember,
  children,
  disable,
  style,
  ...props
}: DropZoneProps) => {
  const { dragItem, dragType, setDrop, drop, onDrop } =
    useContext(DragContext)!;

  const handleDragOver = (e: React.DragEvent) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    return false;
  };

  const handleLeave = () => {
    if (!remember) {
      setDrop(null);
    }
  };

  let Component = as || "div";

  return (
    <Component
      disable={disable ? disable.toString() : "true"}
      onDragEnter={(e: any) => {
        dragItem && dropType === dragType && !disable && setDrop(dropId);
      }}
      onDragOver={handleDragOver}
      onDrop={(e: React.DragEvent) => {
        if (drop !== undefined) onDrop(e);
      }}
      style={{ position: "relative", ...style }}
      {...props}
    >
      {children}
      {drop === dropId && (
        <div
          style={{
            position: "absolute",
            inset: "0px"
          }}
          onDragLeave={handleLeave}
        ></div>
      )}
    </Component>
  );
};

interface DropZonesProps {
  dropType: string;
  prevId: any;
  nextId: any;
  disable?: boolean;
  split?: "x" | "y";
  remember?: boolean;
  children: ReactNode;
  [key: string]: any;
}

export const DragDropZones = ({
  dropType,
  prevId,
  nextId,
  split = "y",
  disable,
  remember,
  children,
  ...props
}: DropZonesProps) => {
  const { dragType, isDragging } = useContext(DragContext)!;

  return (
    <div
      style={{
        position: "relative"
      }}
      {...props}
    >
      {children}
      {dragType === dropType && isDragging && (
        <div
          style={{
            position: "absolute",
            inset: "0px",
            display: "flex",
            flexDirection: split === "x" ? "row" : "column"
          }}
        >
          <DragDropZone
            dropId={prevId}
            disable={disable}
            style={{
              width: "100%",
              height: "100%"
            }}
            dropType={dropType}
            remember={remember}
          />

          <DragDropZone
            dropId={nextId}
            disable={disable}
            style={{
              width: "100%",
              height: "100%"
            }}
            dropType={dropType}
            remember={remember}
          />
        </div>
      )}
    </div>
  );
};

interface DropGuideProps {
  as?: React.ElementType;
  dropId: any;
  dropType: string;
  [key: string]: any;
}

export const DragDropGuide = ({
  as,
  dropId,
  dropType,
  ...props
}: DropGuideProps) => {
  const { drop, dragType } = useContext(DragContext)!;
  let Component = as || "div";

  return dragType === dropType && drop === dropId ? (
    <Component {...props} />
  ) : null;
};
