import {
  Box,
  Button,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import moment from "moment";
import { Wrapper } from "./styles";
import { ControlForm, Modal } from "../../../../components";
import { Controller, useForm } from "react-hook-form";
import InputForm from "../../../../components/controls/InputForm";
import {
  DATETIME_FORMAT,
  DATETIME_FORMAT3,
  DATETIME_FORMAT4,
  TIME_FORMAT
} from "../../../../config/constants";
import { TagInput } from "../../../WorkflowManagement/components/TagInput/TagInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ICreateMeetingScheduleReq,
  IMeetingRoom,
  IMeetingSchedule
} from "../../../../models/meeting";
import { useApi } from "../../../../hooks/useApi.hook";
import MeetingService from "../../../../services/api/meeting.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import {
  EMeetingRoomStatus,
  meetingRoomStatusColor,
  meetingRoomStatusTitle
} from "../../../../models/common/models.enum";
import CommonHandle from "../../../../common/handles/common.handles";
import ScheduleCard from "../ScheduleCard";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

type Props = {
  isCreate: boolean;
  refetch: Function;
  myBookings: IMeetingSchedule[];
};

const BookingHeader = ({ refetch, myBookings, isCreate }: Props) => {
  const now = moment().add(5, "minutes").format(DATETIME_FORMAT3);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [endDateTime, setEndDateTime] = useState<any>(null);
  const [beginDateTime, setBeginDateTime] = useState<any>(now);
  // const [duration, setDuration] = useState<number>(30);
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const meetingRoom = useApi<IMeetingRoom[]>({ isFetch: true });
  const createMeetingSchedule = useApi<IMeetingSchedule>({});

  const validationSchema = Yup.object().shape({
    Title: Yup.string().required(`${"Vui lòng nhập tiêu đề"}`),
    Description: Yup.string().required(`${"Vui lòng nhập mô tả"}`),
    RoomId: Yup.string()
      .notOneOf([""], "You must select an option!")
      .required(),
    MeetingParticipants: Yup.array()
      .required(`${"Vui lòng chọn người tham dự"}`)
      .min(1, `${"Vui lòng chọn người tham dự"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateMeetingScheduleReq>({
    defaultValues: {
      StartDate: now
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (request: ICreateMeetingScheduleReq) => {
    const participants = request.MeetingParticipants.map((meeting: any) =>
      Object.assign({
        ["meetingParticipantId"]: meeting.id,
        ["userNameParticipant"]: meeting.username,
        ["type"]: meeting.type
      })
    );

    const req = {
      ...request,
      StartDate: beginDateTime,
      EndDate: endDateTime,
      MeetingParticipants: participants
    };
    createMeetingSchedule.request(
      MeetingService.create(req).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Đăng ký lịch thành công"
          });
          refetch();
          setOpenCreate(false);
          reset();
        }
      })
    );
  };

  return (
    <Wrapper>
      {isCreate && (
        <div>
          <Button
            className="action-button"
            variant="contained"
            size="small"
            aria-label="aria-modal"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              meetingRoom.request(
                MeetingService.getRooms({
                  CurrentPage: 1,
                  PageSize: 10
                })
              );
              setOpenCreate(true);
            }}
            fullWidth
          >
            <Typography variant="subtitle2">ĐĂNG KÝ LỊCH HỌP</Typography>
          </Button>

          <Modal
            title="Đăng ký lịch họp mới"
            textSubmit="Đăng ký"
            textClose="Huỷ bỏ"
            open={openCreate}
            onSubmit={handleSubmit(onSubmit)}
            onClose={() => {
              setOpenCreate(false);
              reset();
            }}
            loadingState={createMeetingSchedule.loading}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              {/* <ControlForm title="" classname="brand-form">
              <EditAvatar
                file={file}
                onButtonClick={onButtonClick}
                handleFileChange={handleFileChange}
                inputRef={inputRef}
              />
            </ControlForm> */}
              <ControlForm title="Tiêu đề" isRequired classname="brand-form">
                <InputForm
                  placeholder="Tiêu đề"
                  required
                  name="Title"
                  errors={errors}
                  control={control}
                  size="small"
                  // disabled={createUser.loading}
                />
              </ControlForm>
              <ControlForm title="Mô tả" isRequired classname="brand-form">
                <InputForm
                  placeholder="Mô tả"
                  name="Description"
                  required
                  errors={errors}
                  control={control}
                  size="small"
                  // disabled={createUser.loading}
                />
              </ControlForm>

              <ControlForm title="Phòng họp" isRequired classname="brand-form">
                <Controller
                  name="RoomId"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={createMeetingSchedule.loading}
                      fullWidth
                      size="small"
                      placeholder="Vui lòng chọn"
                      sx={{ fontSize: "small", height: "40px" }}
                      error={!!errors.RoomId}
                      defaultValue=""
                    >
                      {meetingRoom.data &&
                        meetingRoom.data?.map(room => (
                          <MenuItem
                            key={room.id}
                            value={room.id}
                            sx={{ fontSize: "small" }}
                          >
                            {room.roomName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors.RoomId && (
                  <Typography variant="caption" marginLeft="16px" color="error">
                    Vui lòng chọn phòng họp
                  </Typography>
                )}
              </ControlForm>
              <ControlForm title="Thời gian bắt đầu" classname="brand-form">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    name="StartDate"
                    ampm={false}
                    format={DATETIME_FORMAT4}
                    defaultValue={moment(beginDateTime)}
                    sx={{ width: "100%" }}
                    onChange={e => {
                      const date = e ? e.format(DATETIME_FORMAT3) : now;
                      setBeginDateTime(date);
                    }}
                    dayOfWeekFormatter={day => `${day.format("ddd")}`}
                  />
                </LocalizationProvider>
              </ControlForm>

              <ControlForm title="Thời gian kết thúc" classname="brand-form">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    name="EndDate"
                    ampm={false}
                    format={DATETIME_FORMAT4}
                    // defaultValue={moment(beginDateTime)}
                    sx={{ width: "100%" }}
                    onChange={e => {
                      const date = e ? e.format(DATETIME_FORMAT3) : null;
                      setEndDateTime(date);
                    }}
                    dayOfWeekFormatter={day => `${day.format("ddd")}`}
                  />
                </LocalizationProvider>
              </ControlForm>

              <ControlForm title="Tham dự" isRequired classname="brand-form">
                <TagInput
                  name={"MeetingParticipants"}
                  control={control}
                  errors={errors}
                  isError={!!errors.MeetingParticipants}
                  placeholder={"Gõ tên để chọn người tham dự, ..."}
                  // optionDisabled={data}
                />
              </ControlForm>

              <ControlForm
                title="Thành phần tham dự khác"
                classname="brand-form"
                infor="Người tham dự khác ngoài công ty"
              >
                <InputForm
                  placeholder="Khách hàng, ..."
                  name="otherMeetingParticipants"
                  errors={errors}
                  control={control}
                  size="small"
                  // disabled={createUser.loading}
                />
              </ControlForm>
            </form>
          </Modal>
        </div>
      )}

      <Box
        sx={{
          backgroundColor: "white",
          padding: "4px 12px",
          borderRadius: "8px",
          margin: "18px 0"
        }}
      >
        {Object.values(EMeetingRoomStatus)
          .filter(v => !isNaN(Number(v)))
          .map((status, i) => (
            <Box key={i} display="flex" margin="12px 0" alignItems="center">
              <CircleRoundedIcon
                sx={{
                  fontSize: 16,
                  marginRight: "12px",
                  color: meetingRoomStatusColor(status as EMeetingRoomStatus)
                    .color
                }}
              />
              <Typography variant="caption">
                {meetingRoomStatusTitle(status as EMeetingRoomStatus)}
              </Typography>
            </Box>
          ))}
      </Box>

      <Typography variant="subtitle2">Đăng ký của tôi</Typography>
      {myBookings.map(item => (
        <ScheduleCard key={item.id} item={item} />
      ))}
    </Wrapper>
  );
};

export default BookingHeader;
