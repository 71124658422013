import { Box, Button, Divider, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface IProps {
  placeholder: string;

  value: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSearch: () => void;
  onClear: () => void;
}
const NameFilter = ({ placeholder, value, setValue, onSearch }: IProps) => {
  return (
    <TextField
      className="rounded-full"
      placeholder={placeholder}
      size="small"
      variant="outlined"
      type="search"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button sx={{ marginLeft: "8px" }} onClick={onSearch}>
              <SearchIcon />
            </Button>
          </InputAdornment>
        )
      }}
      value={value}
      onChange={value => setValue(value.target.value)}
    />
  );
};
export default NameFilter;
