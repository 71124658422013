import { Control } from "react-hook-form";
import { ReactNode, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { CircularProgress, Typography } from "@mui/material";
import { UniqueIdentifier } from "@dnd-kit/core";
import { TagType } from "../../../models/common/models.enum";
import { IDepartment } from "../../../models/department";
import useAxios from "../../../components/UseAxios/useAxios";
import { IGetUsersReq } from "../../../models/user";
import DepartmentService from "../../../services/api/department.service";
import { AutocompleteForm } from "../../../components/controls/AutocompleteForm";

interface Props {
  name: string;
  control?: Control<any, any>;
  errors: any;
  type?: TagType;
  store?: "workflow" | "stage";
  storeId?: UniqueIdentifier;
  isError: boolean;
  placeholder: string | undefined;
  multiple?: boolean;
  limit?: number;
  defaultValues?: IDepartment[];
}

export const SearchDepartmentInput = ({
  name,
  control,
  errors,
  isError,
  multiple,
  limit,
  placeholder,
  defaultValues
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IDepartment[]>([]);
  const [noOptionsText, setnoOptionsText] = useState<ReactNode | undefined>();

  const search = useAxios<IDepartment[]>({ loading: "OnRequest" });

  const getTags = async (value: string) => {
    if (value !== "") {
      search.reset();
      let request: IGetUsersReq = {
        CurrentPage: 1,
        PageSize: 10,
        KeySearch: value
      };
      search.request(DepartmentService.getPageAll(request));
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const [handleTagSearch] = useState(() => _debounce(getTags, 500));

  useEffect(() => {
    setnoOptionsText(
      <center>
        <CircularProgress size={24} />
      </center>
    );

    handleTagSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (search.isSuccess && search.data) {
      setOptions(search.data);
      if (search.data.length === 0)
        setnoOptionsText(
          <Typography key="noOptionsText">{"Không tìm thấy"}</Typography>
        );
    }
    if (search.error) {
      setnoOptionsText(
        <Typography key="noOptionsText">{"Đã có lỗi xảy ra"}</Typography>
      );
    }
  }, [search.data, search.isSuccess, search.error]);

  return (
    <AutocompleteForm<IDepartment>
      multiple={multiple ?? true}
      name={name}
      control={control}
      errors={errors}
      limit={limit}
      defaultValue={defaultValues}
      inputValue={inputValue}
      onInputChanged={(_, v) => setInputValue(v)}
      id={"tags-" + name}
      isError={isError}
      options={options}
      noOptionsText={<Typography>{noOptionsText}</Typography>}
      open={inputValue !== undefined && inputValue.length >= 1}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionTag={option => option.userName}
      getOptionLabel={option => option.name + option.userName}
      renderOption={option => <p> {option.name}</p>}
      size={"small"}
      placeholder={placeholder}
      loading={loading}
    />
  );
};
