import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import variableStyles from "../../../../theme/variable-styles";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import { Avatar } from "../../../../components";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { IDayOffDataItem, ITimeOff } from "../../../../models/timeoff";
import useAxios from "../../../../components/UseAxios/useAxios";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { ITimeOffRowData } from "../DataTable/TimeOffDataTable";
import {
  ETimeOffStatus,
  ETimeOffType
} from "../../../../models/common/models.enum";
import { sessionOptions } from "../../TimeOff";
import TimeOffService from "../../../../services/api/timeoff.service";
import { toggleMessage } from "../../../../components/Toast/Toast";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  propose: ITimeOffRowData | null;
  status: {
    label: string;
    color: string;
  };
  tab: any;
  updateType: string;
  canUpdateStatus: boolean;
  canDeleteTimeoff: boolean;
  dateOffGroup: (days: IDayOffDataItem[]) => Map<string, IDayOffDataItem[]>;
  handleClose: () => void;
  handleStatusChange: () => void;
}

interface ICommonProps {
  icon: React.ReactNode;
  name: string;
  content: React.ReactNode;
}

const CommonRow = ({ icon, name, content }: ICommonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 1
      }}
    >
      <Box display={"flex"} flexDirection={"row"} flexBasis={"35%"} gap={1}>
        {icon}
        <Typography fontSize={15} fontWeight={500} color={"#858585"}>
          {name}
        </Typography>
      </Box>
      <Box flexBasis={"65%"}>{content}</Box>
    </Box>
  );
};
const TimeOffDetailDialog = ({
  open,
  propose,
  status,
  tab,
  updateType,
  canUpdateStatus,
  canDeleteTimeoff,
  dateOffGroup,
  handleClose,
  handleStatusChange
}: IProps) => {
  const updateStatusTimeOffAxios = useAxios<string>({ loading: "OnRequest" });
  const deleteTimeOffAxios = useAxios<string>({ loading: "OnRequest" });

  const _canUpdateStatus = useMemo(() => {
    if (updateType === "ManagerUpdateStatus") {
      return propose?.status === ETimeOffStatus.Pending && canUpdateStatus;
    }
    if (updateType === "AdminUpdateStatus") {
      return (
        (propose?.status === ETimeOffStatus.Pending ||
          propose?.status === ETimeOffStatus.ManagerApprove) &&
        canUpdateStatus
      );
    }
  }, [propose, canUpdateStatus]);

  useEffect(() => {
    if (updateStatusTimeOffAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message:
          updateStatusTimeOffAxios.message ?? "Trạng thái đã được cập nhật"
      });
      handleStatusChange();
      handleClose();
    }
    if (updateStatusTimeOffAxios.error) {
      toggleMessage({
        type: "error",
        message: updateStatusTimeOffAxios.error?.message ?? ""
      });
    }
  }, [updateStatusTimeOffAxios.isSuccess, updateStatusTimeOffAxios.error]);

  useEffect(() => {
    if (deleteTimeOffAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: deleteTimeOffAxios.message ?? "Đề xuất đã được xoá"
      });
      handleStatusChange();
      handleClose();
    }
    if (deleteTimeOffAxios.error) {
      toggleMessage({
        type: "error",
        message: deleteTimeOffAxios.error?.message ?? ""
      });
    }
  }, [deleteTimeOffAxios.isSuccess, deleteTimeOffAxios.error]);

  const dateOffPropose = useMemo(() => {
    return dateOffGroup(propose?.proposeAt ?? []);
  }, [propose]);

  const handleApproveTimeOff = () => {
    if (_canUpdateStatus && updateType === "ManagerUpdateStatus") {
      updateStatusTimeOffAxios.request(
        TimeOffService.managerUpdateTimeOffStatus({
          Id: propose!.id,
          Status: ETimeOffStatus.ManagerApprove
        })
      );
    }
    if (_canUpdateStatus && updateType === "AdminUpdateStatus") {
      updateStatusTimeOffAxios.request(
        TimeOffService.CEOUpdateTimeOffStatus({
          Id: propose!.id,
          Status: ETimeOffStatus.CEOApprove
        })
      );
    }
  };

  const handleRejectTimeOff = () => {
    if (_canUpdateStatus && updateType === "ManagerUpdateStatus") {
      updateStatusTimeOffAxios.request(
        TimeOffService.managerUpdateTimeOffStatus({
          Id: propose!.id,
          Status: ETimeOffStatus.NotApprove
        })
      );
    }
    if (_canUpdateStatus && updateType === "AdminUpdateStatus") {
      updateStatusTimeOffAxios.request(
        TimeOffService.CEOUpdateTimeOffStatus({
          Id: propose!.id,
          Status: ETimeOffStatus.NotApprove
        })
      );
    }
  };

  const handleDeleteTimeOff = () => {
    deleteTimeOffAxios.request(TimeOffService.deleteTimeOff(propose!.id));
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-describedby="timeoff-dialog-detail"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#267CDE",
          padding: "8px 24px"
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={500}
          color={variableStyles.NaturalColor300}
        >
          {`> ${propose?.title}`}
        </Typography>
        <CloseRoundedIcon
          onClick={handleClose}
          sx={{ color: "white", height: 24, width: 24 }}
        />
      </Box>
      <LoadingOverlayWrapper
        active={updateStatusTimeOffAxios.isLoading}
        spinner
        styles={{
          overlay: {
            position: "absolute",
            height: "100%",
            width: "100%",
            top: "0px",
            left: "0px",
            display: "-webkit-box",

            textAlign: "center",
            fontSize: "1.2em",
            color: "#FFF",
            backgroundColor: "rgba(199, 199, 199, 0.7)",
            zIndex: 800,
            webkitTransition: "opacity 500ms ease-in",
            transition: " opacity 500ms ease-in",
            opacity: 1
          }
        }}
      >
        {propose && (
          <Box display={"flex"} flexDirection={"row"}>
            <Box flexBasis={"100%"} borderRight={"1px solid #D3DCDF"}>
              <div style={{ padding: " 20px 24px" }}>
                <Typography fontSize={28} fontWeight={500}>
                  {propose.description}
                </Typography>
                <Typography fontSize={14} color={"#858585"}>
                  Trạng thái:{" "}
                  <Box
                    component="span"
                    fontWeight={500}
                    fontSize={14}
                    color={status.color}
                  >
                    {status.label}
                  </Box>
                </Typography>
              </div>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#ECF8E7",
                  gap: 1,
                  margin: "0 24px",
                  padding: "12px 16px",
                  borderBottom: "1px dotted #D3DCDF"
                }}
              >
                <DoneRoundedIcon
                  sx={{
                    color: variableStyles.GreenPrimaryColor500,
                    width: 20,
                    height: 20
                  }}
                />
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={variableStyles.GreenPrimaryColor500}
                >
                  {"Tu An"}
                  <Box
                    component="span"
                    fontSize={13}
                    fontWeight={500}
                    letterSpacing={0.5}
                  >
                    {" đã đề xuất 1.00 ngày / 1.00 ngày phép còn lại"}
                  </Box>
                </Typography>
              </Box> */}

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 2,
                  padding: "20px 24px",
                  Button: {
                    "&:hover": {
                      backgroundColor: "#267CDE !important",
                      boxShadow: "none !important"
                    }
                  }
                }}
              >
                {_canUpdateStatus && (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleApproveTimeOff}
                      sx={{
                        backgroundColor: "#42B814",
                        height: 40,
                        borderRadius: 1.5
                      }}
                    >
                      Chấp thuận
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleRejectTimeOff}
                      sx={{
                        backgroundColor: "#C34343",
                        height: 40,
                        borderRadius: 1.5
                      }}
                    >
                      Từ chối
                    </Button>
                  </>
                )}
                {canDeleteTimeoff &&
                  propose.status === ETimeOffStatus.Pending && (
                    <Button
                      variant="contained"
                      onClick={handleDeleteTimeOff}
                      sx={{
                        backgroundColor: "#5b5c5c",
                        height: 40,
                        borderRadius: 1.5
                      }}
                    >
                      Xoá đơn
                    </Button>
                  )}
              </Box>

              <div style={{ padding: "0px 24px" }}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  padding={"12px 0px"}
                  borderBottom={"1px dotted #D3DCDF"}
                >
                  <Typography fontSize={16} fontWeight={600}>
                    THÔNG TIN CHÍNH
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    color={variableStyles.NaturalColor600}
                  >
                    ID:
                    <Box
                      component={"span"}
                      fontSize={16}
                      fontWeight={500}
                      color={"#267CDE"}
                    >
                      {" "}
                      {propose?.id}
                    </Box>
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  padding={"16px 0px"}
                >
                  <CommonRow
                    icon={
                      <AccountCircleOutlinedIcon
                        sx={{
                          color: "#858585"
                        }}
                      />
                    }
                    name={"Tạo bởi"}
                    content={
                      <Typography fontSize={15} fontWeight={600}>
                        {`${propose.creator?.fullName ?? ""} ${propose.creator?.userName ?? ""}`}
                        {propose.creator?.position && (
                          <Box component={"span"} fontWeight={400}>
                            {` (${propose.creator?.position})`}
                          </Box>
                        )}
                      </Typography>
                    }
                  />
                  <CommonRow
                    icon={
                      <LocalAtmOutlinedIcon
                        sx={{
                          color: "#858585"
                        }}
                      />
                    }
                    name={"Ghi nhận công"}
                    content={
                      <Typography fontSize={15} fontWeight={400}>
                        {[
                          ETimeOffType.Leave_of_absence_with_permission_for_less_than_3days,
                          ETimeOffType.Leave_of_absence_with_permission_for_more_than_3days
                        ].includes(propose.type)
                          ? "Có tính công"
                          : "Không tính công"}
                      </Typography>
                    }
                  />
                  {/* <CommonRow
                    icon={
                      <EventNoteOutlinedIcon
                        sx={{
                          color: "#858585"
                        }}
                      />
                    }
                    name={"Ngày tạo"}
                    content={
                      <Typography fontSize={15} fontWeight={400}>
                        {""}
                      </Typography>
                    }
                  /> */}
                  {/* <CommonRow
                    icon={
                      <AccessTimeOutlinedIcon
                        sx={{
                          color: "#858585"
                        }}
                      />
                    }
                    name={"Thời gian cập nhật"}
                    content={
                      <Typography fontSize={15} fontWeight={400}>
                        9 phút
                      </Typography>
                    }
                  /> */}
                </Box>
              </div>
              <div style={{ padding: "0px 24px 30px 24px" }}>
                <Box padding={"12px 0px"} borderBottom={"1px dotted #D3DCDF"}>
                  <Typography fontSize={16} fontWeight={600}>
                    CA XIN NGHỈ
                  </Typography>
                </Box>
                {Array.from(dateOffPropose).map((date, i) => (
                  <Box
                    key={date[0]}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={2}
                    margin={"12px 0px"}
                    alignItems={"center"}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={600}
                      color={"#a6a6a6"}
                    >
                      {i + 1 < 10 ? "0" + (i + 1) : i + 1}
                    </Typography>
                    <Box>
                      <Typography
                        fontSize={15}
                        fontWeight={500}
                        color={"#858585"}
                      >
                        {date[0]}
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"} gap={0.5}>
                        {date[1]
                          .sort((a, b) => a.session - b.session)
                          .map((item, i) => (
                            <Typography
                              variant="caption"
                              fontSize={14}
                              fontWeight={600}
                              key={item.id}
                            >
                              {i > 0 ? "・ " : ""}
                              {sessionOptions.find(
                                e => e.value === item.session
                              )?.name ?? ""}
                            </Typography>
                          ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
            </Box>
            {/* <Box flexBasis={"35%"} sx={{ backgroundColor: "#F3F3F3" }}>
              <DialogContent>
                <Box padding={"12px 0px"} borderBottom={"1px solid #D3DCDF"}>
                  <Typography fontSize={16} fontWeight={600} color={"#42B814"}>
                    NGƯỜI PHÊ DUYỆT
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", padding: "12px 0px" }}>
                  <Avatar
                    src={null}
                    alt="Avatar-Image"
                    width={30}
                    height={30}
                  />

                  <Box
                    sx={{
                      marginLeft: "12px",
                      height: "30px",
                      justifyContent: "flex-start",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      lineHeight={1.3}
                      fontSize={14}
                    >
                      {"Nguyễn Quốc An"}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize={13}
                      color={"#858585"}
                    >
                      <Box component={"span"} fontWeight={600}>
                        {"@userName"}{" "}
                      </Box>
                      - {"position"}
                    </Typography>
                  </Box>
                </Box>
              </DialogContent>
            </Box> */}
          </Box>
        )}
        {!propose && (
          <Box display={"flex"} flexDirection={"row"} height={"450px"}></Box>
        )}
      </LoadingOverlayWrapper>
    </Dialog>
  );
};
export default TimeOffDetailDialog;
