import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  // display: "flex",
  margin: "12px 12px",
  "& .action-button": {
    fontSize: "12px",
    padding: "0 12px",
    height: "40px",
    borderRadius: "8px"
  }
}));
