import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/vi";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { toggleMessage } from "../../../../components/Toast/Toast";
import useAxios from "../../../../components/UseAxios/useAxios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ControlForm, Modal } from "../../../../components";
import {
  ICreateTimeOffRequest,
  IDayOffItem,
  ITimeOff,
  ITimeOffDate,
  ITimeOffOperation,
  ITimeOffOption
} from "../../../../models/timeoff";
import InputForm from "../../../../components/controls/InputForm";
import BackspaceRoundedIcon from "@mui/icons-material/BackspaceRounded";
import { Wrapper } from "./styles";
import { sessionOptions, typeOptions } from "../../TimeOff";
import TimeOffService from "../../../../services/api/timeoff.service";
import TimeOffTypeService from "../../../../services/api/timeofftype.service";
import { ITimeOffType } from "../../../../models/timeofftype";

dayjs.extend(utc);
dayjs.extend(timezone);
const today = dayjs();

interface IProps {
  open: boolean;
  onCreateSuccess: () => void;
  handleClose: () => void;
}

interface DataForm {
  title: string;
  type: number;
  description: string;
  days: ITimeOffDate[];
}

interface ITimeOffItemProps {
  index: number;
  id: number;
  value: ITimeOffDate[];
  timeOffDate: ITimeOffDate;
  operations: ITimeOffType[];
  dayOffSession: ITimeOffOption;
  onChange: (...event: any[]) => void;
}
const TimeOffItem = ({
  index,
  id,
  value,
  operations,
  timeOffDate,
  dayOffSession,
  onChange
}: ITimeOffItemProps) => {
  const [timeOffTypeId, setTimeOffTypeId] = useState<string>("");

  useEffect(() => {
    setTimeOffTypeId(operations[0]?.id ?? "");
  }, [operations]);

  useEffect(() => {
    const idx = timeOffDate.DayOff?.findIndex(
      e => e.Session === dayOffSession.value
    );
    if (idx > -1) {
      const newValue = [...value];
      newValue[index].DayOff[idx].TimeOffTypeId = timeOffTypeId;
      onChange(newValue);
    }
  }, [
    timeOffTypeId,
    dayOffSession,
    timeOffDate.DayOff,
    index,
    onChange,
    value
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        mt: 1
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        flexBasis="50%"
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px solid #D3DCDF"}
        borderRadius={"12px"}
        sx={{
          backgroundColor: "white"
        }}
      >
        <Typography flexGrow={6} textAlign={"center"}>
          {dayOffSession.name}
        </Typography>
        <FormControlLabel
          label=""
          control={
            <Checkbox
              checked={
                timeOffDate.DayOff?.findIndex(
                  e => e.Session === dayOffSession.value
                ) > -1
              }
              onChange={e => {
                const checked = e.target.checked;
                const newValue = [...value];
                if (checked) {
                  newValue[index].DayOff.push({
                    TimeOffTypeId: timeOffTypeId ?? null,
                    DateTime: timeOffDate.DateTime,
                    Session: dayOffSession.value
                  });
                } else {
                  newValue[index].DayOff = newValue[index].DayOff.filter(
                    e => e.Session !== dayOffSession.value
                  );
                }
                onChange(newValue);
              }}
              sx={{
                flexGrow: 1,
                margin: "auto",
                paddingRight: 1
              }}
            />
          }
        />
      </Box>
      <Select
        size="small"
        displayEmpty
        value={timeOffTypeId}
        onChange={e => setTimeOffTypeId(e.target.value)}
        IconComponent={ExpandLessIcon}
        sx={{
          fontSize: 15,
          flexGrow: 1,
          backgroundColor: "white",
          borderRadius: "12px",
          fontWeight: 500,
          color: "#596970",
          marginLeft: "6px",
          flexBasis: "50%"
        }}
      >
        {operations.map((option, typeIndex) => (
          <MenuItem
            sx={{ fontSize: 13 }}
            value={option.id + ""}
            key={timeOffDate.DateTime! + "type" + typeIndex}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const CreateTimeOffModal = ({ open, handleClose, onCreateSuccess }: IProps) => {
  const [timeoffType, setTimeoffType] = useState<ITimeOffOperation>(
    typeOptions[0]
  );
  const [operations, setOperations] = useState<ITimeOffType[]>([]);
  const createTimeOffAxios = useAxios<ITimeOff>({ loading: "OnRequest" });
  const getTypeAxios = useAxios<ITimeOffType[]>({
    loading: "OnRequest"
  });

  const dateOffGroup = useCallback(
    (value: ITimeOffDate[]) =>
      value?.reduce((group, day) => {
        const { DateTime } = day;
        if (DateTime !== null) {
          const newDays = [...(group.get(DateTime!) ?? []), day.DayOff];
          group.set(
            DateTime!,
            newDays.flatMap(e => e)
          );
        }
        return group;
      }, new Map<string, IDayOffItem[]>()),
    []
  );

  useEffect(() => {
    if (open === true) {
      reset({
        title: "",
        type: timeoffType.value,
        description: "",
        days: [
          {
            DateTime: null,
            DayOff: []
          }
        ]
      });

      // getAllType();
    }
    return () => {
      setTimeoffType(typeOptions[0]);
      setOperations([]);
      createTimeOffAxios.reset();
      getTypeAxios.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = useCallback(
    (data: DataForm) => {
      const request: ICreateTimeOffRequest = {
        Title: data.title,
        Type: data.type,
        Description: data.description,
        DayOff: data.days.flatMap(e => e.DayOff)
      };

      createTimeOffAxios.request(TimeOffService.createTimeOff(request));
    },
    [createTimeOffAxios]
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Vui lòng nhập tiêu đề"),
    type: Yup.number().required("Vui lòng chọn loại đề xuất"),
    description: Yup.string().required("Vui lòng nhập lí do"),
    days: Yup.mixed<ITimeOffDate[]>()
      .test("days-test", "Thời gian không hợp lệ", (value, _) => {
        if (value![0].DateTime === null) return false;

        if (Array.from(dateOffGroup(value!)).some(e => e[1].length > 2))
          return false;

        return true;
      })
      .test("days-test", "Vui lòng chọn thời gian", (value, _) => {
        if (value![0].DayOff.length === 0) return false;

        return true;
      })
      .test("session-test", "Vui lòng chọn loại phép", (value, _) => {
        if (value && value![0].DateTime) {
          if (value.find(e => e.DayOff.findIndex(e => !e.TimeOffTypeId) > -1))
            return false;
        }

        return true;
      })
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (open && timeoffType.operation >= 0) {
      getTypeAxios.request(
        TimeOffTypeService.getTimeOffType(timeoffType.operation)
      );
    }
  }, [timeoffType, open]);

  useEffect(() => {
    if (getTypeAxios.isSuccess && getTypeAxios.data) {
      setOperations(getTypeAxios.data);
      const dayOff = getValues("days");
      if (dayOff?.length > 0)
        setValue(
          "days",
          dayOff.map(e => ({
            ...e,
            DayOff: e.DayOff.map(e => ({
              ...e,
              TimeOffTypeId: null
            }))
          }))
        );
    }
    if (getTypeAxios.error) {
      toggleMessage({
        type: "error",
        message: getTypeAxios.error?.message ?? ""
      });
    }
  }, [getTypeAxios.isSuccess, getTypeAxios.error]);

  useEffect(() => {
    if (createTimeOffAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message:
          createTimeOffAxios.message ?? "Tạo yêu cầu nghỉ phép thành công"
      });
      onCreateSuccess();
      handleClose();
    } else if (createTimeOffAxios.error) {
      toggleMessage({
        type: "error",
        message: createTimeOffAxios.error?.message ?? ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTimeOffAxios.error, createTimeOffAxios.isSuccess]);

  return (
    <Modal
      title={"Tạo yêu cầu nghỉ phép"}
      textSubmit="Lưu lại"
      textClose="Bỏ qua"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={createTimeOffAxios.isLoading}
      disabledSubmit={createTimeOffAxios.isLoading}
      onClose={
        !createTimeOffAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="580px"
    >
      <Wrapper
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }}
        id="create-time-off-modal"
        // onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ backgroundColor: "#ffeee2", padding: 1, borderRadius: 1 }}>
          <Typography variant="body1" color="black" fontWeight={400}>
            {"[Bắt buộc] Bạn không thể đề xuất những ngày trong quá khứ"}
          </Typography>
        </Box>
        <ControlForm title="Tiêu đề" classname="brand-form" isRequired>
          <Typography
            fontSize={14}
            sx={{
              color: "#999999",
              paddingBottom: 1,
              paddingLeft: 0.5
            }}
          >
            Theo mẫu:{" "}
            <Box component={"span"} fontSize={16}>
              ĐƠN XIN NGHỈ PHÉP - HỌ VÀ TÊN - MSNV
            </Box>
          </Typography>

          <InputForm
            placeholder="Tiêu đề"
            required
            name="title"
            errors={errors}
            control={control}
            inputProps={{
              style: {
                fontSize: 15,
                fontWeight: 500,
                color: "#596970",
                textTransform: "uppercase"
              }
            }}
            size="small"
          />
        </ControlForm>
        <ControlForm title="Loại đề xuất" classname="brand-form" isRequired>
          <Controller
            name="type"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  value={field.value}
                  onChange={newValue => {
                    field.onChange(newValue);
                    setTimeoffType(
                      typeOptions.find(e => e.value === newValue.target.value)!
                    );
                  }}
                  size="small"
                  name="type-off-timeoff"
                  displayEmpty
                  fullWidth
                  IconComponent={ExpandLessIcon}
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    fontSize: 15,
                    fontWeight: 500,
                    color: "#596970"
                  }}
                >
                  {typeOptions.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      value={option.value}
                      key={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type && (
                  <FormHelperText sx={{ color: "#ED3E47" }}>
                    {errors.type.message ?? ""}
                  </FormHelperText>
                )}
              </>
            )}
          ></Controller>
        </ControlForm>
        <ControlForm title="Mô tả đề xuất" classname="brand-form" isRequired>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Mô tả đề xuất"
                size="small"
                multiline
                fullWidth
                rows={4}
                inputProps={{
                  style: { fontSize: 15, fontWeight: 500, color: "#596970" }
                }}
                error={!!errors?.description}
                helperText={errors?.description?.message}
              />
            )}
          />
        </ControlForm>

        <ControlForm title="Ca đề xuất" classname="brand-form" isRequired>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
            <Controller
              name="days"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        padding: 1,
                        backgroundColor: "#e9e9e9",
                        borderRadius: "4px"
                      }}
                    >
                      {value.map((timeOffDate, index) => (
                        <div key={"timeOffDate" + index}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <DatePicker
                              disablePast
                              minDate={
                                value[index - 1]?.DateTime
                                  ? dayjs(
                                      value[index - 1]?.DateTime,
                                      "DD/MM/YYYY"
                                    )?.add(1, "day")
                                  : today
                              }
                              value={
                                timeOffDate.DateTime !== null
                                  ? dayjs(timeOffDate.DateTime, "DD/MM/YYYY")
                                  : null
                              }
                              dayOfWeekFormatter={date => date.format("dd")}
                              onChange={newDate => {
                                const newValue = [...value];
                                newValue[index].DateTime =
                                  dayjs(newDate).format("DD/MM/YYYY");
                                onChange(newValue);
                              }}
                              slotProps={{
                                textField: {
                                  placeholder: "Nhấn để chọn ngày",
                                  InputProps: {
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="end">
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            color: "#000",
                                            textAlign: "center",
                                            lineHeight: "18px",
                                            fontSize: 15
                                          }}
                                        >
                                          {timeOffDate.DateTime !== null
                                            ? dayjs(
                                                timeOffDate.DateTime,
                                                "DD/MM/YYYY"
                                              )
                                                .locale("vi")
                                                .format("dddd")
                                            : null}
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                    sx: {
                                      cursor: "pointer",
                                      backgroundColor: "white",
                                      borderRadius: "12px"
                                    }
                                  }
                                }
                              }}
                            />
                            {index > 0 && (
                              <BackspaceRoundedIcon
                                color="action"
                                fontSize="small"
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                  cursor: "pointer",
                                  color: "#b22020",
                                  alignSelf: "center"
                                }}
                                onClick={() =>
                                  onChange(value.filter((_, i) => i !== index))
                                }
                              />
                            )}
                            {index === 0 && <Box width={"30px"}></Box>}
                            {timeOffDate.DateTime &&
                              sessionOptions.map((dayOff, i) => (
                                <TimeOffItem
                                  key={timeOffDate.DateTime! + "/" + i}
                                  index={index}
                                  id={i}
                                  value={value}
                                  timeOffDate={timeOffDate}
                                  operations={operations}
                                  dayOffSession={dayOff}
                                  onChange={onChange}
                                ></TimeOffItem>
                              ))}
                          </div>
                          {error && (
                            <FormHelperText sx={{ color: "#ED3E47" }}>
                              {error.message}
                            </FormHelperText>
                          )}
                        </div>
                      ))}
                    </Box>
                    <Button
                      sx={{
                        color: "#A0A0A0",
                        alignSelf: "center",
                        marginTop: "12px"
                      }}
                      onClick={() =>
                        onChange([...value, { DateTime: null, DayOff: [] }])
                      }
                    >
                      +THÊM NGÀY NỐI TIẾP
                    </Button>
                  </Box>
                );
              }}
            />
          </LocalizationProvider>
        </ControlForm>
      </Wrapper>
    </Modal>
  );
};

export default CreateTimeOffModal;
