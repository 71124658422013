import {
  IGetMeetingScheduleReq,
  ICreateMeetingScheduleReq,
  IMeetingSchedule,
  ICreateMeetingRoomReq,
  IUpdateMeetingScheduleReq,
  IUpdateScheduleDateTimeReq,
  IGetPageByStatusReq
} from "./../../models/meeting/index";
import { IAdminUpdateStatus } from "./../../models/department/index";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IGetPageReq } from "../../models/common/models.request";

const API = ApiEndpoints.MEETING;
const baseURL = envConfig.API_ENDPOINT;

const getPage = (
  data: IGetMeetingScheduleReq
): Promise<IBasePagingRes<any>> => {
  return api.get(baseURL, API.GET_PAGE.url, {
    params: data
  });
};

const getPageByStatus = (
  data: IGetPageByStatusReq
): Promise<IBasePagingRes<IMeetingSchedule[]>> => {
  return api.get(baseURL, API.GET_PAGE_BY_STATUS.url, {
    params: data
  });
};

const getRooms = (data: IGetPageReq): Promise<IBasePagingRes<any>> => {
  return api.get(baseURL, API.GET_ROOMS.url, {
    params: data
  });
};

const create = (
  data: ICreateMeetingScheduleReq
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.post(baseURL, API.CREATE.url, data);
};

const createRoom = (
  data: ICreateMeetingRoomReq
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.post(baseURL, API.CREATE_ROOM.url, data);
};

const getSingle = (
  id: string
): Promise<IBaseResponseModel<IMeetingSchedule>> => {
  return api.get(baseURL, `${API.GET_SIGNLE.url}/${id}`);
};

const update = (
  data: IUpdateMeetingScheduleReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE.url, data);
};

const updateDateTime = (
  data: IUpdateScheduleDateTimeReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE_DATETIME.url, data);
};

const adminUpdateStatus = (
  data: IAdminUpdateStatus
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.ADMIN_UPDATE.url, data);
};

const userUpdateStatus = (
  data: IAdminUpdateStatus
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.USER_UPDATE.url, data);
};

const deleteMeeting = (id: string): Promise<IBaseResponseModel<any>> => {
  return api.delete(baseURL, `${API.DELETE.url}/${id}`);
};

const deleteRoom = (id: string): Promise<IBaseResponseModel<any>> => {
  return api.delete(baseURL, `${API.DELETE_ROOM.url}/${id}`);
};

const MeetingService = {
  getPage,
  create,
  getSingle,
  update,
  deleteMeeting,
  getRooms,
  adminUpdateStatus,
  createRoom,
  userUpdateStatus,
  updateDateTime,
  getPageByStatus,
  deleteRoom
};

export default MeetingService;
