import { Box, Button, Divider } from "@mui/material";
import React, { useMemo, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BasicMenu, { IMenuItem } from "../../components/Menu/BasicMenu";
import CreateTaskModal from "./CreateTaskModal/create_task_modal";
import { IWorkflow } from "../../models/workflow";
import CreateWorkflowDialog from "../WorkflowManagement/components/CreateWorkflowDialog/create_workflow_dialog";
import { IRoleBoard } from "../../models/Stage";
import { EStageType } from "../../models/common/models.enum";
import CopyWorkflowModal from "./CopyWorkflowModal/copy_workflow_modal";

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  hasStepStage: boolean;
  onCloseWorkflow: (id: string) => void;
  onCreateStage: () => void;
  onCreateTaskSuccess: () => void;
  onUpdateWorkflowSuccess: () => void;
  onCopyWorkflowSuccess?: () => void;
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }
// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`
//   };
// }

// function CustomTabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

const ActionProject = ({
  workflow,
  role,
  hasStepStage,
  onCloseWorkflow,
  onCreateStage,
  onCreateTaskSuccess,
  onUpdateWorkflowSuccess,
  onCopyWorkflowSuccess
}: Props) => {
  const [openCreateTask, setOpenCreateTask] = useState<boolean>(false);
  const [openUpdateWorkflow, setOpenUpdateWorkflow] = useState<boolean>(false);
  const [openCopyWorkflow, setOpenCopyWorkflow] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0
      }}
    >
      <Box
        className="content-header"
        sx={{ display: "flex", margin: "10px  16px 0 0" }}
      >
        <Box
          className="header-action"
          sx={{
            display: "flex",
            button: {
              marginLeft: "8px"
            },
            "& #basic-button": {
              "& .menu-button": { padding: 0 }
            }
          }}
        >
          <Button size="small" variant="outlined" color="info">
            {workflow.totalTask}/{workflow.totalTask} nhiệm vụ
          </Button>

          {(role.isProcessManagement ||
            role.isCreator ||
            role.isAssignTask) && (
            <Button
              size="small"
              variant="contained"
              color="info"
              onClick={_ =>
                hasStepStage ? setOpenCreateTask(true) : onCreateStage()
              }
            >
              Tạo nhiệm vụ
            </Button>
          )}
          {(role.isProcessManagement || role.isCreator) && (
            <BasicMenu
              onChange={() => {}}
              label={
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  sx={{ minWidth: "unset" }}
                >
                  <MoreHorizIcon />
                </Button>
              }
              padding={0}
              options={[
                {
                  value: "edit",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => onCreateStage()}
                    >
                      Thêm giai đoạn
                    </Box>
                  )
                },
                {
                  value: "update",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => setOpenUpdateWorkflow(true)}
                    >
                      Cập nhật luồng công việc
                    </Box>
                  )
                },
                {
                  value: "clone",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px"
                      }}
                      onClick={() => setOpenCopyWorkflow(true)}
                    >
                      Nhân bản luồng công việc
                    </Box>
                  )
                },
                {
                  value: "divider",
                  label: (
                    <Divider
                      sx={{
                        height: "1px",
                        width: "90%",
                        marginInline: 1
                      }}
                    />
                  )
                },

                {
                  value: "delete",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px",
                        color: "#ff4e4e"
                      }}
                      onClick={() => onCloseWorkflow(workflow.id)}
                    >
                      Đóng luồng công việc
                    </Box>
                  )
                }
              ]}
            />
          )}
          <CreateTaskModal
            open={openCreateTask}
            workflowId={workflow.id}
            onCreateSuccess={onCreateTaskSuccess}
            handleClose={() => setOpenCreateTask(false)}
          />
          <CreateWorkflowDialog
            open={openUpdateWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenUpdateWorkflow(false)}
            handleSuccess={onUpdateWorkflowSuccess}
          />
          <CopyWorkflowModal
            open={openCopyWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenCopyWorkflow(false)}
            handleSuccess={
              onCopyWorkflowSuccess ? onCopyWorkflowSuccess : () => {}
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActionProject;
