import { Chip } from "@mui/material";
import { Role } from "../../../models/user";

type Props = {
  roles?: Role | null;
};

const RolesTag = (props: Props) => {
  function addAlpha(color: string, opacity: number) {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  return (
    <Chip
      label={props.roles?.name ?? "Không xác định"}
      size="small"
      sx={{
        color: props.roles?.color ?? "GrayText",
        backgroundColor: addAlpha(props.roles?.color ?? "#FFFFFF", 0.1),
        fontSize: "12px",
        fontWeight: "500",
        padding: "0 4px"
      }}
    />
  );
};

export default RolesTag;
