import { useEffect, useMemo, useRef, useState } from "react";
import { Wrapper } from "./styles";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  Box,
  Grid
} from "@mui/material";

import {
  IChangePasswordByAdminReq,
  IUpdateByAdminRequest,
  IUser
} from "../../../../models/user";
import Modal from "../../../../components/Modal";
import ControlForm from "../../../../components/ControlForm";
import InputForm from "../../../../components/controls/InputForm";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  HRPermissionModel,
  UserAction
} from "../../../../models/common/models.enum";
import { TagInput } from "../../../WorkflowManagement/components/TagInput/TagInput";
import { useApi } from "../../../../hooks/useApi.hook";
import UserService from "../../../../services/api/user.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import CommonHandle from "../../../../common/handles/common.handles";
import { DATE_FORMAT2, DATE_REQ_FORMAT } from "../../../../config/constants";
import moment from "moment";
import EditAvatar from "../EditAvatar";
import { IAttachmentFile } from "../../../../models/Task";
import UploadFileService from "../../../../services/api/uploadFile.service";
import {
  IFeatureByRole,
  IPermissionByFeature,
  IRolePermission,
  IRoles
} from "../../../../models/user/models.roles";
import RoleService from "../../../../services/api/role.service";
import CheckIcon from "../../../../assets/images/common/select_square.svg";
import IconUnCheck from "../../../../assets/images/common/check_square_5.svg";
import UserInformationBox from "../UserInformationBox";
import { IUpdateTotalTimeOffReq } from "../../../../models/timeoff";
import { parseInt } from "lodash";
import TimeOffService from "../../../../services/api/timeoff.service";
import { SearchDepartmentInput } from "../SearchDepartmentInput";
import { IDepartment } from "../../../../models/department";

type Props = {
  item: IUser;
  reFetch?: Function;
  permisisons: string[];
};

type Action = {
  title: string;
  controls: ControlsForm[];
};

type ControlsForm = {
  title: string;
  child: React.ReactNode;
  isRequired?: boolean;
};

type FeaturePermissions = {
  feature: IFeatureByRole;
  permissions: PermissionsStatus[];
  check: boolean;
};

type PermissionsStatus = {
  item: IPermissionByFeature;
  checked: boolean;
};

const UserInfoContainer = (props: Props) => {
  const { item, reFetch, permisisons } = props;
  const [actionIndex, setActionIndex] = useState<number>(0);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [features, setFeatures] = useState<FeaturePermissions[]>([]);
  const [keyRole, setKeyRole] = useState(item.roles);
  const [defaultPermissions, setDefaultPermissions] = useState<
    IRolePermission[]
  >([]);
  const [file, setFile] = useState<File | null>(null);
  const [dataItem, setDataItem] = useState<IUser>(item);
  const inputRef = useRef<any>(null);
  const updateUser = useApi<IUser>({});
  const changePassUser = useApi<IUser>({});
  const deleteUser = useApi<IUser>({});
  const uploadFile = useApi<IAttachmentFile[]>({});
  const getFeatures = useApi<IFeatureByRole[]>({ isFetch: true });
  const updateRolePermissions = useApi<IRolePermission[]>({ isFetch: true });
  const getUserRolePermission = useApi<IRolePermission[]>({ isFetch: true });
  const getRoles = useApi<IRoles[]>({ isFetch: true });
  const getDefaultPermission = useApi<IRolePermission[]>({ isFetch: true });
  const getSingle = useApi<IUser>({});
  const updateTotal = useApi<IUser>({});

  const now = CommonHandle.formatDate(new Date(), DATE_FORMAT2);

  function handleClose() {
    setOpenCreate(false);
    reset();
    if (reFetch) reFetch();
  }

  useEffect(() => {
    reset(item);
  }, [item]);

  useEffect(() => {
    if (getDefaultPermission.data) {
      setDefaultPermissions(getDefaultPermission.data);
      getFeatures.request(RoleService.getFeatureByRoleId(keyRole));
    }
  }, [getDefaultPermission.data]);

  useEffect(() => {
    if (getFeatures.data) {
      const list: FeaturePermissions[] = getFeatures.data.map(feature => {
        var pers: PermissionsStatus[] = [];
        var countTrue = 0;
        var defaultValues =
          defaultPermissions.length > 0
            ? defaultPermissions
            : (getUserRolePermission.data ?? []);
        feature.permission.map(data => {
          const checkStatus =
            defaultValues.find(r => r.permissionId === data.id) !== undefined;
          if (checkStatus) {
            countTrue = countTrue + 1;
          }
          pers.push({
            item: data,
            checked: checkStatus
          });
        });
        const f: FeaturePermissions = {
          feature: feature,
          permissions: pers,
          check: countTrue === feature.permission.length
        };
        return f;
      });
      setFeatures(list);
    }
  }, [getFeatures.data, getUserRolePermission.data]);

  useEffect(() => {
    if (updateUser.success) {
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
      // if (updateUser.data) {
      //   authTokens.storeUser(updateUser.data);
      //   authTokens.storeRole(updateUser.data.roles);
      // }
      handleClose();
    }
    if (updateUser.error) {
      toggleMessage({
        type: "error",
        message: updateUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [updateUser.success, updateUser.error]);

  useEffect(() => {
    if (changePassUser.success) {
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
      handleClose();
    }
    if (changePassUser.error) {
      toggleMessage({
        type: "error",
        message: changePassUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [changePassUser.success, changePassUser.error]);

  useEffect(() => {
    if (deleteUser.success) {
      toggleMessage({
        type: "success",
        message: "Tài khoản này đã bị vô hiệu hoá"
      });
      handleClose();
    }
    if (deleteUser.error) {
      toggleMessage({
        type: "error",
        message: updateUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [deleteUser.success, deleteUser.error]);

  const validate = (): any => {
    switch (actionIndex) {
      case 0:
        return {
          firstName: Yup.string().required(`${"Vui lòng nhập họ và tên đệm"}`),
          lastName: Yup.string().required(`${"Vui lòng nhập tên"}`),
          userName: Yup.string().required(`${"Vui lòng nhập username"}`)
        };
      case 1:
        return {
          newPassword: Yup.string().required(`${"Vui lòng nhập mật khẩu mới"}`)
        };
      case 2:
        return {
          leaderId: Yup.array()
            .required(`${"Vui lòng chọn người quản lý"}`)
            .min(1, `${"Vui lòng chọn người quản lý"}`)
        };
      default:
        break;
    }
  };

  const validationSchema = Yup.object().shape(validate()) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<any>({
    defaultValues: useMemo(() => dataItem, [dataItem]),
    resolver: yupResolver(validationSchema)
  });

  function getUserSingle() {
    getSingle.request(
      UserService.getSingle(item.id).then(user => {
        if (user.success) {
          setDataItem(user.data);
          setValue("departments", user.data.departments);
        }
      })
    );
  }

  function handleActionClick(index: number) {
    setActionIndex(index);
    setOpenCreate(true);
    if (index === 3) {
      getFeatures.request(RoleService.getFeatureByRoleId(item.roles));
      getUserRolePermission.request(RoleService.getUserRolePemission(item.id));
      getRoles.request(RoleService.getRoles());
      setDefaultPermissions([]);
    } else if (index === 4 || index === 0) {
      getUserSingle();
    }
  }

  function handleDeleteUser() {
    toggleConfirmModal({
      title: `Bạn muốn vô hiệu hoá?`,
      content: `Bạn có chắc chắn muốn vô hiệu hoá tài khoản này?`,
      onSubmit: async () => {
        if (item) {
          deleteUser.request(UserService.deleteUser(item.id));
        }
      },
      submitText: "Xác nhận",
      type: "warning-red"
    });
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onSubmit = (request: any) => {
    const reqInfo: IUpdateByAdminRequest = {
      id: request.id,
      firstName: request.firstName,
      lastName: request.lastName,
      fullName: request.fullName,
      avatar: item?.avatar,
      email: request.email,
      phoneNumber: request.phoneNumber,
      userName: request.userName,
      roles: request.roles,
      position: request.position,
      address: request.address,
      managerId: request.leaderId,
      birthDay: moment(
        CommonHandle.formatDate(request?.birthDay, DATE_REQ_FORMAT)
      ).toDate(),
      manager: request.manager,
      status: 0,
      code: request.code,
      isTimeOffStart: request.isTimeOffStart,
      departmentIds: request.departments.map(
        (deparment: IDepartment) => deparment.id
      )
    };

    switch (Object.values(UserAction)[actionIndex]) {
      case UserAction.ChangeAdministration:
        var reqIds: IRolePermission[] = [];
        features.map(feature =>
          feature.permissions.map(permission =>
            permission.checked
              ? reqIds.push({
                  permissionId: permission.item.id
                })
              : null
          )
        );
        reqIds = reqIds.map(
          id =>
            getUserRolePermission.data?.find(
              user => user.permissionId === id.permissionId
            ) ?? id
        );
        updateRolePermissions.request(
          RoleService.updateRolePermission({
            UserId: item.id,
            RolePermissions: reqIds,
            KeyRole: keyRole
          }).then(res => {
            if (res.success) {
              toggleMessage({
                type: "success",
                message: "Cập nhật thành công"
              });
              handleClose();
            } else {
              toggleMessage({
                type: "error",
                message: updateRolePermissions?.error ?? "Đã xảy ra lỗi"
              });
            }
          })
        );
        break;

      case UserAction.ChangePassword:
        const req: IChangePasswordByAdminReq = {
          userId: request.id,
          newPassword: request.newPassword
        };
        changePassUser.request(UserService.updatePasswordByAdmin(req));
        break;

      case UserAction.ChangeManage:
        const reqUpdate: IUpdateByAdminRequest = {
          ...reqInfo,
          managerId: request.leaderId[0].id
        };
        updateUser.request(UserService.updateUserByAdmin(reqUpdate));
        break;

      case UserAction.UpdateTotalTimeOffForUser:
        const totalReq: IUpdateTotalTimeOffReq = {
          userId: item.id,
          totalTimeOff: parseFloat(request.totalTimeOff)
        };
        updateTotal.request(
          TimeOffService.updateTotalTimeOffForUser(totalReq).then(res => {
            if (res.success) {
              toggleMessage({
                type: "success",
                message: "Cập nhật thành công"
              });
              handleClose();
            } else {
              toggleMessage({
                type: "error",
                message: updateTotal?.error ?? "Đã xảy ra lỗi"
              });
            }
          })
        );
        break;

      default:
        if (file) {
          const formData = new FormData();
          formData.append("FolderName", "User");
          formData.append("Files", file);
          uploadFile.request(
            UploadFileService.uploadFile(formData).then(response => {
              if (response.success) {
                let newReq = { ...reqInfo };
                newReq.avatar = response.data[0].path;
                updateUser.request(
                  UserService.updateUserByAdmin(newReq).then(updateRes => {
                    if (!updateRes.success) {
                      uploadFile.request(
                        UploadFileService.deleteFile(updateRes.data[0])
                      );
                    } else {
                      handleClose();
                    }
                  })
                );
              }
            })
          );
        } else {
          updateUser.request(UserService.updateUserByAdmin(reqInfo));
        }
        break;
    }
  };

  const handlSelectAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    featureId: string
  ) => {
    var feature = features.find(p => p.feature.id === featureId);
    if (feature) {
      var listUppdate = feature.permissions.map(per =>
        per.checked === e.target.checked
          ? per
          : { ...per, checked: e.target.checked }
      );
      feature = {
        ...feature,
        permissions: listUppdate,
        check: e.target.checked
      };
      setFeatures(
        features.map(old => (old.feature.id === featureId ? feature! : old))
      );
    }
  };

  const handleSelectPermission = (
    e: React.ChangeEvent<HTMLInputElement>,
    perId: string,
    featureId: string
  ) => {
    var feature = features.find(f => f.feature.id === featureId);
    if (feature) {
      var listUppdate = feature.permissions.map(per =>
        per.item.id === perId ? { ...per, checked: e.target.checked } : per
      );
      feature = {
        ...feature,
        permissions: listUppdate,
        check:
          listUppdate.filter(c => c.checked).length ===
          feature.permissions.length
      };
      setFeatures(
        features.map(old => (old.feature.id === featureId ? feature! : old))
      );
    }
  };

  const listControls: ControlsForm[][] = [
    [
      {
        title: "",
        child: (
          <EditAvatar
            file={file}
            avatar={item.avatar}
            onButtonClick={onButtonClick}
            handleFileChange={handleFileChange}
            inputRef={inputRef}
          />
        )
      },
      {
        title: "Mã nhân viên",
        isRequired: false,
        child: (
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} marginRight="12px">
              <InputForm
                placeholder={"M000"}
                // required
                name={"code"}
                errors={errors}
                control={control}
                size="small"
              />
            </Box>
            <Controller
              name="isTimeOffStart"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  icon={
                    <img src={IconUnCheck} width={"22px"} height={"22px"} />
                  }
                  checkedIcon={
                    <img src={CheckIcon} width={"22px"} height={"22px"} />
                  }
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            <Typography variant="caption">Tính phép</Typography>
          </Box>
        )
      },
      {
        title: "Họ và tên đệm",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập họ và tên"}
            required
            name={"lastName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Tên",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Tên"}
            required
            name={"firstName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Username",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập userName"}
            required
            name={"userName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Chức danh",
        child: (
          <InputForm
            placeholder={"Nhập chức danh"}
            name={"position"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Số điện thoại",
        child: (
          <InputForm
            placeholder={"Nhập só điện thoại"}
            name={"phoneNumber"}
            errors={errors}
            control={control}
            size="small"
            type="tel"
          />
        )
      },
      {
        title: "Ngày sinh",
        child: (
          <InputForm
            name={"birthDay"}
            errors={errors}
            control={control}
            size="small"
            type="date"
            isFormatDate
            inputProps={{ max: now }}
          />
        )
      },
      {
        title: "Địa chỉ",
        child: (
          <InputForm
            placeholder={"Nhập địa chỉ"}
            name={"address"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Phòng ban",
        child: (
          <SearchDepartmentInput
            name={"departments"}
            control={control}
            errors={errors}
            isError={!!errors.departmentIds}
            placeholder={"Gõ để tìm"}
          />
        )
      }
    ],
    [
      {
        title: "Mật khẩu mới",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập mật khẩu mới"}
            required
            name={"newPassword"}
            errors={errors}
            control={control}
            size="small"
            type="password"
          />
        )
      }
    ],
    [
      {
        title: "Chọn người quản lý trực tiếp",
        isRequired: true,
        child: (
          <TagInput
            name={"leaderId"}
            control={control}
            errors={errors}
            isError={!!errors.leaderId}
            placeholder={"Gõ để tìm"}
            limit={1}
          />
        )
      }
    ],
    [
      {
        title: "Phân quyền tài khoản",
        isRequired: true,
        child: (
          <Controller
            name="roles"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                size="small"
                placeholder="Vui lòng chọn"
                sx={{ fontSize: "small" }}
                onChange={e => {
                  field.onChange(e);
                  setKeyRole(e.target.value);
                  getDefaultPermission.request(
                    RoleService.getDefaultPermissionByKeyRole(e.target.value)
                  );
                }}
              >
                {getRoles.data &&
                  getRoles.data.map((role, i) => (
                    <MenuItem
                      key={i}
                      value={role.key}
                      sx={{ fontSize: "small" }}
                    >
                      {role.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        )
      },
      {
        title: "Cấp quyền sử dụng",
        isRequired: true,
        child: (
          <Box>
            {features &&
              features.map(feature => (
                <Box
                  key={feature.feature.id}
                  sx={{
                    border: "1px solid #BEDDFC",
                    marginBottom: "16px",
                    borderRadius: "8px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#f1f5f6",
                      padding: "12px 16px",
                      borderRadius: "8px 8px 0 0"
                    }}
                  >
                    <Typography variant="subtitle2" flexGrow={1}>
                      {feature.feature.name}
                    </Typography>
                    <Checkbox
                      sx={{ padding: 0, marginRight: "12px" }}
                      checked={feature.check}
                      onChange={e => handlSelectAll(e, feature.feature.id)}
                      icon={
                        <img src={IconUnCheck} width={"22px"} height={"22px"} />
                      }
                      checkedIcon={
                        <img src={CheckIcon} width={"22px"} height={"22px"} />
                      }
                    />
                    <Typography variant="caption">Chọn tất cả</Typography>
                  </Box>

                  <Box margin="12px">
                    <Grid container spacing={2} columns={12}>
                      {feature.permissions.map(permission => (
                        <Grid key={permission.item.id} item xs={6}>
                          <Checkbox
                            checked={permission.checked}
                            sx={{ padding: 0, marginRight: "12px" }}
                            onChange={e =>
                              handleSelectPermission(
                                e,
                                permission.item.id,
                                feature.feature.id
                              )
                            }
                            icon={
                              <img
                                src={IconUnCheck}
                                width={"20px"}
                                height={"20px"}
                              />
                            }
                            checkedIcon={
                              <img
                                src={CheckIcon}
                                width={"20px"}
                                height={"20px"}
                              />
                            }
                          />
                          {permission.item.name}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              ))}
          </Box>
        )
      }
    ],
    [
      {
        title: "Số phép năm",
        child: (
          <InputForm
            placeholder={"Nhập ngày phép"}
            name={"totalTimeOff"}
            errors={errors}
            control={control}
            size="small"
            // type="number"
            onKeyDown={e => {
              if (
                ![
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "0",
                  ".",
                  "Backspace"
                ].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
          />
        )
      }
    ]
  ];

  const actions = Object.values(UserAction).map((action, i) => ({
    title: action,
    controls: listControls[i]
  }));

  return (
    <Wrapper>
      <UserInformationBox item={item} />
      {permisisons.includes(HRPermissionModel.EditUser) &&
        actions.map((action, i) => (
          <Button key={i} onClick={_ => handleActionClick(i)} color="info">
            {action.title}
          </Button>
        ))}

      {permisisons.includes(HRPermissionModel.DeleteUser) && (
        <Button onClick={handleDeleteUser} color="error">
          Vô hiệu hoá tài khoản
        </Button>
      )}

      <Modal
        title={Object.values(UserAction)[actionIndex]}
        textSubmit="Cập nhật"
        textClose="Huỷ bỏ"
        open={openCreate}
        width={actionIndex === 3 ? "50vw" : undefined}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => {
          setOpenCreate(false);
          reset();
          setKeyRole(item.roles);
        }}
        loadingState={updateUser.loading}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          {actions[actionIndex].controls.map((ctrl, i) => (
            <ControlForm
              key={i}
              title={ctrl.title}
              isRequired={ctrl.isRequired}
              classname="brand-form"
            >
              {ctrl.child}
            </ControlForm>
          ))}
        </form>
      </Modal>
    </Wrapper>
  );
};

export default UserInfoContainer;
