import { Box, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import CircleIcon from "@mui/icons-material/Circle";
import {
  FeatureModel,
  MeetingRoomSchedulePermissionModel,
  meetingRoomStatusColor
} from "../../models/common/models.enum";
import CommonHandle from "../../common/handles/common.handles";
import {
  DATETIME_FORMAT3,
  DATE_FORMAT2,
  DATE_REQ_FORMAT,
  TIME_FORMAT
} from "../../config/constants";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import EventDetailPopover from "./components/EventDetailPopover";
import BookingHeader from "./components/BookingHeader";
import { Wrapper } from "./styles";
import { useApi } from "../../hooks/useApi.hook";
import {
  ICreateMeetingRoomReq,
  IMeetingSchedule,
  IUpdateScheduleDateTimeReq
} from "../../models/meeting";
import MeetingService from "../../services/api/meeting.service";
import { EventSourceInput } from "@fullcalendar/core";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toggleMessage } from "../../components/Toast/Toast";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import moment from "moment";
import { authTokens } from "../../services/services";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import { Avatar } from "../../components";

type Props = {};

const Booking = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const [userId, setUserId] = useState<string | undefined>();
  const [permisisons, setPermisisons] = useState<string[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<{
    anchorEl: any;
    item: IMeetingSchedule;
  } | null>(null);
  const [events, setEvents] = useState<EventSourceInput | undefined>();
  const getPage = useApi<IMeetingSchedule[]>({ isFetch: true, isPaging: true });
  const getSingle = useApi<IMeetingSchedule>({});
  const updateSchedule = useApi<IMeetingSchedule>({});
  const getFeaturePersmission = useApi<IFeaturePermission[]>({ isFetch: true });

  const validationSchema = Yup.object().shape({
    RoomName: Yup.string().required(`${"Vui lòng nhập tên phòng"}`),
    Location: Yup.string().required(`${"Vui lòng nhập vị trí"}`),
    Description: Yup.string().required(`${"Vui lòng nhập mô tả"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateMeetingRoomReq>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    const containerEl = document.getElementById("#events");
    if (containerEl) {
      new Draggable(containerEl, {
        itemSelector: ".event",
        eventData: eventEl => {
          return {
            title: eventEl.innerText
          };
        }
      });
    }
  }, []);

  function fetchData() {
    getPage.request(
      MeetingService.getPage({
        Type: 0,
        Year: currentDate.year(),
        Month: currentDate.month() + 1
      })
    );
  }

  useEffect(() => {
    if (userId) {
      getFeaturePersmission.request(
        RoleService.getFeaturePemissionByUserId(userId).then(res => {
          if (res.success) {
            const permission = res.data.find(
              per => per.feature === FeatureModel.MeetingRoomSchedule
            );
            if (permission) {
              setPermisisons(permission.permissions);
            }
          }
        })
      );
    }
  }, [userId]);

  useEffect(() => {
    if (currentDate) fetchData();
  }, [currentDate]);

  const handleEventClick = (e: any) => {
    getSingle.request(
      MeetingService.getSingle(e.event.id).then(res => {
        if (res.success) {
          setSelectedItem({ anchorEl: e.el, item: res.data });
        }
      })
    );
  };

  useEffect(() => {
    if (selectedItem) {
      setAnchorEl(selectedItem.anchorEl);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (getPage.data) {
      const res = getPage.data.map(item => {
        return {
          title: item.title,
          start: item.startDate,
          end: item.endDate,
          backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
          borderColor: meetingRoomStatusColor(item.status).color,
          id: item.id
        };
      });
      setEvents(res);
    }
  }, [getPage.data]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserId(user?.id);
    }
    getUserInfo();
  }, []);

  function handleDrop(e: any) {
    var item = getPage?.data?.find(meeting => meeting.id === e.event.id);
    if (item) {
      var request: IUpdateScheduleDateTimeReq = {
        Id: item.id,
        StartDate: CommonHandle.formatDate(
          `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${CommonHandle.formatDate(item.startDate, TIME_FORMAT)}`,
          DATE_REQ_FORMAT
        ),
        EndDate:
          item.endDate &&
          CommonHandle.formatDate(
            `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${CommonHandle.formatDate(item.endDate, TIME_FORMAT)}`,
            DATE_REQ_FORMAT
          )
      };
      toggleConfirmModal({
        title: `Bạn muốn thay đổi thời gian thành`,
        content: (
          <Box>
            <Typography variant="subtitle2" marginBottom="8px">
              Bắt đầu
            </Typography>
            <Box display="flex">
              <TextField
                type="date"
                sx={{ fontSize: "10px" }}
                size="small"
                value={CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}
                disabled
              />
              <TextField
                type="time"
                name="startDate"
                sx={{ fontSize: "10px" }}
                size="small"
                defaultValue={CommonHandle.formatDateTime(
                  e.event.start,
                  TIME_FORMAT
                )}
                onChange={v => {
                  const date = CommonHandle.formatDate(
                    `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${v.target.value}`,
                    DATE_REQ_FORMAT
                  );
                  if (date !== "Invalid date") {
                    request = {
                      ...request,
                      StartDate: date
                    };
                  }
                }}
              />
            </Box>
            <Typography variant="subtitle2" margin="8px 0">
              Kết thúc
            </Typography>
            <Box display="flex">
              <TextField
                type="date"
                sx={{ fontSize: "10px" }}
                size="small"
                value={CommonHandle.formatDate(e.event.end, DATE_FORMAT2)}
                disabled
              />
              <TextField
                type="time"
                sx={{ fontSize: "10px" }}
                size="small"
                defaultValue={CommonHandle.formatDateTime(
                  e.event.end,
                  TIME_FORMAT
                )}
                onChange={v => {
                  const date = CommonHandle.formatDate(
                    `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${v.target.value}`,
                    DATE_REQ_FORMAT
                  );
                  if (date !== "Invalid date") {
                    request = {
                      ...request,
                      EndDate: date
                    };
                  }
                }}
              />
            </Box>
          </Box>
        ),
        onSubmit: async () => {
          updateSchedule.request(
            MeetingService.updateDateTime(request).then(response => {
              if (response.success) {
                toggleMessage({
                  type: "success",
                  message: "Cập nhật thành công!"
                });
                fetchData();
              } else {
                e.revert();
                toggleMessage({
                  type: "error",
                  message: updateSchedule?.error ?? "Đã xảy ra lỗi"
                });
              }
            })
          );
        },
        submitText: "Xác nhận",
        type: "warning",
        onCancel: async () => {
          e.revert();
        }
      });
    }
  }

  return (
    <Wrapper>
      <Box width="20%">
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          sx={{ marginLeft: "12px" }}
        >
          <EventDetailPopover
            item={selectedItem?.item}
            reFetch={() => {
              fetchData();
              setAnchorEl(null);
            }}
            isAdminUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.AdminUpdateStatus
              ) ?? false
            }
            isUserUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.AdminUpdateStatus
              ) === false &&
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.UserUpdateStatus
              )
            }
            isUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.Update
              ) ?? false
            }
            isRegister={selectedItem?.item.meetingRegistrantId === userId}
          />
        </Popover>

        <BookingHeader
          isCreate={
            permisisons?.includes(MeetingRoomSchedulePermissionModel.Create) ??
            false
          }
          refetch={fetchData}
          myBookings={
            getPage?.data?.filter(
              booking => booking.meetingRegistrantId === userId
            ) ?? []
          }
        />
      </Box>

      <Box flexGrow={1} sx={{ paddingTop: "16px" }}>
        <FullCalendar
          timeZone="UTC"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin
          ]}
          initialView="dayGridMonth"
          dayMaxEventRows={3}
          editable
          droppable
          eventContent={arg => {
            const checkEvent = getPage.data?.find(
              evt => evt.id === arg.event.id
            );
            return (
              <Tooltip title={checkEvent?.title}>
                {checkEvent?.meetingRegistrantId === userId ? (
                  <Box
                    sx={{
                      backgroundColor: arg.event.backgroundColor,
                      width: "100%",
                      borderRadius: 2,
                      padding: "0 8px",
                      border: `2px solid ${arg.event.borderColor}`
                    }}
                  >
                    <CircleIcon
                      sx={{
                        fontSize: 8,
                        color: arg.event.borderColor
                      }}
                    />
                    <Typography
                      variant="caption"
                      fontWeight="600"
                      color="black"
                    >
                      &nbsp;&nbsp;
                      {CommonHandle.formatDateTime(
                        arg.event.start,
                        TIME_FORMAT
                      )}
                    </Typography>

                    <Box>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {arg.event.title}
                      </p>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: 2,
                      padding: "0 8px",
                      border: `2px solid ${arg.event.borderColor}`
                    }}
                  >
                    <CircleIcon
                      sx={{
                        fontSize: 8,
                        color: arg.event.borderColor
                      }}
                    />
                    <Typography
                      variant="caption"
                      fontWeight="600"
                      color="black"
                    >
                      &nbsp;&nbsp;
                      {CommonHandle.formatDateTime(
                        arg.event.start,
                        TIME_FORMAT
                      )}
                    </Typography>
                    {!checkEvent?.meetingRegistrant ? (
                      <p>Không có dữ liệu</p>
                    ) : (
                      <Box display="flex">
                        <Avatar
                          src={checkEvent?.meetingRegistrant?.avatar}
                          width="12px"
                          height="12px"
                        />
                        <Typography
                          variant="caption"
                          fontSize="10px"
                          marginLeft="4px"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {checkEvent?.meetingRegistrant?.userName}
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {arg.event.title}
                      </p>
                    </Box>
                  </Box>
                )}
              </Tooltip>
            );
          }}
          height="85vh"
          locale={"vi"}
          noEventsText={"Không có dữ liệu"}
          dayHeaderFormat={{ weekday: "long" }}
          nowIndicator
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }}
          headerToolbar={{
            end: "dayGridMonth,listMonth",
            center: "title",
            start: "prev today next"
          }}
          buttonIcons={{
            dayGridPlugin: "chevron-left"
          }}
          buttonText={{
            today: "Hôm nay",
            month: "Tháng",
            week: "Tuần",
            day: "Ngày",
            list: "Danh sách",
            listDay: "Ngày"
          }}
          datesSet={dateInfo => {
            const dates = moment(dateInfo.start).add(14, "days");
            setCurrentDate(dates);
          }}
          businessHours
          events={events}
          eventClick={handleEventClick}
          eventDrop={handleDrop}
          // eventAllow={(dropInfo, draggedEvent) => {
          //   return moment(
          //     moment(dropInfo.start).utc().format(DATETIME_FORMAT3)
          //   ).isAfter(
          //     moment(draggedEvent?.start).utc().format(DATETIME_FORMAT3)
          //   );
          // }}
        />
      </Box>
    </Wrapper>
  );
};

export default Booking;
