import { ControlForm, Modal } from "../../../../components";
import * as Yup from "yup";
import useAxios from "../../../../components/UseAxios/useAxios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { ITag, IUser } from "../../../../models/user";
import { TagInput } from "../../../WorkflowManagement/components/TagInput/TagInput";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";
import TimeOffTypeService from "../../../../services/api/timeofftype.service";
import { IUpdateMailCCRequest } from "../../../../models/timeofftype";

interface IProps {
  open: boolean;
  cc: IUser[];
  onUpdateSuccess: () => void;
  handleClose: () => void;
}

interface DataForm {
  cc: IUser[];
}

const UpdateMailCCModal = ({
  open,
  cc,
  onUpdateSuccess,
  handleClose
}: IProps) => {
  const max = 10;
  const updateMailCCAxios = useAxios<IUser[]>({
    loading: "OnRequest"
  });

  useEffect(() => {
    reset({
      cc: cc.length === 0 ? [{}] : cc
    });
  }, [open]);

  useEffect(() => {
    if (updateMailCCAxios.error)
      toggleMessage({
        type: "error",
        message: updateMailCCAxios.error?.message ?? ""
      });
    if (updateMailCCAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateMailCCAxios.message ?? "Cập nhật thành công"
      });
      onUpdateSuccess();
      handleClose();
    }
  }, [updateMailCCAxios.error, updateMailCCAxios.isSuccess]);

  const onSubmit = async (event: DataForm) => {
    const req: IUpdateMailCCRequest = {
      data: event.cc
        .filter(user => user.email && user.email.trim() !== "")
        .map(e => ({
          id: e.id ?? null,
          email: e.email
        }))
    };

    updateMailCCAxios.request(TimeOffTypeService.updateMailCCDefault(req));
  };

  const validationSchema = Yup.object().shape({
    cc: Yup.mixed<IUser[]>().test(
      "cc-test",
      "Vui lòng thêm ít nhất 1 người được cc mặc định",
      (value, _) => {
        if (value!.every(e => !e.email || e.email === "")) return false;
        return true;
      }
    )
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });
  return (
    <Modal
      title={"Cập nhật cc nghỉ phép mặc định"}
      textSubmit={"Xác nhận"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={updateMailCCAxios.isLoading}
      disabledSubmit={updateMailCCAxios.isLoading}
      onClose={
        !updateMailCCAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm
          title="Người được cc mặc định"
          infor="Tối đa 10"
          classname="brand-form"
        >
          <Controller
            name="cc"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      padding: 1,
                      borderRadius: "4px"
                    }}
                  >
                    {value.map((user, index) => (
                      <div key={"timeOffDate" + index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1
                          }}
                        >
                          <TextField
                            onChange={e => {
                              const newValue = [...value];
                              newValue[index].email = e.target.value;
                              onChange(newValue);
                            }}
                            placeholder="Nhập địa chỉ mail"
                            size="small"
                            value={user.email}
                            inputProps={{
                              style: {
                                fontSize: 15,
                                fontWeight: 500,
                                color: "#596970"
                              }
                            }}
                          ></TextField>
                        </div>
                        {error && (
                          <FormHelperText sx={{ color: "#ED3E47" }}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </div>
                    ))}
                  </Box>
                  {value.length <= max && (
                    <Button
                      sx={{
                        color: "#A0A0A0",
                        alignSelf: "center",
                        marginTop: "12px"
                      }}
                      onClick={() => onChange([...value, {}])}
                    >
                      +Thêm Mail
                    </Button>
                  )}
                </Box>
              );
            }}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};
export default UpdateMailCCModal;
