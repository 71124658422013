import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import FileIcon from "../../../assets/images/file/Paperclip.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextEditorQuill from "../../../components/TextEditorQuill";
import { ControlForm, ImageCustom } from "../../../components";
import { DATETIME_FORMAT4, DATE_REQ_FORMAT } from "../../../config/constants";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";

import InputForm from "../../../components/controls/InputForm";
import { IDocument, IDocumentType } from "../../../models/docs";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../../../hooks/useApi.hook";
import DocsService from "../../../services/api/docs.service";
import {
  documentStatusTitle,
  EDocumentStatus,
  EFileType
} from "../../../models/common/models.enum";
import CheckIcon from "../../../assets/images/common/select_square.svg";
import IconUnCheck from "../../../assets/images/common/check_square_5.svg";
import FileImage from "../../ManageProject/Task/components/CommentTask/components/FileImage";
import UploadFileService from "../../../services/api/uploadFile.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import { IAttachmentFile } from "../../../models/Task";

type Props = {
  handleClose: Function;
  handleOnSubmit: Function;
  isLoading: boolean;
  editItem?: IDocument;
  documentTypes: IDocumentType[];
};

const CreateDocumentPopup = ({
  handleClose,
  handleOnSubmit,
  isLoading,
  editItem,
  documentTypes
}: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [deleteFiles, setDeletefiles] = useState<IAttachmentFile[]>([]);
  const [oldFiles, setOldfiles] = useState<IAttachmentFile[]>(
    editItem?.attachmentFiles ?? []
  );
  const [checkAll, setCheckAll] = useState(editItem?.isViewAll ?? false);
  const inputRef = useRef<any>(null);

  const uploadFile = useApi<IAttachmentFile[]>({});
  // const getDocumentType = useApi<IDocumentType[]>({ isFetch: true });

  // useEffect(() => {
  //   getDocumentType.request(
  //     DocsService.getDocumentType({
  //       CurrentPage: 1,
  //       PageSize: 10
  //     })
  //   );
  // }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(`${"Vui lòng nhập tiêu đề"}`),
    description: Yup.string().required(`${"Vui lòng nhập nội dung"}`),
    dateOfIssue: Yup.string().required(`${"Vui lòng chọn ngày ban hành"}`),
    documentTypeId: Yup.string()
      .notOneOf([""], "You must select an option!")
      .required()
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<any>({
    defaultValues: editItem ?? {},
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    setValue(
      "reciver",
      editItem?.reciver?.map(reciver => ({
        idEdit: reciver.id,
        id: reciver.userOrDepartmentId,
        username: reciver.userName,
        fullName: reciver.userName,
        type: reciver.type
      }))
    );
  }, [editItem]);

  const onSubmit = (request: IDocument) => {
    request = { ...request, reciver: request.reciver };
    if (checkAll) {
      request = { ...request, reciver: [] };
    }
    if (deleteFiles.length > 0) {
      uploadFile.request(
        UploadFileService.deleteFiles(deleteFiles).then(res => {
          if (res.success) {
            toggleMessage({
              type: "success",
              message: res?.message ?? "Cập nhật thành công"
            });
          } else {
            toggleMessage({
              type: "error",
              message: res?.error ?? "Đã xảy ra lỗi, vui lòng thử lại"
            });
          }
        })
      );
    }
    handleOnSubmit(request);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f1f5f6",
        padding: "14px",
        position: "absolute",
        top: "50%",
        left: "50%",
        height: "auto",
        transform: "translate(-50%, -50%)",
        width: "80vw",
        borderRadius: "8px"
      }}
    >
      <Box display="flex">
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "16px",
            width: "70%"
          }}
        >
          <Typography variant="h5" marginBottom="14px">
            {editItem ? "CHỈNH SỬA VĂN BẢN" : "TẠO VĂN BẢN MỚI"}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex">
              <ControlForm title={"Số, kí hiệu"}>
                <InputForm
                  name={"numberIdentifier"}
                  placeholder="Số, kí hiệu"
                  control={control}
                  errors={errors}
                  disabled={isLoading}
                />
              </ControlForm>
              <Box flexGrow={1} marginLeft="14px">
                <ControlForm title={"Tiêu đề"} isRequired>
                  <InputForm
                    name="title"
                    placeholder="Tiêu đề thông báo"
                    required
                    control={control}
                    errors={errors}
                    disabled={isLoading}
                  />
                </ControlForm>
              </Box>
            </Box>

            <Box display="flex" margin="12px 0">
              <Box flexGrow={1}>
                <ControlForm title={"Ngày ban hành"} isRequired>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      name="dateOfIssue"
                      ampm={false}
                      format={DATETIME_FORMAT4}
                      disabled={isLoading}
                      defaultValue={
                        editItem ? moment(editItem.dateOfIssue) : undefined
                      }
                      sx={{
                        width: "100%",
                        border: errors.dateOfIssue ? "1px solid red" : "none",
                        borderRadius: "12px"
                      }}
                      onChange={e => {
                        if (e) {
                          setValue("dateOfIssue", e.format(DATE_REQ_FORMAT));
                        }
                      }}
                      dayOfWeekFormatter={day => `${day.format("ddd")}`}
                    />
                  </LocalizationProvider>
                  {errors.dateOfIssue && (
                    <Typography
                      variant="caption"
                      color="error"
                      marginLeft="16px"
                    >
                      Vui lòng chọn ngày ban hành
                    </Typography>
                  )}
                </ControlForm>
              </Box>
              <Box flexGrow={1} marginLeft="12px">
                <ControlForm title={"Ngày hết hạn"}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      name="documentExpirationDate"
                      ampm={false}
                      format={DATETIME_FORMAT4}
                      defaultValue={
                        editItem && getValues("documentExpirationDate")
                          ? moment(getValues("documentExpirationDate"))
                          : undefined
                      }
                      disabled={isLoading}
                      sx={{ width: "100%" }}
                      onChange={e => {
                        if (e) {
                          setValue(
                            "documentExpirationDate",
                            e.format(DATE_REQ_FORMAT)
                          );
                        }
                      }}
                      dayOfWeekFormatter={day => `${day.format("ddd")}`}
                    />
                  </LocalizationProvider>
                </ControlForm>
              </Box>
            </Box>
            <ControlForm title={"Nội dung"} isRequired>
              <Controller
                name={"description"}
                render={({ field }) => (
                  <TextEditorQuill
                    placeholder="Nhập nội dung..."
                    toolbar={true}
                    editorStyle={{
                      border: errors.description
                        ? "1px solid red"
                        : "1px solid #D3DCDF",
                      borderRadius: "8px",
                      width: "100%",
                      height: "40vh"
                    }}
                    onChange={e => field.onChange(e)}
                    value={field.value}
                  />
                )}
                control={control}
              />
              {errors.description && (
                <Typography variant="caption" color="error" marginLeft="16px">
                  Vui lòng nhập nội dung
                </Typography>
              )}
            </ControlForm>
          </form>
        </Box>
        <Box
          width="30%"
          marginLeft="14px"
          display="flex"
          flexDirection="column"
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "16px",
              flex: "0 0 auto"
            }}
          >
            <Box sx={{ opacity: checkAll ? "50%" : "100%" }}>
              <ControlForm title="Nơi nhận" classname="brand-form">
                <TagInput
                  name={"reciver"}
                  control={control}
                  errors={errors}
                  isError={!!errors.reciver}
                  placeholder={"Gõ tên để chọn người nhận, ..."}
                  disabled={checkAll}
                />
              </ControlForm>
            </Box>

            <Box display="flex" alignItems="center">
              <Controller
                name={"isViewAll"}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    icon={
                      <img src={IconUnCheck} width={"22px"} height={"22px"} />
                    }
                    checkedIcon={
                      <img src={CheckIcon} width={"22px"} height={"22px"} />
                    }
                    onChange={e => {
                      setCheckAll(e.target.checked);
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
              <Typography variant="subtitle2" fontWeight={"400"}>
                Gửi toàn công ty
              </Typography>
            </Box>
            <Box margin="12px 0">
              <ControlForm title={"Loại văn bản"} isRequired>
                <Controller
                  name={"documentTypeId"}
                  defaultValue={editItem?.documentTypeId}
                  render={({ field }) => (
                    <Select
                      {...field}
                      name="documentTypeId"
                      disabled={documentTypes.length < 0 || isLoading}
                      fullWidth
                      size="small"
                      placeholder="Vui lòng chọn"
                      error={!!errors.documentTypeId}
                      sx={{
                        fontSize: "small",
                        height: "40px"
                      }}
                    >
                      {documentTypes &&
                        documentTypes.map(type => (
                          <MenuItem
                            key={type.id}
                            value={type.id}
                            sx={{ fontSize: "small" }}
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                  control={control}
                />
                {errors.documentTypeId && (
                  <Typography variant="caption" color="error" marginLeft="16px">
                    Vui lòng chọn loại văn bản
                  </Typography>
                )}
              </ControlForm>
            </Box>
            <ControlForm title={"Trạng thái"}>
              <Controller
                name={"status"}
                defaultValue={
                  editItem?.status ?? EDocumentStatus.PendingRelease
                }
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={isLoading}
                    fullWidth
                    size="small"
                    placeholder="Vui lòng chọn"
                    sx={{
                      fontSize: "small",
                      height: "40px"
                    }}
                    error={!!errors.status}
                  >
                    {[
                      EDocumentStatus.PendingRelease,
                      EDocumentStatus.Released
                    ].map(status => (
                      <MenuItem
                        key={status}
                        value={status}
                        sx={{ fontSize: "small" }}
                      >
                        {documentStatusTitle(status)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </ControlForm>
            <Box height="12px" />
            <ControlForm title={"Từ khoá"}>
              <InputForm
                name={"tags"}
                placeholder="Từ khoá"
                control={control}
                errors={errors}
                disabled={isLoading}
              />
            </ControlForm>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "16px",
              margin: "14px 0",
              flex: "1 1 auto"
            }}
          >
            <ControlForm
              title="Tài liệu đính kèm"
              infor="Đính kèm tối đa 10 tài liệu"
              classname="brand-form"
            >
              <Box display="flex" flexDirection={"column"}>
                <Grid
                  container
                  sx={{
                    // width: "100",
                    height: "auto",
                    maxHeight: "180px",
                    overflow: "auto"
                    // flex: "1 1 auto"
                  }}
                >
                  {oldFiles.length > 0 &&
                    oldFiles.map((file, i) => (
                      <Grid key={`${i}/${file.fileName}`} item xs={"auto"}>
                        <Box
                          border={"1px solid #DEDEDE"}
                          borderRadius="8px"
                          margin="12px 0"
                          padding="4px 6px"
                          display="flex"
                          marginRight="6px"
                        >
                          <FileImage type={file.extension ?? EFileType.doc} />
                          <Typography variant="caption" margin="0 6px">
                            {file.fileName}
                          </Typography>
                          <Tooltip title="Xoá tài liệu">
                            <Button
                              sx={{ color: "#7E969F" }}
                              onClick={() => {
                                setDeletefiles([...deleteFiles, file]);
                                const list = getValues(
                                  "attachmentFiles"
                                ) as IAttachmentFile[];
                                setOldfiles(
                                  oldFiles.filter(o => o.id !== file.id)
                                );
                                setValue(
                                  "attachmentFiles",
                                  list.filter(l => l.id !== file.id)
                                );
                              }}
                            >
                              <CloseRounded sx={{ fontSize: 14 }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}

                  {files &&
                    files.map((file, i) => (
                      <Grid key={`${i}/${file.name}`} item xs={"auto"}>
                        <Box
                          border={"1px solid #DEDEDE"}
                          borderRadius="8px"
                          margin="12px 0"
                          padding="4px 6px"
                          display="flex"
                          marginRight="6px"
                        >
                          <FileImage
                            type={
                              `.${file.type.split("/")[1]}` ?? EFileType.doc
                            }
                          />
                          <Typography variant="caption" margin="0 6px">
                            {file.name}
                          </Typography>
                          <Tooltip title="Xoá tài liệu">
                            <Button
                              sx={{ color: "#7E969F" }}
                              onClick={() =>
                                setFiles(files.filter(f => f !== file))
                              }
                            >
                              <CloseRounded sx={{ fontSize: 14 }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                </Grid>

                <Box
                  sx={{
                    border: "1px dashed green",
                    borderRadius: "8px",
                    padding: "10px 0",
                    justifyContent: "center",
                    color: "green",
                    display: "flex",
                    opacity: isLoading ? "40%" : "100%",
                    flex: "0 0 auto"
                  }}
                  onClick={() => (isLoading ? {} : inputRef.current.click())}
                >
                  <ImageCustom src={FileIcon} width="18px" height="18px" />
                  <Typography variant="caption" marginLeft="8px">
                    Chọn tài liệu đính kèm
                  </Typography>
                  <input
                    name="avatar"
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={e => {
                      if (e.target?.files) {
                        const fileList = files.concat(
                          Array.from(e.target?.files)
                        );
                        setFiles(fileList);
                        setValue("attachmentFiles", [
                          ...(getValues("attachmentFiles") ?? []),
                          ...fileList
                        ]);
                      }
                    }}
                    className="input-file-upload"
                    hidden
                  />
                </Box>
              </Box>
            </ControlForm>
          </Box>
          <Box display="flex" flex={"0 0 auto"}>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                borderRadius: "8px",
                marginRight: "14px",
                color: "#7E969F",
                borderColor: "#7E969F"
              }}
              onClick={() => handleClose()}
              disabled={isLoading}
            >
              Huỷ
            </Button>
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{ borderRadius: "8px" }}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={20} />
              ) : editItem ? (
                `Cập nhật`
              ) : (
                `Tạo mới`
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateDocumentPopup;
