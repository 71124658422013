import { styled } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  margin: "14px",

  "& .header-box": {
    display: "flex",
    justifyContent: "end"
  },

  "& .text-overflow": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical"
  },

  "& .MuiTable-root": {
    width: "calc(100vw - 280px)",
    margin: "16px  auto",
    backgroundColor: "white"
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: variableStyles.NaturalColor50
  },
  "& .MuiTableHead-root": {
    backgroundColor: "transparent"
  },
  "& .MuiTableCell-root": {
    borderBottom: "0.5px solid #E7E9EF",
    borderRight: "0.5px solid #E7E9EF",
    borderTop: "0.5px solid #E7E9EF",
    padding: "6px 16px"
  },
  "& .MuiTableCell-head": {
    backgroundColor: "white",
    padding: "6px 16px ",
    fontSize: "13px",
    color: "#7E969F",
    fontWeight: "700"
  },

  "& .empty-box": {
    // width: "100%",
    // height: "calc(100vh - 120px)",
    justifyItems: "center",
    marginTop: "20vh"
  }
}));
