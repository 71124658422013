import { AddRounded, CancelRounded, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Modal,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { ImageCustom } from "../../../components";
import { IDocumentType } from "../../../models/docs";
import IconDelete from "../../../assets/images/common/trash_bin.svg";
import IconNotes from "../../../assets/images/common/Notes.svg";
import IconEdit from "../../../assets/images/common/Pen.svg";
import IconPower from "../../../assets/images/common/power.svg";
import IconActive from "../../../assets/images/common/check-circle.svg";
import CreateDocumentTypePopup from "./CreateDocumentTypePopup";
import DocsService from "../../../services/api/docs.service";
import { useApi } from "../../../hooks/useApi.hook";
import { toggleMessage } from "../../../components/Toast/Toast";
import { toggleConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";

type Props = {
  isCreate: boolean;
  isEdit: boolean;
  isDelete: boolean;
  data: IDocumentType[];
  handleClose: Function;
  style?: SxProps<Theme>;
};

const DocumentTypePopup = ({
  data,
  style,
  handleClose,
  isCreate,
  isDelete,
  isEdit
}: Props) => {
  const [dataItem, setDataItem] = useState(data);
  const [isChange, setIsChange] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDocumentType | undefined>();
  const [listAction, setListAction] = useState<any[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const getDocumentType = useApi<IDocumentType[]>({ isFetch: true });
  const deleteItem = useApi<IDocumentType>({});
  const updateDocumentType = useApi<IDocumentType>({});

  useEffect(() => {
    var list: any[] = [];
    if (isEdit) {
      list = [
        {
          key: "active",
          title: "Kích Hoạt",
          icon: <ImageCustom src={IconActive} width="16px" height="16px" />
        },
        {
          key: "edit",
          title: "Chỉnh Sửa",
          icon: <ImageCustom src={IconEdit} width="16px" height="16px" />
        }
      ];
    }
    if (isDelete) {
      list.push({
        key: "delete",
        title: "Xoá",
        icon: <ImageCustom src={IconDelete} width="16px" height="16px" />
      });
    }
    setListAction(list);
  }, []);

  function fetchDocumentType() {
    getDocumentType.request(
      DocsService.getDocumentType({
        CurrentPage: 1,
        PageSize: 10
      }).then(res => {
        if (res.success) {
          res.items ? setDataItem(res.items) : handleClose(isChange);
          if (!isChange) setIsChange(true);
        } else {
          toggleMessage({
            type: "error",
            message: res?.error ?? "Đã xảy ra lỗi"
          });
        }
      })
    );
  }

  const handleClick = (key: string, item?: IDocumentType) => {
    switch (key) {
      case "delete":
        if (item) {
          toggleConfirmModal({
            title: `Bạn có chắc chắc muốn xoá?`,
            content: `Bạn không thể khôi phục sau khi xác nhận`,
            onSubmit: async () => {
              deleteItem.request(
                DocsService.deleteDocType(item.id).then(res => {
                  if (res.success) {
                    toggleMessage({
                      type: "success",
                      message: res?.message ?? "Cập nhật thành công"
                    });
                    setAnchorEl(null);
                    fetchDocumentType();
                  } else {
                    toggleMessage({
                      type: "error",
                      message: res?.error ?? "Đã xảy ra lỗi"
                    });
                  }
                })
              );
            },
            submitText: "Xác nhận",
            type: "warning-red"
          });
        }
        break;

      case "edit":
        setOpenEdit(true);
        setAnchorEl(null);
        break;

      case "active":
        if (item) {
          toggleConfirmModal({
            title: `Bạn có chắc chắc muốn thay đổi?`,
            content: `Trạng thái sẽ được thay đổi sau khi xác nhận`,
            onSubmit: async () => {
              updateDocumentType.request(
                DocsService.updateDocType({
                  ...item,
                  isActive: !item.isActive
                }).then(res => {
                  if (res.success) {
                    toggleMessage({
                      type: "success",
                      message: res?.message ?? "Cập nhật thành công"
                    });
                    setAnchorEl(null);
                    fetchDocumentType();
                  } else {
                    toggleMessage({
                      type: "error",
                      message: res?.error ?? "Đã xảy ra lỗi"
                    });
                  }
                })
              );
            },
            submitText: "Xác nhận",
            type: "warning-red"
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Box sx={style}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" flexGrow={1}>
          Loại văn bản ({dataItem.length})
        </Typography>
        <Button
          onClick={() => handleClose(isChange)}
          style={{
            backgroundColor: "black",
            borderRadius: "32px",
            padding: 0,
            width: "18px",
            height: "18px",
            margin: "8px 0"
          }}
        >
          <CancelRounded sx={{ color: "white" }} />
        </Button>
      </Box>
      {isCreate && (
        <Box
          sx={{
            border: "1.2px dashed green",
            width: "100%",
            padding: "10px",
            marginTop: "12px",
            borderRadius: "8px",
            justifyItems: "center"
          }}
          onClick={() => setOpenCreate(true)}
        >
          <Typography variant="subtitle2" color="green">
            <span>
              <AddRounded
                sx={{ fontSize: 18, marginRight: "12px", color: "green" }}
              />
            </span>
            Thêm mới
          </Typography>
        </Box>
      )}
      {dataItem.map(type => (
        <Box
          key={type.id}
          sx={{
            backgroundColor: "white",
            padding: "12px",
            marginTop: "12px",
            borderRadius: "8px"
          }}
          display="flex"
        >
          <ImageCustom src={IconNotes} width="24px" height="24px" />
          <Typography margin="0 12px" flexGrow={1}>
            {type.name}
          </Typography>
          {(isEdit || isDelete) && (
            <Button
              onClick={event => {
                setAnchorEl(event.currentTarget);
                setSelectedItem(type);
              }}
            >
              <MoreVert />
            </Button>
          )}

          {selectedItem && (
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button"
              }}
              anchorEl={anchorEl}
              open={anchorEl !== null}
              onClose={() => setAnchorEl(null)}
            >
              {listAction.map(option => (
                <MenuItem
                  key={option.key}
                  onClick={() => handleClick(option.key, selectedItem)}
                >
                  {option.key === "active" ? (
                    selectedItem.isActive ? (
                      <ImageCustom src={IconPower} width="16px" height="16px" />
                    ) : (
                      option.icon
                    )
                  ) : (
                    option.icon
                  )}
                  <Typography variant="caption" marginLeft="12px">
                    {option.key === "active" &&
                      `${selectedItem.isActive ? "Huỷ\t" : ""}`}
                    {option.title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
      ))}

      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        <Box sx={style}>
          <CreateDocumentTypePopup
            handleClose={() => {
              fetchDocumentType();
              setOpenCreate(false);
            }}
          />
        </Box>
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        <Box sx={style}>
          <CreateDocumentTypePopup
            editItem={selectedItem}
            handleClose={() => {
              fetchDocumentType();
              setOpenEdit(false);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default DocumentTypePopup;
