import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import { Wrapper } from "./styles";
import DocsContent from "../DocsContent";
import { ImageCustom } from "../../../../components";
import EmptyProject from "../../../../assets/images/common/empty_project.svg";

import { useApi } from "../../../../hooks/useApi.hook";
import { IDocument } from "../../../../models/docs";
import DocsService from "../../../../services/api/docs.service";
import { useEffect, useState } from "react";
import moment from "moment";
import { DATETIME_FORMAT2 } from "../../../../config/constants";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { IBasePaging } from "../../../../models/common/models.type";
import SearchInput from "../../../Member/components/SearchInput";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

type Props = {};

const DocsUserView = (props: Props) => {
  const [data, setData] = useState<IDocument[]>([]);
  const [inputValue, setInputValue] = useState<string | undefined>();

  const [paging, setPaging] = useState<IBasePaging | null>(null);

  const getPage = useApi<IDocument[]>({ isFetch: true, isPaging: true });
  const getSingle = useApi<IDocument>({ isFetch: true });
  const [contentView, setContentView] = useState(
    <Box className="empty-box">
      <ImageCustom src={EmptyProject} width="auto" height="165px" />
      <Typography variant="subtitle1" marginTop="28px">
        Không có nội dung hiển thị
      </Typography>
    </Box>
  );

  function fetchData(pageIdx?: number) {
    getPage.request(
      DocsService.getPageByUser({
        CurrentPage: pageIdx ?? 1,
        PageSize: 10,
        KeySearch: inputValue
      }).then(res => {
        if (res.success) {
          setData(res.currentPage === 1 ? res.items : data.concat(res.items));
          setPaging({
            currentPage: res.currentPage,
            totalPages: res.totalPages,
            pageSize: res.pageSize,
            totalCount: res.totalCount
          });
        }
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Box
        className="content-list"
        display={paging?.totalCount === 0 ? "none" : "flex"}
        flexDirection="column"
      >
        <Box margin="32px 24px">
          <SearchInput
            fullWidth
            placeholder="Tìm kiếm văn bản.."
            onChange={e => setInputValue(e.target.value)}
            search={() => fetchData()}
          />
        </Box>
        <Box className="content-list-item">
          {data.length > 0 &&
            data.map(item => (
              <Box
                key={item.id}
                className="list-item"
                onClick={() =>
                  getSingle.request(
                    DocsService.getSingle(item.id).then(res => {
                      if (res.success) {
                        setContentView(<DocsContent item={res.data} />);
                      }
                    })
                  )
                }
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="div"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical"
                    }}
                  >
                    {item.numberIdentifier && `[${item.numberIdentifier}]`}{" "}
                    {item?.title}
                  </Typography>
                  <div
                    className="text-overflow"
                    style={{
                      color: "text.secondary",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical"
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.description.length ? item.description : ""
                    }}
                  ></div>
                  <Box
                    display="flex"
                    marginTop="8px"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary"
                      }}
                    >
                      {moment(item.dateOfIssue).format(DATETIME_FORMAT2)}
                    </Typography>
                    {/* <Box>
                      <CircleIcon
                        sx={{
                          fontSize: "10px",
                          marginRight: "6px",
                          color: variableStyles.Info500
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary"
                        }}
                      >
                        Chưa xem
                      </Typography>
                    </Box> */}
                  </Box>
                </CardContent>
              </Box>
            ))}

          {paging && paging.currentPage !== paging.totalPages && (
            <Box margin="18px 0" justifyContent="center">
              {getPage.loading ? (
                <CircularProgress color="info" />
              ) : (
                <Button
                  color="info"
                  endIcon={<KeyboardArrowDownRounded />}
                  onClick={() => fetchData(paging.currentPage + 1)}
                >
                  Hiển thị văn bản cũ hơn
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Box className="content-page">{contentView}</Box>
    </Wrapper>
  );
};

export default DocsUserView;
