import { UniqueIdentifier } from "@dnd-kit/core";
import { ControlForm, Modal } from "../../../components";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import useAxios from "../../../components/UseAxios/useAxios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toggleMessage } from "../../../components/Toast/Toast";

import * as Yup from "yup";
import InputForm from "../../../components/controls/InputForm";
import WorkflowService from "../../../services/api/workflow.service";
import { ICopyWorkflowRequest } from "../../../models/workflow";

interface Props {
  open: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
  workflowId: UniqueIdentifier;
}
interface DataForm {
  name: string;
  type: string;
}

const CopyWorkflowModal = ({
  open,
  workflowId,
  handleSuccess,
  handleClose
}: Props) => {
  const copyWorkflowAxios = useAxios<string>({ loading: "OnRequest" });

  useEffect(() => {
    reset();
  }, [open]);

  useEffect(() => {
    if (copyWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: copyWorkflowAxios.error?.message ?? ""
      });
    if (copyWorkflowAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: copyWorkflowAxios.message ?? ""
      });
      handleSuccess();
      handleClose();
    }
  }, [copyWorkflowAxios.error, copyWorkflowAxios.isSuccess]);

  const onSubmit = async (data: DataForm) => {
    console.log(data);

    const request: ICopyWorkflowRequest = {
      Id: workflowId,
      Name: data.name,
      TypeBoard: data.type
    };

    copyWorkflowAxios.request(WorkflowService.copyWorkflow(request));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(`${"Vui lòng nhập tên luồng công việc"}`),
    type: Yup.string().trim().required(`${"Vui lòng nhập tên phân loại"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  return (
    <Modal
      title="Nhân bản thành luồng công việc mới"
      textSubmit="Xác nhận"
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={copyWorkflowAxios.isLoading}
      disabledSubmit={copyWorkflowAxios.isLoading}
      onClose={
        !copyWorkflowAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm
          title="Tên luồng công việc mới"
          classname="brand-form"
          children={
            <InputForm
              placeholder="Tên luồng công việc "
              name="name"
              errors={errors}
              control={control}
              required
              size="small"
            />
          }
        />
        <ControlForm
          title="Phân loại luồng công việc"
          classname="brand-form"
          children={
            <InputForm
              placeholder="Tên phân loại"
              name="type"
              errors={errors}
              control={control}
              required
              size="small"
            />
          }
        />
      </form>
    </Modal>
  );
};

export default CopyWorkflowModal;
