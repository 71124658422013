import { Badge, Box, IconButton } from "@mui/material";
import Avatar from "../../../components/Avatar";

import CameraAltIcon from "@mui/icons-material/CameraAltRounded";
import { ChangeEventHandler } from "react";
import { envConfig } from "../../../config/env.config";

type Props = {
  file: File | null;
  avatar?: string | null;
  onButtonClick: Function;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
  inputRef: any;
  size?: string;
};

const EditAvatar = (props: Props) => {
  const { file, onButtonClick, handleFileChange, inputRef, avatar, size } =
    props;

  const getAvatar = () => {
    const imgUrl = !file && avatar ? avatar : undefined;
    return file ? URL.createObjectURL(file) : imgUrl;
  };

  return (
    <Box textAlign="center">
      <input
        name="avatar"
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={false}
        onChange={handleFileChange}
        className="input-file-upload"
        hidden
        accept="image/png, image/jpeg"
      />
      <IconButton onClick={() => onButtonClick()}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          badgeContent={<CameraAltIcon style={{ fontSize: 18 }} />}
        >
          <Avatar
            alt="User"
            src={getAvatar()}
            isUrl={!file}
            width={size ?? "52px"}
            height={size ?? "52px"}
          />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default EditAvatar;
