import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem
} from "@mui/material";
import InputForm from "../../../../components/controls/InputForm";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateWorkflowFormItem from "./components/FormItem/form_item";
import { CreateWorkflowDialogWrapper } from "./styles";
import TextEditorQuill from "../../../../components/TextEditorQuill";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Dropdown from "./components/Dropdown";
import { TagInput } from "../TagInput/TagInput";
import WorkflowService from "../../../../services/api/workflow.service";
import { ITag } from "../../../../models/user";
import { toggleMessage } from "../../../../components/Toast/Toast";
import useAxios from "../../../../components/UseAxios/useAxios";
import { ICreateWorkflowRequest, IWorkflow } from "../../../../models/workflow";
import { IStage } from "../../../../models/Stage";
import StageService from "../../../../services/api/stage.service";
import { UniqueIdentifier } from "@dnd-kit/core";
import { SearchWorkflow } from "./components/SearchWorkflow/SearchWorkflow";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { get } from "lodash";

export interface DataForm {
  workflowName: string;
  typeBoard: string;
  management: ITag[];
  assignTasks: ITag[];
  viewAllTasks: ITag[];
  viewTasks: ITag[];
  stageTransitionId: string; //  stage Transition
  stageTransitionToWorkflow: IWorkflow; // workflow position
  stageTransitionToWStageId: string; // stage position
  permission: any[];
  display: any[];
  description: string;
  reasonFailure: string;
}
interface _StageTransition {
  BoardId: string;
  WorkflowId?: string;
  WorkflowRelateds?: _INewWorkflowPosition[];
}
interface _INewWorkflowPosition {
  BoardRelatedId?: string;
  WorkflowRelatedId?: string;
}

interface CreateWorkflowDialogProps {
  open: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
  workflowId?: UniqueIdentifier;
}

const CreateWorkflowDialog = ({
  open,
  handleSuccess,
  handleClose,
  workflowId
}: CreateWorkflowDialogProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const createWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const updateWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const getWorkflowAxios = useAxios<IWorkflow>({ loading: "OnRequest" });

  const getStagesCurrentWorkflowAxios = useAxios<IStage[]>({
    loading: "OnRequest"
  });

  const getStagesNextWorkflowAxios = useAxios<IStage[]>({
    loading: "OnRequest"
  });

  const [updateLoaded, setUpdateLoaded] = useState<boolean>(false);
  const [stageTransition, setStageTransition] = useState<_StageTransition>({
    BoardId: (workflowId ?? "") as string
  });

  useEffect(() => {
    if (open === true) {
      reset();
      setUpdateLoaded(false);
      if (workflowId) {
        getWorkflowAxios.request(WorkflowService.getWorkflow(workflowId));
        getStagesCurrentWorkflowAxios.request(
          StageService.getListStageByWorkflowId(workflowId)
        );
      }
    }
    return () => {
      createWorkflowAxios.reset();
      getWorkflowAxios.reset();
      updateWorkflowAxios.reset();
    };
  }, [open, workflowId]);

  useEffect(() => {
    if (getWorkflowAxios.data) {
      const workflow = getWorkflowAxios.data;
      reset({
        workflowName: workflow.name,
        typeBoard: workflow.typeBoard,
        management: workflow.processManagements.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        assignTasks: workflow.assignTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        viewAllTasks: workflow.viewAllTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        viewTasks: workflow.viewTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        stageTransitionId: workflow.stageTransition?.workflowId,
        stageTransitionToWorkflow: workflow.stageTransition?.workflowRelateds[0]
          ? {
              name: workflow.stageTransition.workflowRelateds[0].boardName,
              id: workflow.stageTransition.workflowRelateds[0].boardRelatedId
            }
          : undefined,
        stageTransitionToWStageId:
          workflow.stageTransition?.workflowRelateds?.[0]?.workflowRelatedId,
        description: workflow.description ?? undefined,
        reasonFailure: workflow.reasonForFailure
      });

      if (workflow.stageTransition) {
        setStageTransition({
          BoardId: workflow.stageTransition.boardId,
          WorkflowId: workflow.stageTransition.workflowId,
          WorkflowRelateds: workflow.stageTransition.workflowRelateds.map(
            e => ({
              BoardRelatedId: e.boardRelatedId,
              WorkflowRelatedId: e.workflowRelatedId
            })
          )
        });
      }
      setUpdateLoaded(true);
    }

    if (getWorkflowAxios.error) {
      toggleMessage({
        type: "error",
        message: getWorkflowAxios.error.message ?? ""
      });
      handleClose();
    }
  }, [getWorkflowAxios.data, getWorkflowAxios.error]);

  const validationSchema = Yup.object().shape({
    workflowName: Yup.string()
      .trim()
      .required(`${"Vui lòng nhập tên luồng công việc"}`),
    typeBoard: Yup.string().required(
      `${"Vui lòng chọn phân loại luồng công việc"}`
    ),
    stageTransitionId: Yup.string().nullable(),
    stageTransitionToWorkflow: Yup.mixed<IWorkflow>()
      .nullable()
      .when("stageTransitionId", {
        is: (val: string) => val !== undefined,
        then: schema => schema.required("Vui lòng chọn luồng công việc"),
        otherwise: schema => schema.nullable()
      })
      .when("stageTransitionId", {
        is: (val: string | undefined) => val === undefined,
        then: schema => {
          resetField("stageTransitionToWorkflow");
          return schema.nullable();
        }
      }),
    stageTransitionToWStageId: Yup.string()
      .nullable()
      .when("stageTransitionToWorkflow", {
        is: (val: IWorkflow) => val !== undefined,
        then: schema => schema.required("Vui lòng chọn giai đoạn chuyển tiếp"),
        otherwise: schema => schema.nullable()
      })
      .when("stageTransitionToWorkflow", {
        is: (val: string | undefined) => val === undefined,
        then: schema => {
          resetField("stageTransitionToWStageId");
          return schema.nullable();
        }
      })
  }) as any;

  const {
    control,
    reset,
    resetField,
    getValues,
    setValue,
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    getStagesNextWorkflowAxios.reset();
    if (getValues("stageTransitionToWorkflow")?.id) {
      getStagesNextWorkflowAxios.request(
        StageService.getListStageByWorkflowId(
          getValues("stageTransitionToWorkflow").id
        )
      );
    }
  }, [getValues("stageTransitionToWorkflow")]);

  useEffect(() => {
    if (createWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: createWorkflowAxios.error.message ?? ""
      });
    if (createWorkflowAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: createWorkflowAxios.message ?? "Tạo luồng công việc thành công"
      });
      handleSuccess();
      handleClose();
    }
  }, [createWorkflowAxios.error, createWorkflowAxios.isSuccess]);

  useEffect(() => {
    if (updateWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: updateWorkflowAxios.error.message ?? ""
      });
    if (updateWorkflowAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateWorkflowAxios.message ?? "Cập nhật thành công"
      });
      handleSuccess();
      handleClose();
    }
  }, [updateWorkflowAxios.error, updateWorkflowAxios.isSuccess]);

  const onSubmit = async (data: DataForm) => {
    const _req: ICreateWorkflowRequest = {
      TypeBoard: data.typeBoard,
      Name: data.workflowName,
      Description: data.description,
      ReasonForFailure: data.reasonFailure,
      ProcessManagements: data.management?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      AssignTasks: data.assignTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      ViewAllTasks: data.viewAllTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      ViewTasks: data.viewTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      }))
    };

    if (workflowId) {
      if (data.stageTransitionId) {
        _req.StageTransition = {
          BoardId: workflowId as string,
          WorkflowId: data.stageTransitionId,
          WorkflowRelateds: [
            {
              BoardRelatedId: data.stageTransitionToWorkflow.id,
              WorkflowRelatedId: data.stageTransitionToWStageId
            }
          ]
        };
      }

      updateWorkflowAxios.request(
        WorkflowService.updateWorkflow(workflowId, _req)
      );
    } else {
      createWorkflowAxios.request(WorkflowService.createWorkflow(_req));
    }
  };

  return (
    <CreateWorkflowDialogWrapper
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ backgroundColor: "#ededed" }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <p>
            {workflowId
              ? "CẬP NHẬT LUỒNG CÔNG VIỆC"
              : "TẠO LUỒNG CÔNG VIỆC MỚI"}
          </p>
          <Button onClick={handleClose} sx={{ color: "grey" }}>
            <Close />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent>
        {workflowId && getWorkflowAxios.isLoading && (
          <center>
            <CircularProgress />
          </center>
        )}
        {(!workflowId ||
          (workflowId && getWorkflowAxios.isSuccess && updateLoaded)) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CreateWorkflowFormItem
              name={"Tên luồng công việc"}
              desciption={"Tên luồng công việc"}
              input={
                <InputForm
                  placeholder="Tên luồng công việc"
                  required
                  name="workflowName"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            />
            <CreateWorkflowFormItem
              name={"Phân loại luồng công việc"}
              desciption={"Phân loại luồng công việc"}
              input={
                <InputForm
                  placeholder="Phân loại (ví dụ: Phòng KD, Human Resource)"
                  required
                  name="typeBoard"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Thành viên quản trị quy trình"}
              desciption={
                "Người quản trị có thể xem nhiệm vụ của mọi thành viên. Bạn được mặc định là người quản trị"
              }
              input={
                <TagInput
                  name={"management"}
                  control={control}
                  errors={errors}
                  defaultValues={getValues("management")}
                  isError={!!errors.management}
                  placeholder={"Tag thành viên quản trị"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Nhóm Thành viên có quyền tạo các nhiệm vụ mới"}
              desciption={
                "Các thành viên hoặc nhóm thành viên có quyền tạo nhiệm vụ trong luồng công việc"
              }
              input={
                <TagInput
                  name={"assignTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.assignTasks}
                  placeholder={"Viết tên thành viên hoặc phòng ban"}
                />
              }
            ></CreateWorkflowFormItem>
            {workflowId && (
              <CreateWorkflowFormItem
                name={"Chuyển tiếp công việc khi đến giai đoạn"}
                desciption={
                  "Công việc ở giai đoạn được chọn sẽ chuyển đến luồng công việc khác"
                }
                input={
                  <Dropdown
                    name={"stageTransitionId"}
                    control={control}
                    value={getValues("stageTransitionId")}
                    onChange={value => {
                      setStageTransition({
                        BoardId: workflowId as string,
                        WorkflowId: value
                      });

                      if (!value) {
                      }

                      reset({
                        ...getValues(),
                        stageTransitionToWorkflow: undefined,
                        stageTransitionToWStageId: undefined
                      });
                      console.log(getValues());
                      trigger("stageTransitionToWorkflow");
                    }}
                    options={(getStagesCurrentWorkflowAxios.data ?? [])
                      .map((option, index) => (
                        <MenuItem
                          sx={{ fontSize: 13 }}
                          value={option.id}
                          key={`stageTransitionId-item-${index}`}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                      .concat(
                        <MenuItem
                          sx={{ fontSize: 13 }}
                          value={""}
                          key={`stageTransitionId-item-none`}
                        >
                          {"Không có giai đoạn nào"}
                        </MenuItem>
                      )}
                    error={errors.stageTransitionId}
                  />
                }
              ></CreateWorkflowFormItem>
            )}
            {stageTransition?.WorkflowId && (
              <CreateWorkflowFormItem
                name={"Chọn luồng công việc"}
                icon={<SubdirectoryArrowRightIcon color="primary" />}
                desciption={""}
                input={
                  <SearchWorkflow
                    name={"stageTransitionToWorkflow"}
                    control={control}
                    errors={errors}
                    isError={!!errors.stageTransitionToWorkflow}
                    value={getValues("stageTransitionToWorkflow")}
                    onChange={e => {
                      console.log("2", e);
                      setStageTransition({
                        ...stageTransition,
                        WorkflowRelateds: [
                          {
                            BoardRelatedId: e?.id
                          }
                        ]
                      });
                      reset({
                        ...getValues(),
                        stageTransitionToWStageId: undefined
                      });
                      trigger("stageTransitionToWorkflow");
                      trigger("stageTransitionToWStageId");
                    }}
                    placeholder={"Chọn luồng công việc"}
                  />
                }
              ></CreateWorkflowFormItem>
            )}
            {stageTransition?.WorkflowRelateds?.[0]?.BoardRelatedId && (
              <CreateWorkflowFormItem
                name={"Chọn giai đoạn chuyển tiếp"}
                icon={
                  <SubdirectoryArrowRightIcon
                    color="primary"
                    sx={{ marginLeft: 2 }}
                  />
                }
                desciption={""}
                input={
                  <Dropdown
                    name={"stageTransitionToWStageId"}
                    control={control}
                    value={getValues("stageTransitionToWStageId")}
                    onChange={value => {
                      setStageTransition({
                        ...stageTransition!,
                        WorkflowRelateds: [
                          {
                            BoardRelatedId:
                              stageTransition?.WorkflowRelateds?.[0]
                                ?.BoardRelatedId,
                            WorkflowRelatedId: value
                          }
                        ]
                      });
                      trigger("stageTransitionToWStageId");
                    }}
                    options={(getStagesNextWorkflowAxios.data ?? []).map(
                      (option, index) => (
                        <MenuItem
                          sx={{ fontSize: 13 }}
                          value={option.id}
                          key={`stageTransitionToWStageId-item-${index}`}
                        >
                          {option.name}
                        </MenuItem>
                      )
                    )}
                    error={errors.stageTransitionToWStageId}
                  />
                }
              ></CreateWorkflowFormItem>
            )}

            <Divider>CÁC TUỲ CHỌN KHÁC</Divider>

            {/* <CreateWorkflowFormItem
              name={"Tuỳ chọn quyền xem các nhiệm vụ"}
              desciption={"Quyền xem các nhiệm vụ trong luồng công việc"}
              input={
                <Controller
                  control={control}
                  name={"permission"}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={permissionOptions}
                      values={field.value}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem> */}
            {/* <CreateWorkflowFormItem
              name={"Tuỳ chọn hiển thị các nhiệm vụ"}
              desciption={
                "Tuỳ chọn hiển thị các nhiệm vụ trong luồng công việc"
              }
              input={
                <Controller
                  control={control}
                  name={"display"}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={displayOptions}
                      values={field.value}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem> */}
            <CreateWorkflowFormItem
              name={"Người giám sát workflow"}
              desciption={
                "Thành viên có quyền xem & review tất cả nhiệm vụ nhưng không có quyền chỉnh sửa"
              }
              input={
                <TagInput
                  name={"viewAllTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.viewAllTasks}
                  placeholder={"Sử dụng @ để tag thành viên"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Nhóm thành viên có thể xem được workflow"}
              desciption={
                "Các thành viên hoặc nhóm thành viên có thể nhìn thấy luồng công việc trong danh sách"
              }
              input={
                <TagInput
                  name={"viewTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.viewTasks}
                  placeholder={"Viết tên phòng ban"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Hướng dẫn làm việc với quy trình"}
              desciption={"Mô tả chi tiết về luồng công việcs"}
              input={
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field }) => (
                    <TextEditorQuill
                      {...field}
                      placeholder="Mô tả chi tiết về luồng công việc"
                      toolbar={true}
                      editorStyle={{
                        border: "1px solid #D3DCDF",
                        borderRadius: "8px",
                        width: "100%",
                        height: "24vh"
                      }}
                      quillRef={quillRef}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem>

            <Divider>LÝ DO THẤT BẠI</Divider>

            <CreateWorkflowFormItem
              name={"Lý do thất bại"}
              desciption={
                "Vui lòng ghi chi tiết các lí do thất bại có thể xảy ra, nếu có"
              }
              input={
                <InputForm
                  placeholder="Điền một lý do thất bại có thể có"
                  required
                  name="reasonFailure"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            ></CreateWorkflowFormItem>
          </form>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={
            createWorkflowAxios.isLoading === false &&
            updateWorkflowAxios.isLoading === false &&
            getWorkflowAxios.isLoading === false
              ? handleClose
              : undefined
          }
          sx={{ height: 40, borderRadius: 1.5 }}
        >
          Bỏ qua
        </Button>
        <Box width={24}></Box>
        <Button
          fullWidth
          disabled={
            createWorkflowAxios.isLoading ||
            updateWorkflowAxios.isLoading ||
            getWorkflowAxios.isLoading
          }
          sx={{ height: 40, borderRadius: 1.5 }}
          variant="contained"
          type="submit"
          onClick={
            createWorkflowAxios.isLoading === false &&
            updateWorkflowAxios.isLoading === false &&
            getWorkflowAxios.isLoading === false
              ? handleSubmit(onSubmit)
              : undefined
          }
        >
          {createWorkflowAxios.isLoading || updateWorkflowAxios.isLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : workflowId ? (
            "Cập nhật luồng công việc"
          ) : (
            "Tạo luồng công việc mới"
          )}
        </Button>
      </DialogActions>
    </CreateWorkflowDialogWrapper>
  );
};

export default CreateWorkflowDialog;
