import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "380px",
  "& .event-action-button": {
    fontSize: "12px",
    padding: 0,
    marginTop: "10px",
    height: "32px",
    width: "110px",
    justifyItems: "end",
    // flexGrow: 1,
    borderRadius: "8px",
    marginRight: "12px"
  },
  svg: {
    fontSize: 15,
    color: "GrayText"
  }
}));
