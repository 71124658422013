export const HYBRID_CRYTO_RSA_STANDARD = "RSAES-PKCS1-V1_5";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const DATETIME_FORMAT = "hh:mm  DD-MM-YYYY";
export const DATE_SELECT_FORMAT = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT2 = "yyyy-MM-DD";
export const DATE_REQ_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const DATETIME_FORMAT3 = "YYYY-MM-DDTHH:mm";
export const DATETIME_FORMAT2 = "HH:mm DD/MM/YYYY";
export const DATETIME_FORMAT4 = "DD/MM/YYYY HH:mm";
