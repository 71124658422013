import { USER_FCM_TOKEN_KEY } from "./../../services/local/auth-tokens";
import { all, put, takeEvery } from "redux-saga/effects";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { toggleMessage } from "../../components/Toast/Toast";
import { ILoginResponse } from "../../models/auth";
import { IBaseResponseModel } from "../../models/common/models.type";
import {
  EAuthType,
  IActionAdminLoginWithPassword,
  IActionLoginWithPassword,
  IActionLogout
} from "../../models/redux/auth";
import AuthService from "../../services/api/auth.service";
import authTokens from "../../services/local/auth-tokens";
import { clearUser } from "../user/user.duck";
import { jwtDecode } from "jwt-decode";
import { IUser } from "../../models/user";

function* loginWithPassword({ payload }: IActionLoginWithPassword) {
  const { loginRequest, navigate } = payload;
  try {
    const resLogin: IBaseResponseModel<string> =
      yield AuthService.loginWithPassword(loginRequest);
    if (resLogin.success) {
      const token = resLogin.data;
      const user = JSON.parse(JSON.stringify(jwtDecode(token)));
      const userInfo: IUser = {
        id: user.userId,
        code: null,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        avatar: user.avatar,
        email: user.email,
        phoneNumber: null,
        userName: "",
        roles: user.role,
        roleId: user.roleId,
        role: null,
        position: null,
        address: null,
        managerId: null,
        birthDay: null,
        manager: null,
        status: 0,
        departments: [],
        staff: [],
        isTimeOffStart: false
      };

      yield authTokens.storeToken(token);
      yield authTokens.storeUser(userInfo);
      yield authTokens.storeRole(userInfo.roles);
      const to = { pathname: ROUTE_PATH.INDEX, user: user };

      navigate(to, { replace: true });
    } else if (!resLogin.success) {
      toggleMessage({
        type: "error",
        message: resLogin.message ?? "Your email or password is incorrect!"
      });
    }
  } catch (err) { }
}

function* adminLoginWithPassword({ payload }: IActionAdminLoginWithPassword) {
  const { loginRequest, navigate } = payload;
  try {
    const resLogin: IBaseResponseModel<ILoginResponse> =
      yield AuthService.adminLoginWithPassword(loginRequest);
    if (resLogin.success) {
      const loginInfo = resLogin.data;

      yield authTokens.storeToken(loginInfo.token);
      // yield authTokens.storeRole(
      //   loginInfo.user.role === Roles.Admin
      //     ? Roles.AdminSystem
      //     : loginInfo.user.role
      // );
      navigate(ROUTE_PATH.INDEX, { replace: true });
    } else {
      toggleMessage({
        type: "error",
        message: "Your email or password is incorrect!"
      });
    }
  } catch (err) { }
}

function* logout({ payload }: IActionLogout) {
  const { navigate } = payload;
  try {
    const token = localStorage.getItem(USER_FCM_TOKEN_KEY);
    if (token) {
      yield AuthService.logout(token);
    }
    yield authTokens.clear();
    yield put(clearUser());

    navigate && navigate(ROUTE_PATH.LOGIN, { replace: true });
  } catch (err) { }
}

export default function* rootSaga() {
  yield all([
    takeEvery(EAuthType.LOGIN_WITH_PASSWORD, loginWithPassword),
    takeEvery(EAuthType.ADMIN_LOGIN_WITH_PASSWORD, adminLoginWithPassword),
    takeEvery(EAuthType.LOGOUT, logout)
  ]);
}
