import React, { useEffect, useRef, useState } from "react";
import { ControlForm, Modal } from "../../../components";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import { ITaskCard, ITaskCardUpdateRequest } from "../../../models/Task";
import { TagType } from "../../../models/common/models.enum";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ITag } from "../../../models/user";
import useAxios from "../../../components/UseAxios/useAxios";
import TaskService from "../../../services/api/task.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import TextEditorQuill from "../../../components/TextEditorQuill";
import { FormControl } from "@mui/material";
import ReactQuill from "react-quill";

interface EditTaskDescriptionProps {
  open: boolean;
  onClose: () => void;
  onUpdateSuccess: () => void;
  task: ITaskCard;
}

interface FormData {
  description: string;
}

const EditTaskDescriptionModal: React.FC<EditTaskDescriptionProps> = ({
  open,
  onClose,
  onUpdateSuccess,
  task
}) => {
  const updateDescriptionAxios = useAxios<ITaskCard>({ loading: "OnRequest" });
  const quillRef = useRef<ReactQuill>(null);
  const validationSchema = Yup.object().shape({}) as any;

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: FormData) => {
    const request: ITaskCardUpdateRequest = {
      id: task.id,
      workflowId: task.workflowId,
      boardId: task.boardId,
      title: task.title,
      description: data.description ?? "",
      deadLine: task.deadLine,
      attachmentFiles: task.attachmentFiles,
      personAssignedId: task.personAssignedId,
      taskTrackerIds: task.taskTrackerIds
    };

    updateDescriptionAxios.request(TaskService.updateTask(request));
  };

  useEffect(() => {
    if (open) {
      reset({
        description: task.description ?? ""
      });
      updateDescriptionAxios.reset();
    }
  }, [open, task, reset]);

  useEffect(() => {
    if (updateDescriptionAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateDescriptionAxios.message ?? "Cập nhật thành công"
      });
      onUpdateSuccess();
      onClose();
    }
    if (updateDescriptionAxios.error) {
      toggleMessage({
        type: "error",
        message: updateDescriptionAxios.error?.message ?? ""
      });
      onClose();
    }
  }, [updateDescriptionAxios.isSuccess, updateDescriptionAxios.error]);

  return (
    <Modal
      title="Chỉnh sửa mô tả nhiệm vụ"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={updateDescriptionAxios.isLoading}
      disabledSubmit={updateDescriptionAxios.isLoading}
      textSubmit="Cập nhật"
      textClose="Huỷ"
      width="580px"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlForm title="Mô tả" classname="brand-form">
          <Controller
            control={control}
            name={"description"}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextEditorQuill
                  {...field}
                  placeholder="Mô tả về nhiệm vụ"
                  toolbar={true}
                  editorStyle={{
                    border: "1px solid #D3DCDF",
                    borderRadius: "8px",
                    width: "100%",
                    height: "24vh"
                  }}
                  quillRef={quillRef}
                />
              </FormControl>
            )}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};

export default EditTaskDescriptionModal;
