import {
  IChangePasswordByAdminReq,
  IUpdateByAdminRequest,
  ITag
} from "./../../models/user/index";
import {
  IActionChangePasswordByAdmin,
  IActionCreateUser,
  IActionGetUserOrDepartment,
  IActionGetUsers,
  IActionIncrementNotification,
  IActionUpdateUserByAdmin
} from "./../../models/redux/user/index";
import { all, put, takeEvery } from "redux-saga/effects";
import { toggleMessage } from "../../components/Toast/Toast";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";

import { EUserType, IActionGetUser } from "../../models/redux/user";
import { IUser } from "../../models/user";
import UserService from "../../services/api/user.service";
import { authTokens } from "../../services/services";
import {
  createUsersFailed,
  createUserSucess,
  setItag,
  setNotificationCountState,
  setUsersState,
  setUserState,
  updateUserByAdminFailed,
  updateUserByAdminSucess
} from "./user.duck";
import moment from "moment";

function* getUser({ payload }: IActionGetUser) {
  try {
    const resUser: IBaseResponseModel<IUser> = yield UserService.getSingle(
      "05e2a92f-40e9-4c8f-92b4-263a8e8a7a73"
    );
    if (resUser.success && resUser.statusCode === 200) {
      const userInfo = resUser.data;
      toggleMessage({
        type: "success",
        message: "Tạo tài khoản thành công"
      });
      yield put(setUserState(userInfo));
    } else {
      toggleMessage({
        type: "error",
        message: "Đã xảy ra lỗi"
      });
    }
  } catch (err) {}
}

function* getUsers({ payload }: IActionGetUsers) {
  const { request } = payload;
  try {
    console.log("request get user", request);

    const resUser: IBasePagingRes<IUser> = yield UserService.getUsers(
      request ?? { currenPage: "1", pageSize: 10, pageIndex: 1 }
    );
    if (resUser.success && resUser.statusCode === 200) {
      const data = resUser.items;
      yield put(setUsersState(data));
    } else {
      toggleMessage({
        type: "error",
        message: "Đã xảy ra lỗi"
      });
    }
  } catch (err) {}
}

// function* getUserOrDepartment({ payload }: IActionGetUserOrDepartment) {
//   const { request } = payload;
//   try {
//     const res: IBasePagingRes<ITag> =
//       yield UserService.getUserOrDepartment(request);

//     if (res.success && res.statusCode === 200) {
//       const data = res.items;
//       yield put(setItag(data));
//     } else {
//       toggleMessage({
//         type: "error",
//         message: "Đã xảy ra lỗi"
//       });
//     }
//   } catch (err) {}
// }

function* createUser({ payload }: IActionCreateUser) {
  const { request } = payload;
  try {
    console.log("request", request);
    const resUser: IBaseResponseModel<IUser> =
      yield UserService.createUser(request);
    if (resUser.success && resUser.statusCode === 201) {
      yield put(createUserSucess());
    } else {
      yield put(createUsersFailed(resUser?.message ?? "Đã xảy ra lỗi"));
      toggleMessage({
        type: "error",
        message: resUser?.message ?? "Đã xảy ra lỗi"
      });
    }
  } catch (err) {
    yield put(createUsersFailed("Đã xảy ra lỗi"));
  }
}

function* updateUserByAdmin({ payload }: IActionUpdateUserByAdmin) {
  const { request } = payload;
  try {
    const updateReq: IUpdateByAdminRequest = {
      id: request.id,
      firstName: request.firstName,
      lastName: request.lastName,
      fullName: request.fullName,
      avatar: request.avatar,
      email: request.email,
      phoneNumber: request.phoneNumber,
      userName: request.userName,
      roles: request.roles,
      position: request.position,
      address: request.address,
      managerId: null,
      birthDay:
        request?.birthDay === null
          ? null
          : moment(
              moment(request?.birthDay, "DD-MM-YYYY")
                // .utc()
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            ).toDate(),
      manager: null,
      status: request.status,
      code: request.code,
      isTimeOffStart: request.isTimeOffStart,
      departmentIds: request.departmentIds
    };

    const resUser: IBaseResponseModel<IUser> =
      yield UserService.updateUserByAdmin(updateReq);
    if (resUser.success && resUser.statusCode === 200) {
      yield put(updateUserByAdminSucess());
    } else {
      yield put(updateUserByAdminFailed(resUser?.message ?? "Đã xảy ra lỗi"));
      toggleMessage({
        type: "error",
        message: resUser?.message ?? "Đã xảy ra lỗi"
      });
    }
  } catch (err) {
    yield put(createUsersFailed("Đã xảy ra lỗi"));
  }
}

function* changePasswordByAdmin({ payload }: IActionChangePasswordByAdmin) {
  const { request } = payload;
  try {
    const updateReq: IChangePasswordByAdminReq = {
      userId: request.id,
      newPassword: request.newPassword
    };
    const resUser: IBaseResponseModel<IUser> =
      yield UserService.updatePasswordByAdmin(updateReq);
    if (resUser.success && resUser.statusCode === 200) {
      toggleMessage({
        type: "success",
        message: "Đổi mật khẩu thành công"
      });
      yield put(updateUserByAdminSucess());
    } else {
      yield put(updateUserByAdminFailed(resUser?.message ?? "Đã xảy ra lỗi"));
      toggleMessage({
        type: "error",
        message: resUser?.message ?? "Đã xảy ra lỗi"
      });
    }
  } catch (err) {
    yield put(createUsersFailed("Đã xảy ra lỗi"));
  }
}

function* incrementNotification({ payload }: IActionIncrementNotification) {
  try {
    yield put(setNotificationCountState());
  } catch (err) {}
}

export default function* rootSaga() {
  yield all([
    takeEvery(EUserType.GET_USER, getUser),
    takeEvery(EUserType.GET_USERS, getUsers),
    takeEvery(EUserType.CREATE_USER, createUser),
    takeEvery(EUserType.ADMIN_UPDATE_USER, updateUserByAdmin),
    takeEvery(EUserType.ADMIN_CHANGE_PASSWORD, changePasswordByAdmin),
    takeEvery(
      [EUserType.CREATE_SUCCESS, EUserType.ADMIN_UPDATE_SUCCESS],
      getUsers
    ),
    takeEvery(EUserType.INCREMENT_NOTIFICATION, incrementNotification)
  ]);
}
