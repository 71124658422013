import {
  IFeatureByRole,
  IFeaturePermission,
  IPermissionByFeature,
  IRolePermission,
  IRoles,
  IUpdateRolePermissionReq
} from "./../../models/user/models.roles";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API = ApiEndpoints.ROLE;
const baseURL = envConfig.API_ENDPOINT;

const getRoles = (): Promise<IBaseResponseModel<IRoles>> => {
  const roles = api.get(baseURL, API.GET_ROLES.url);
  return roles;
};

const getFeatureByRoleId = (
  id: string
): Promise<IBaseResponseModel<IFeatureByRole[]>> => {
  const features = api.get(baseURL, API.GET_FEATURE.url, {
    params: { keyRole: id }
  });
  return features;
};

const getPemissionByFeatureId = (
  id: string
): Promise<IBaseResponseModel<IPermissionByFeature[]>> => {
  const pemission = api.get(baseURL, API.GET_PERMISSION.url, {
    params: { featureId: id }
  });
  return pemission;
};

const getFeaturePemissionByUserId = (
  id: string
): Promise<IBaseResponseModel<IFeaturePermission[]>> => {
  const pemission = api.get(baseURL, API.GET_FEATURE_PERMISSION_BY_USERID.url, {
    params: { userid: id }
  });
  return pemission;
};

const getUserRolePemission = (
  id: string
): Promise<IBaseResponseModel<IRolePermission[]>> => {
  const pemission = api.get(baseURL, API.GET_USER_ROLE_PERMISSION.url, {
    params: { userId: id }
  });
  return pemission;
};

const getDefaultPermissionByKeyRole = (
  keyRole: string
): Promise<IBaseResponseModel<IRolePermission[]>> => {
  const pemission = api.get(baseURL, API.GET_DEFAULT_PERMISSION.url, {
    params: { keyRole: keyRole }
  });
  return pemission;
};

const updateRolePermission = (
  data: IUpdateRolePermissionReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE_ROLE_PERMISSION.url, data);
};

// const getStageById = (
//   id: UniqueIdentifier
// ): Promise<IBaseResponseModel<IStage>> => {
//   const stage = api.get(baseURL, API.GET_SINGLE.url + `/${id}`);
//   return stage;
// };

// const createStage = (
//   params: IStageRequest
// ): Promise<IBaseResponseModel<IStage>> => {
//   return api.post(baseURL, API.CREATE_NEW_STAGE.url, params);
// };

// const updateStage = (
//   id: UniqueIdentifier,
//   params: IStageRequest
// ): Promise<IBaseResponseModel<IStage>> => {
//   return api.put(baseURL, API.UPDATE.url, { ...params, Id: id });
// };
// const getListWorkflowByBoardId = (
//   boardId: UniqueIdentifier
// ): Promise<IBaseResponseModel<IStage[]>> => {
//   return api.get(baseURL, API.GET_LIST_WORKFLOW_BY_BOARD_ID.url, {
//     params: { boardId: boardId }
//   });
// };

// const deleteStage = (id: string): Promise<IBaseResponseModel<string>> => {
//   return api.delete(baseURL, API.DELETE.url + `/${id}`);
// };

// const updateStagePosition = (
//   params: IUpdateStagePositionRequest
// ): Promise<IBaseResponseModel<IStageByWorkflowResponse>> => {
//   return api.put(baseURL, API.UPDATE_POSITION.url, params);
// };

const RoleService = {
  getRoles,
  getFeatureByRoleId,
  getPemissionByFeatureId,
  getFeaturePemissionByUserId,
  getUserRolePemission,
  updateRolePermission,
  getDefaultPermissionByKeyRole
};

export default RoleService;
