import React, { useEffect, useState } from "react";
import { ImageCustom } from "..";
import { DragAndDropFileWrapper } from "./styles";
import IconUpload from "../../assets/images/common/icon_upload_fill_green.svg";
import IconDelete from "../../assets/images/common/icon_delete_line_grey.svg";
import { Button } from "@mui/material";
import IconError from "../../assets/images/common/icon_warning_fill_red.png";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { toggleMessage } from "../Toast/Toast";

export interface DragAndDropFileProps {
  disabled?: boolean;
  helpertext?: string;
  limitFile?: number;
  placeholder?: string;
  title: string;
  classname?: string;
  handleUploadError?: (value: boolean) => void;
  onUploaded: (data: File[], name: string) => void;
  name: string;
  handleRemoveFile: (value: File) => void;
  data?: File[];
}

export default function DragAndDropFile(props: DragAndDropFileProps) {
  const {
    disabled,
    helpertext = "",
    limitFile = 0,
    placeholder,
    classname = "",
    handleUploadError,
    name,
    onUploaded,
    handleRemoveFile,
    data
  } = props;

  const inputRef = React.useRef<any>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [validateFiles, setValidateFiles] = useState<(Blob | MediaSource)[]>(
    []
  );
  const [fileFiles, setFileFiles] = useState<any[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  const [checkSize, setCheckSize] = useState(false);
  const [checkType, setCheckType] = useState(false);
  const [fileProgress, _] = useState<number | null>(null);

  useEffect(() => {
    handleUploadError && handleUploadError(!!error && error.length > 0);
  }, [error, handleUploadError]);

  useEffect(() => {
    onUploaded(fileFiles, name);
  }, [fileFiles, name, onUploaded]);

  useEffect(() => {
    if (data) {
      setFiles(data);
      setFileFiles(data);
    }
  }, [data]);

  useEffect(() => {
    if (checkType && checkSize && !disabled) {
      let image: File[] = [];
      let imageUploaded: any[] = [];
      for (const key in validateFiles) {
        if (key !== "length" && key !== "item") {
          let value = validateFiles[key] as File;
          imageUploaded.push(value);
          image.push(value);
        }
      }
      setFileFiles(prevState => [...prevState, ...imageUploaded]);
      onUploaded([...fileFiles, ...imageUploaded], name);
      setFiles(prevState => [...prevState, ...image]);
      setCheckSize(false);
      setCheckType(false);
      setError("");
      inputRef.current.value = null;
    }
  }, [checkSize, checkType]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setError("");
    if (!disabled) {
      setDragActive(true);
      // if (e.type === "dragenter" || e.type === "dragover") {
      //   setDragActive(true);
      // } else if (e.type === "dragleave") {
      //   setDragActive(false);
      // }
    } else {
      setDragActive(false);
    }
  };

  const checkValidate = (e: any, typeAction: "drop" | "click") => {
    e.preventDefault();
    e.stopPropagation();
    setError("");

    if (!disabled) {
      setDragActive(false);
      let dataClick: any;
      if (typeAction === "click") {
        dataClick = e.target.files;
      } else {
        dataClick = e.dataTransfer.files;
      }
      setValidateFiles(dataClick);
      const remain = limitFile - (dataClick.length + files.length);
      if (remain < 0) {
        inputRef.current.value = null;
        toggleMessage({
          type: "error",
          message: `Bạn đang upload quá số lượng file cho phép, số lượng file vượt quá: ${
            remain * -1
          }`
        });
      } else {
        for (const key in dataClick) {
          if (key !== "length" && key !== "item") {
            let value = dataClick[key];
            if (value.size / 1024 / 1024 > 15) {
              toggleMessage({
                type: "error",
                message: "Bạn đã upload file vượt quá dung lượng (tối đa 15MB)"
              });
              setCheckSize(false);
              inputRef.current.value = null;
            } else {
              setCheckSize(true);
              setError("");
            }
            if (
              // ![
              //   "image/jpg",
              //   "image/png",
              //   "image/jpeg",
              //   "image/webp",
              //   "video/mp4",
              //   "video/webm",
              //   "video/mpeg",
              //   "video/mov",
              //   "video/quicktime"
              // ].includes(value.type)
              false
            ) {
              toggleMessage({
                type: "error",
                message: "Bạn đã upload sai định dạng"
              });
              setError("Bạn đã upload sai định dạng");
              setCheckType(false);
              inputRef.current.value = null;
            } else {
              setCheckType(true);
              setError("");
            }
          }
        }
      }
    }
  };

  const onButtonClick = () => {
    if (!disabled) {
      inputRef.current.click();
    }
  };
  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
    value: File
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setError("");
    if (!disabled) {
      const newArr = [...files];
      newArr.splice(idx, 1);
      setFiles(newArr);
      const newArrFiles = [...fileFiles];
      newArrFiles.splice(idx, 1);
      setFileFiles(newArrFiles);
      onUploaded(newArrFiles, name);
      handleRemoveFile(value);
    }
  };

  return (
    <DragAndDropFileWrapper
      id="form-file-upload"
      className={classname}
      error={error.length > 0 || helpertext.length > 0}
      disabled={disabled}
      progresspercent={fileProgress}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
    >
      <div className="drag-drop-box">
        <>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={e => checkValidate(e, "click")}
            className="input-file-upload"
            // accept="application/*"
          />
          <div
            id="label-file-upload"
            className={`file-upload-box flex-column-center ${
              dragActive ? "drag-active" : ""
            }`}
          >
            <p className="upload-text">
              {placeholder ? (
                placeholder
              ) : (
                <>
                  <ImageCustom
                    src={IconUpload}
                    alt="Icon-Upload"
                    width={"40px"}
                    height={"40px"}
                    className="upload-icon"
                  />
                  <span className="upload-title">Kéo tệp vào đây</span>
                  <span className="upload-subtitle">
                    Hoặc{" "}
                    <Button
                      size="large"
                      variant="text"
                      className="choose-file-button"
                      onClick={onButtonClick}
                    >
                      Chọn File
                    </Button>{" "}
                    từ máy tính của bạn
                  </span>
                </>
              )}
            </p>
          </div>
          {dragActive && !disabled && (
            <div
              className="drag-file-box"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={e => checkValidate(e, "drop")}
            ></div>
          )}
        </>
      </div>
      {files &&
        files.length > 0 &&
        files.map((file, index) => (
          <div
            key={`file-item ${index}`}
            className={`file-infor ${error.length > 0 ? "error" : ""}`}
          >
            <div className="infor-detail">
              <FilePresentIcon sx={{ width: 24, height: 24 }} />
              <div className="detail-text">
                <p className="text-name">{file.name}</p>
                {/* {error.length > 0 ? (
                  <p className="upload-error error-text">{error}</p>
                ) : (
                  <p className="text-size">{files.size}</p>
                )} */}
              </div>
            </div>
            {fileProgress !== null && fileProgress < 100 ? (
              <div className="infor-progress-bar">
                <div className="progress-bar-child"></div>
              </div>
            ) : (
              <>
                {error.length > 0 ? (
                  <ImageCustom
                    src={IconError}
                    alt="Icon-Error"
                    width={"24px"}
                    height={"24px"}
                  />
                ) : (
                  <Button
                    size="large"
                    variant="text"
                    className="delete-file-button"
                    onClick={e => handleDelete(e, index, file)}
                  >
                    <ImageCustom
                      src={IconDelete}
                      alt="Icon-Delete"
                      width={"18px"}
                      height={"18px"}
                    />
                  </Button>
                )}
              </>
            )}
          </div>
        ))}
    </DragAndDropFileWrapper>
  );
}
