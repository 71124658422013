import { UniqueIdentifier } from "@dnd-kit/core";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  ITask,
  ITaskCard,
  ITaskCardCreateRequest,
  ITaskCardPage,
  ITaskCardTransitionRequest,
  ITaskCardUpdatePositionRequest,
  ITaskCardUpdateRequest,
  ITaskUpdateAssignRequest,
  ITaskUpdatePositionResponse
} from "../../models/Task";
import { IGetTaskPagesReq, IGetUsersReq } from "../../models/user";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_TASK = ApiEndpoints.TASK;
const baseURL = envConfig.API_ENDPOINT;

const createTask = (
  params: ITaskCardCreateRequest
): Promise<IBaseResponseModel<ITaskCard>> => {
  return api.post(baseURL, API_TASK.CREATE.url, params);
};

const updateTask = (
  params: ITaskCardUpdateRequest
): Promise<IBaseResponseModel<ITaskCard>> => {
  return api.put(baseURL, API_TASK.UPDATE.url, params);
};

const updateTaskTransition = (
  params: ITaskCardTransitionRequest
): Promise<IBaseResponseModel<ITaskCard>> => {
  return api.put(baseURL, API_TASK.UPDATE_TASK_TRANSITION.url, params);
};

const updateAssign = (
  params: ITaskUpdateAssignRequest
): Promise<IBaseResponseModel<ITaskCard>> => {
  return api.put(baseURL, API_TASK.UPDATE_ASSIGN.url, params);
};

const getSingle = (
  id: UniqueIdentifier
): Promise<IBaseResponseModel<ITask>> => {
  return api.get(baseURL, API_TASK.GET_SINGLE.url + `/${id}`);
};

const updateTaskPosition = ({
  TaskCardMoveId,
  WorkflowId,
  NewPosition
}: ITaskCardUpdatePositionRequest): Promise<
  IBaseResponseModel<ITaskUpdatePositionResponse>
> => {
  return api.put(baseURL, API_TASK.UPDATE_POSITION.url, {
    TaskCardMoveId: TaskCardMoveId,
    WorkflowId: WorkflowId,
    NewPosition: NewPosition
  });
};

const markAsFailed = (
  taskId: UniqueIdentifier,
  reasonFailed: string
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_TASK.MARK_AS_FAILED.url, {
    id: taskId,
    reasonFailed: reasonFailed
  });
};

const nextStage = (
  taskId: UniqueIdentifier
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_TASK.NEXT_STAGE.url, {
    id: taskId
  });
};

const deleteTask = (
  id: UniqueIdentifier
): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_TASK.DELETE.url + `/${id}`);
};

const getPage = (
  data: IGetTaskPagesReq
): Promise<IBasePagingRes<ITaskCardPage>> => {
  return api.get(baseURL, API_TASK.GET_PAGE.url, {
    params: data
  });
};
const rollBack = (id: UniqueIdentifier): Promise<IBasePagingRes<string>> => {
  return api.put(baseURL, API_TASK.ROLL_BACK.url, {
    id: id
  });
};

const TaskService = {
  createTask,
  updateTask,
  getSingle,
  updateTaskPosition,
  updateTaskTransition,
  updateAssign,
  deleteTask,
  getPage,
  nextStage,
  markAsFailed,
  rollBack
};

export default TaskService;
