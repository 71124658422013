import { Control, Controller, FieldError } from "react-hook-form";
import { FormControl, FormHelperText, Select } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ReactNode } from "react";

interface DropdownProps {
  name: string;
  control: Control<any, any>;
  onChange: (e: any) => void;
  options: any[];
  value: string | undefined;
  error: FieldError | undefined;
}

const Dropdown = ({
  name,
  control,
  error,
  value,
  options,
  onChange
}: DropdownProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        console.log(field);
        return (
          <FormControl fullWidth>
            <Select
              {...field}
              size="small"
              displayEmpty
              value={value || ""}
              labelId={`simple-select-label ${name}`}
              id={`${name}-select`}
              IconComponent={ExpandLessIcon}
              onChange={async value => {
                field.onChange(value);
                onChange(value.target.value);
              }}
              sx={{ fontSize: 13 }}
            >
              {options}
            </Select>
            {!!error && (
              <FormHelperText sx={{ color: "#ED3E47" }}>
                {error.message ?? ""}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default Dropdown;
