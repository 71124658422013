import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  // backgroundColor: "white",
  "& .fc-daygrid-day-number": {
    //     backgroundColor: "red"
    fontSize: "16px",
    fontWeight: "500"
  },
  "& .fc-header-toolbar": {
    "& .fc-toolbar-title": {
      fontSize: "16px",
      fontWeight: "500"
    },
    "& .fc-button-group": {
      marginRight: "10px",
      backgroundColor: "#D4E3F2",
      borderRadius: "32px",
      "& .fc-button": {
        //    width: "80px",
        border: "none",
        // color: "#3091F3",
        color: "black",
        textTransform: "uppercase",
        borderRadius: "32px",
        backgroundColor: "transparent",
        fontSize: "14px",
        fontWeight: "500",
        width: "auto",
        margin: "4px 8px",
        padding: "0 12px"
      }
    }
  },
  "& .fc .fc-button-primary:not(:disabled).fc-button-active": {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#3091F3",
    border: "0x solid #3091F3",
    borderRadius: "32px",
    width: "auto",
    padding: "0 12px",
    margin: "4px 6px",
    fontSize: "14px"
  },
  "& .fc .fc-button-primary:not(:disabled).fc-button-active:focus": {
    boxShadow: "none"
  },
  "& .fc .fc-button-primary": {
    color: "GrayText",
    width: "32px",
    height: "32px",
    padding: 0,
    backgroundColor: "#F6F6F6",
    border: "1px solid #DDDDDD",
    borderRadius: "32px"
  },
  ".fc .fc-button-primary:disabled": {
    width: "100px",
    backgroundColor: "transparent",
    color: "GrayText",
    border: "none",
    fontWeight: "600"
  },
  ".fc-direction-ltr .fc-toolbar > * > :not(:first-child, :last-child)": {
    width: "auto",
    border: 0,
    fontWeight: "bold",
    backgroundColor: "transparent"
  }
}));

export const ApproveWrapper = styled("div")(() => ({
  display: "flex",
  "& .content-box": {
    backgroundColor: "white",
    margin: "0 12px",
    padding: "12px",
    borderRadius: "4px"
  }
}));
