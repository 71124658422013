import { Box, Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { ImageCustom } from "../../../components";
import FileIcon from "../../../assets/images/file/Paperclip.svg";
import Logo from "../../../assets/images/common/effective.svg";
import { IDocument } from "../../../models/docs";
import moment from "moment";
import { DATETIME_FORMAT2, DATETIME_FORMAT4 } from "../../../config/constants";
import { EDocumentStatus, EFileType } from "../../../models/common/models.enum";
import FileImage from "../../ManageProject/Task/components/CommentTask/components/FileImage";
import { DownloadRounded } from "@mui/icons-material";
import { b64toBlob } from "../../../common/handles/common.handles";
import UploadFileService from "../../../services/api/uploadFile.service";
import { IAttachmentFile } from "../../../models/Task";
import { IDownloadFile } from "../../../models/Comment";
import { useApi } from "../../../hooks/useApi.hook";

type Props = {
  item: IDocument;
};

const DocsContent = ({ item }: Props) => {
  const downloadFile = useApi<IDownloadFile>({});

  function handleDownloadFile(file: IAttachmentFile) {
    downloadFile.request(
      UploadFileService.downloadFile({
        path: file.path,
        fileName: file.fileName
      }).then(response => {
        if (response.success) {
          const blob = b64toBlob(
            response.data.fileContent,
            response.data.fileName
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode!.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ fontSize: "20px" }}>
        [{item.numberIdentifier}] {item.title}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ color: "#7E969F", paddingTop: "12px" }}
      >
        <Box>
          {item.documentType && (
            <Chip
              label={item.documentType?.name}
              size="small"
              sx={{
                color: "#057EF0",
                backgroundColor: "#D7F3FF",
                borderRadius: "16px",
                fontWeight: "w500"
              }}
            />
          )}
          <Typography variant="subtitle2" marginTop="12px">
            Ngày phát hành:
          </Typography>
          <Typography paddingTop="4px" variant="subtitle2">
            {moment(item.dateOfIssue).format(DATETIME_FORMAT4)}
          </Typography>
          <Typography paddingTop="4px" variant="subtitle2">
            Ngày hết hạn:
          </Typography>
          <Typography paddingTop="4px" variant="subtitle2">
            {item.documentExpirationDate
              ? moment(item.documentExpirationDate).format(DATETIME_FORMAT4)
              : "Không có thông tin"}
          </Typography>
        </Box>
        {item.status === EDocumentStatus.Released && (
          <ImageCustom src={Logo} width="105px" height="105px" />
        )}
      </Box>
      <Box marginTop="32px">
        <div
          className="TaskShortDesc Overflow2Line"
          dangerouslySetInnerHTML={{
            __html: item?.description ?? ""
          }}
        ></div>
      </Box>

      {item.attachmentFiles.length > 0 && (
        <Box className="attach" marginTop="32px">
          <Box display="flex">
            <ImageCustom src={FileIcon} width="18px" height="18px" />
            <Typography
              variant="subtitle2"
              sx={{ color: "#7E969F", marginLeft: "10px" }}
            >
              TỆP ĐÍNH KÈM
            </Typography>
          </Box>

          {/* <Box display="flex">
            {item.attachmentFiles.map((file, i) => (
              // <p key={`${i}/${file.fileName}`}>{file.fileName}</p>
              <Box
                key={`${i}/${file.fileName}`}
                border={"1px solid #DEDEDE"}
                borderRadius="8px"
                margin="12px 0"
                padding="8px 16px"
                display="flex"
                marginRight="12px"
              >
                <FileImage type={file.extension ?? EFileType.doc} />
                <Typography variant="subtitle2" margin="0 12px">
                  {file.fileName}
                </Typography>
                <Tooltip title="Tải xuống tài liệu">
                  <Button sx={{ color: "#7E969F" }}>
                    <DownloadRounded />
                  </Button>
                </Tooltip>
              </Box>
            ))}
          </Box> */}

          <Grid container>
            {item.attachmentFiles &&
              item?.attachmentFiles.map((file, i) => (
                <Grid key={`${i}/${file.fileName}`} item xs={"auto"}>
                  <Box
                    border={"1px solid #DEDEDE"}
                    borderRadius="8px"
                    margin="12px 0"
                    padding="8px 16px"
                    display="flex"
                    marginRight="12px"
                  >
                    <FileImage type={file.extension ?? EFileType.doc} />
                    <Typography variant="subtitle2" margin="0 12px">
                      {file.fileName}
                    </Typography>
                    <Tooltip title="Tải xuống tài liệu">
                      <Button
                        sx={{ color: "#7E969F" }}
                        onClick={() => handleDownloadFile(file)}
                      >
                        <DownloadRounded />
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default DocsContent;
