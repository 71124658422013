import { styled } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  maxHeight: "calc(100vh - 52px)",
  overflow: "auto",
  "& .content-list": {
    width: "28%",
    "& .content-list-item": {
      maxHeight: "calc(100vh - 52px - 100px)",
      overflow: "auto"
    }
  },

  "& .content-page": {
    width: "72%",
    backgroundColor: "white",
    minHeight: "calc(100vh - 52px)",
    height: "auto",
    padding: "50px 44px",
    overflow: "auto"
  }
}));
