import { Box, Button, Typography } from "@mui/material";
import { INavItem } from "../../models/app";
import "./styles1.css";
import { Link, useLocation } from "react-router-dom";
import variableStyles from "../../theme/variable-styles";
import useNavigateSearch from "../NavigateSearch";

type Props = {
  item: INavItem[];
};

const DashboardSidebarChildren = ({ item }: Props) => {
  const location = useLocation();
  const navigateSearch = useNavigateSearch();
  return (
    <Box marginLeft="44px">
      <ul className="tree">
        {item.map(child => (
          <li className="parent" key={child.code + child.title}>
            {child.children ? (
              <details>
                <summary>{child.title}</summary>
                <ul>
                  {child.children?.map((children, idx) => {
                    let isActive =
                      child.code === "project"
                        ? location.search.includes(children.code!)
                        : location.pathname
                            .split("/")
                            .splice(1)
                            .includes(children.code!.replace("/", ""));

                    return (
                      <li
                        key={children.code}
                        // style={
                        //   isActive
                        //     ? {
                        //         padding: "0 8px",
                        //         margin: 0,
                        //         height: "auto",
                        //         textAlign: "start",
                        //         overflow: "hidden",
                        //         textOverflow: "ellipsis",
                        //         whiteSpace: "nowrap"
                        //       }
                        //     : {
                        //         backgroundColor: "transparent",
                        //         padding: "0 8px",
                        //         margin: 0,
                        //         height: "auto",
                        //         color: "green",
                        //         textAlign: "start",
                        //         overflow: "hidden",
                        //         textOverflow: "ellipsis",
                        //         whiteSpace: "nowrap"
                        //         // --hover: {
                        //         //   backgroundColor:
                        //         //     variableStyles.GreenPrimaryColor400
                        //         // }
                        //       }
                        // }
                      >
                        <Button
                          variant={"contained"}
                          sx={
                            isActive
                              ? {
                                  padding: "2px 10px",
                                  margin: 0,
                                  borderRadius: "8px",
                                  height: "auto",
                                  textAlign: "start",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap"
                                }
                              : {
                                  backgroundColor: "transparent",
                                  padding: "2px 10px",
                                  margin: 0,
                                  borderRadius: "8px",
                                  height: "auto",
                                  color: "green",
                                  textAlign: "start",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  ":hover": {
                                    backgroundColor:
                                      variableStyles.GreenPrimaryColor400
                                  }
                                }
                          }
                          onClick={() =>
                            navigateSearch(children.href || "/", {
                              id: children.search!
                            })
                          }
                        >
                          <p
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              color: "white"
                            }}
                          >
                            {children.title}
                          </p>
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </details>
            ) : (
              <Button sx={{ color: "white" }}>
                <Typography variant="subtitle2">{child.title}</Typography>
              </Button>
            )}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default DashboardSidebarChildren;
