import React, { useEffect, useMemo, useState } from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import CreateTimeOffModal from "./components/CreateTimeOffModal/CreateTimeOffModal";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  Divider,
  Menu,
  MenuItem,
  CircularProgress
} from "@mui/material";
import TimeOffTable from "./components/DataTable/TimeOffDataTable";
import {
  ITimeOff,
  ITimeOffOperation,
  ITimeOffOption
} from "../../models/timeoff";
import variableStyles from "../../theme/variable-styles";
import {
  EDayOffSession,
  ETimeOffOperation,
  ETimeOffStatus,
  ETimeOffType
} from "../../models/common/models.enum";
import useAxios from "../../components/UseAxios/useAxios";
import TimeOffService from "../../services/api/timeoff.service";
import { toggleMessage } from "../../components/Toast/Toast";
import { IBasePaging } from "../../models/common/models.type";
import dayjs from "dayjs";
import ExportTimeOffModal from "./components/ExportTimeOffModal/ExportTimeOffModal";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import authTokens, { USER_TOKEN_KEY } from "../../services/local/auth-tokens";
import { Helmet } from "react-helmet";

export const sessionOptions: readonly ITimeOffOption[] = [
  {
    name: "8:30-12:30",
    value: EDayOffSession.Morning
  },
  {
    name: "13:30-17:30",
    value: EDayOffSession.Afternoon
  }
];

export const typeOptions: readonly ITimeOffOperation[] = [
  {
    name: "Nghỉ phép năm dưới 3 ngày",
    value: ETimeOffType.Leave_of_absence_with_permission_for_less_than_3days,
    operation: ETimeOffOperation.Minus
  },
  {
    name: "Nghỉ phép năm trên 3 ngày",
    value: ETimeOffType.Leave_of_absence_with_permission_for_more_than_3days,
    operation: ETimeOffOperation.Minus
  },
  {
    name: "Nghỉ không lương dưới 3 ngày",
    value: ETimeOffType.Leave_of_absence_without_permission_for_less_than_3days,
    operation: ETimeOffOperation.NotOperation
  },
  {
    name: "Nghỉ không lương trên 3 ngày",
    value: ETimeOffType.Leave_of_absence_without_permission_for_more_than_3days,
    operation: ETimeOffOperation.NotOperation
  }
];

interface ICreateButonProps {
  onCreteTimeOff?: () => void;
  onCreateExcel?: () => void;
}
const CreateButton = ({ onCreteTimeOff, onCreateExcel }: ICreateButonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!onCreateExcel) {
      handleCreateTimeOff();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCreateTimeOff = () => {
    handleClose();
    onCreteTimeOff?.();
  };
  const handleCreateExcel = () => {
    handleClose();
    onCreateExcel?.();
  };
  if (!onCreteTimeOff) return null;

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-hidden={!open}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownOutlinedIcon sx={{ height: 24, width: 24 }} />}
        sx={{
          color: "white",
          borderRadius: 1,
          height: 40,
          width: "100%",
          "& .MuiButton-endIcon": {
            marginLeft: 0
          },
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor400
          }
        }}
      >
        <Typography fontSize={14} fontWeight={700}>
          Tạo đề xuất
        </Typography>
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateTimeOff} disableRipple>
          <Typography fontSize={14} fontWeight={400}>
            Tạo đề xuất
          </Typography>
        </MenuItem>
        {onCreateExcel && <Divider />}
        {onCreateExcel && (
          <MenuItem onClick={handleCreateExcel} disableRipple>
            <Typography fontSize={14} fontWeight={400}>
              Xuất excel theo tháng
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const defaultPaging = {
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0
};

const TimeOffPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<any>("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isReload, setIsReload] = useState(dayjs());
  const [permission, setPermission] = useState<IFeaturePermission | undefined>(
    undefined
  );
  const [HrPermission, setHrPermission] = useState<
    IFeaturePermission | undefined
  >(undefined);

  const canExportExcel = useMemo(() => {
    return HrPermission?.permissions.includes("ExportExcelTimeOff") ?? false;
  }, [HrPermission]);

  const [paging, setPaging] = useState<IBasePaging>(defaultPaging);

  const [timeOffProposes, setTimeOffProposes] = useState<ITimeOff[]>([]);

  const getTimeOffProposesAxios = useAxios<ITimeOff[]>({
    loading: "OnRequest"
  });

  const getPermissionAxios = useAxios<IFeaturePermission[]>({
    loading: "OnRequest"
  });

  const handleTabChange = (event: any, tab: string) => {
    setCurrentTab(tab);
    setCurrentPage(1);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleProposeStatusChange = () => {
    setIsReload(dayjs());
  };

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);
  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  useEffect(() => {
    authTokens.getUser().then(user => {
      getPermissionAxios.request(
        RoleService.getFeaturePemissionByUserId(user?.id ?? "")
      );
    });
  }, []);

  useEffect(() => {
    if (getPermissionAxios.isSuccess && getPermissionAxios.data) {
      setPermission(getPermissionAxios.data.find(e => e.feature === "TimeOff"));
      setHrPermission(getPermissionAxios.data.find(e => e.feature === "HR"));
    }
    if (getPermissionAxios.error) {
      toggleMessage({
        type: "error",
        message: getPermissionAxios.error?.message ?? ""
      });
    }
  }, [getPermissionAxios.isSuccess, getPermissionAxios.error]);

  const onCreateSuccess = () => {
    setCurrentTab("all");
    setCurrentPage(1);
    if (currentTab === "all" && currentPage === 1) setIsReload(dayjs());
  };

  const getTimeOff = () => {
    if (currentTab === "all") {
      getTimeOffProposesAxios.request(
        TimeOffService.getMyTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize
        })
      );
      return;
    }
    if (currentTab === "pending") {
      getTimeOffProposesAxios.request(
        TimeOffService.getManagerTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize
        })
      );
      return;
    }
    if (typeof currentTab === "number") {
      getTimeOffProposesAxios.request(
        TimeOffService.getCEOTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize,
          Status: currentTab
        })
      );
      return;
    }
  };

  useEffect(() => {
    getTimeOff();
  }, [currentPage, currentTab, isReload]);

  useEffect(() => {
    if (getTimeOffProposesAxios.isSuccess && getTimeOffProposesAxios.data) {
      setTimeOffProposes(getTimeOffProposesAxios.data);
      setPaging(getTimeOffProposesAxios.paging!);
    }
    if (getTimeOffProposesAxios.error) {
      setTimeOffProposes([]);
      setPaging(defaultPaging);
      toggleMessage({
        type: "error",
        message: getTimeOffProposesAxios.error?.message ?? ""
      });
    }
  }, [getTimeOffProposesAxios.isSuccess, getTimeOffProposesAxios.error]);

  if (getPermissionAxios.isSuccess && !permission) return null;
  if (getPermissionAxios.isLoading) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Nghỉ phép"}</title>
      </Helmet>
      <div style={{ backgroundColor: "white" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ padding: "12px 18px 8px 26px" }}
        >
          <h4 className=" truncate font-medium text-xl my-1 prevent-select flex-shrink">
            Danh sách đề xuất
          </h4>
          <CreateButton
            onCreteTimeOff={
              permission?.permissions.includes("Create")
                ? openCreateModal
                : undefined
            }
            onCreateExcel={
              HrPermission?.permissions.includes("ViewUserRegisterTimeOff")
                ? openExportModal
                : undefined
            }
          />
        </Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          scrollButtons
          variant="scrollable"
          indicatorColor="secondary"
          sx={{
            "& .Mui-selected": {
              color: "#267CDE !important"
            }
          }}
        >
          {permission?.permissions.includes("View") && (
            <Tab
              value="all"
              icon={<Typography variant="overline">Tất cả</Typography>}
            />
          )}
          {permission?.permissions.includes("ManagerTimeOff") && (
            <Tab
              value="pending"
              icon={<Typography variant="overline">Đến lượt duyệt</Typography>}
            />
          )}
          {permission?.permissions.includes("AdminTimeOff") && (
            <Tab
              value={ETimeOffStatus.ManagerApprove}
              icon={
                <Typography variant="overline">Quản lí đã duyệt</Typography>
              }
            />
          )}
          {permission?.permissions.includes("AdminTimeOff") && (
            <Tab
              value={ETimeOffStatus.Pending}
              icon={<Typography variant="overline">Đang chờ duyệt</Typography>}
            />
          )}
          {permission?.permissions.includes("AdminTimeOff") && (
            <Tab
              value={ETimeOffStatus.CEOApprove}
              icon={<Typography variant="overline">Đã được duyệt</Typography>}
            />
          )}

          {permission?.permissions.includes("AdminTimeOff") && (
            <Tab
              value={ETimeOffStatus.NotApprove}
              icon={<Typography variant="overline">Đã từ chối</Typography>}
            />
          )}
        </Tabs>
      </div>
      <Box
        sx={{
          margin: 2,
          flexGrow: 1,
          height: "100%",
          backgroundColor: "white"
        }}
      >
        {getTimeOffProposesAxios.data && (
          <TimeOffTable
            tab={currentTab}
            canDeleteTimeoff={
              permission?.permissions.includes("Delete") ?? false
            }
            canUpdateStatus={
              (permission?.permissions.includes("AdminUpdateStatus") ||
                permission?.permissions.includes("ManagerUpdateStatus")) ??
              false
            }
            updateType={
              permission?.permissions.find(e => e.endsWith("UpdateStatus")) ??
              ""
            }
            proposes={timeOffProposes}
            paging={paging}
            onPageChange={handlePageChange}
            onProposeStatusChange={handleProposeStatusChange}
            selectable={
              permission
                ? permission.permissions.findIndex(e =>
                    e.endsWith("UpdateStatus")
                  ) !== -1
                : false
            }
          />
        )}

        {getTimeOffProposesAxios.isLoading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      <CreateTimeOffModal
        open={isCreateModalOpen}
        handleClose={closeCreateModal}
        onCreateSuccess={onCreateSuccess}
      />
      <ExportTimeOffModal
        open={isExportModalOpen}
        canExportExcel={canExportExcel}
        handleClose={closeExportModal}
      />
    </Box>
  );
};

export default TimeOffPage;
