import { Control, Controller } from "react-hook-form";
import { ReactNode, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import {
  IWorkflow,
  IWorkflowSearchReq
} from "../../../../../../models/workflow";
import useAxios from "../../../../../../components/UseAxios/useAxios";
import WorkflowService from "../../../../../../services/api/workflow.service";

interface Props {
  name: string;
  control?: Control<any, any>;
  errors: any;
  isError: boolean;
  onChange?: (e: IWorkflow | null) => void;
  placeholder: string | undefined;
  value: IWorkflow | undefined;
}

export const SearchWorkflow = ({
  name,
  control,
  value,
  onChange,
  isError,
  placeholder
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [searchKey, setSearchKey] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IWorkflow[]>([]);
  const [noOptionsText, setnoOptionsText] = useState<ReactNode | undefined>();

  const search = useAxios<IWorkflow[]>({ loading: "OnRequest" });

  const getWorkflows = async (value: string) => {
    if (value) {
      search.reset();
      let request: IWorkflowSearchReq = {
        CurrentPage: 1,
        PageSize: 10,
        KeySearch: value
      };

      search.request(WorkflowService.searchWorkflows(request));
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const [handleTagSearch] = useState(() => _debounce(getWorkflows, 500));

  useEffect(() => {
    if (searchKey)
      setnoOptionsText(
        <center>
          <CircularProgress size={24} />
        </center>
      );
    if (searchKey === "") setnoOptionsText("No options");

    handleTagSearch(searchKey);
    return () => {
      search.reset();
    };
  }, [searchKey]);

  useEffect(() => {
    if (search.isSuccess && search.data) {
      setOptions(search.data);
      if (search.data.length === 0)
        setnoOptionsText(
          <Typography key="noOptionsText">{"Không tìm thấy"}</Typography>
        );
    }
    if (search.error) {
      setnoOptionsText(
        <Typography key="noOptionsText">{"Đã có lỗi xảy ra"}</Typography>
      );
    }
  }, [search.data, search.isSuccess, search.error]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: field, fieldState: { error } }) => {
        return (
          <Box
            sx={{
              "& .MuiAutocomplete-endAdornment": {
                right: "0px !important"
              }
            }}
          >
            <Autocomplete<IWorkflow>
              inputValue={inputValue}
              onInputChange={(_, v) => {
                setInputValue(v);
                if (value?.name !== v) setSearchKey(v);
              }}
              value={value}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                field.onChange(newValue);
                onChange?.(newValue);
                setSearchKey("");
              }}
              open={!!searchKey}
              noOptionsText={noOptionsText}
              options={options}
              loading={loading}
              popupIcon={undefined}
              forcePopupIcon={false}
              getOptionLabel={option => option.name}
              disableCloseOnSelect={false}
              renderInput={params => (
                <TextField
                  {...params}
                  id="workflow-search"
                  size={"small"}
                  helperText={error?.message}
                  placeholder={placeholder}
                  error={isError}
                />
              )}
            />
          </Box>
        );
      }}
    />
  );
};
